import React from 'react'
import FooterBase from '../../components/shared/bar/footer_base'

class Footer extends React.Component {
  render() {
    return (<FooterBase siteName="Slayer" />)
  }
}

export default Footer
