import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const UploadBase = types
  .model({
    presignedURL: '',
    precreatedItemID: 0,
  })
  .actions(self => ({
    onCreate({ data, error }) {
      self.loading = false
      if (self.checkError(error)) return
      self.presignedURL = data.presigned
      self.precreatedItemID = data.id
    },
    create(payload) {
      self.loading = true
      post('/uploads', payload).then(self.onCreate)
    },

    list() {
      self.loading = true
      get('/uploads', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
      }).then(self.setData)
    },

    read(id) {
      get('/uploads/' + id).then(self.setItemData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/uploads/${id}`, data).then(() => {
        self.list()
      })
    },

    delete(id) {
      self.loading = true
      del(`/uploads/${id}`).then(() => {
        self.list()
      })
    },

    reupload(id) {
      post(`/uploads/${id}`).then(self.onCreate)
    },
  }))

const Upload = types
  .compose(
    BasicObject,
    UploadBase,
  )

export default Upload
