import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class SubscriptionText extends React.Component {
  render() {
    if (this.props.category === 'onetime') {
      let time = moment(this.props.expireDate)
      let now = moment()
      if (time < now) {
        return (
          <span style={{ opacity: 0.2 }}>
            {this.props.text}
          </span>
        )
      }
    }
    if (this.props.status === 'ignorable' || this.props.status === 'terminated') {
      return (
        <span style={{ opacity: 0.2, background: '#e6f7ff', color: '#1890ff' }}>
          {this.props.text}
        </span>
      )
    }
    return <span>{this.props.text}</span>
  }
}

SubscriptionText.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  expireDate: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  status: PropTypes.string,
}

export default SubscriptionText
