import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Select, DatePicker, Form, InputNumber } from 'antd'
import moment from 'moment'
import queryString from 'query-string'
import { get } from '../../../utils/request'
import utils from '../../../utils'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      editMode: this.props.match.path === '/object_episodes/edit/:id',
    }

    if (this.state.editMode) {
      this.loadData()
    }
  }

  componentDidMount = () => {
    let parsed = queryString.parse(this.props.location.search)
    if (parsed.obj_id) {
      this.formRef.current.setFieldsValue({
        obj_id: parsed.obj_id,
      })
    }
  }

  loadData = async () => {
    let origin = (await get(`${utils.MT}/admin/object_episodes/${this.props.match.params.id}`)).data
    this.formRef.current.setFieldsValue({
      ...origin,
      released_at: origin.released_at ? moment(origin.released_at) : '',
      source: origin.source ? origin.source.replace('bgm_ep_', '') : '',
    })
    this.setState({
      loaded: true,
    })
  }

  onFinish = values => {
    let data = {
      ...values,
      source: values.source ? values.source_prefix + values.source : ''
    }
    if (this.state.editMode) {
      window.store.objectEpisode.update(
        this.props.match.params.id,
        data,
      )
    } else {
      window.store.objectEpisode.create(data)
    }
  }

  passCheck(rule, value, callback) {
    callback()
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            onFinish={this.onFinish}
            initialValues={{
              category: 'none',
              title: null,
              alias: '',
              duration: null,
              source: null,
              source_prefix: 'bgm_ep_',
              released_at: null,
            }}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout}
              label="Obj ID:"
              name="obj_id"
              rules={[{ required: true }]}
              validateStatus={window.store.objectEpisode.errors.obj_id ? "error" : ""}
              help={window.store.objectEpisode.errors.obj_id}>
              <InputNumber disabled={true} />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Category:"
              name="category"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.objectEpisode.errors.category ? "error" : ""}
              help={window.store.objectEpisode.errors.category}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key='none'>Episode</Select.Option>
                <Select.Option key='special'>Special</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Key:"
              name="key"
              rules={[{ required: true }]}
              validateStatus={window.store.objectEpisode.errors.key ? "error" : ""}
              help={window.store.objectEpisode.errors.key}>
              <InputNumber />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Title:"
              name="title"
              rules={[{ required: true }]}
              validateStatus={window.store.objectEpisode.errors.title ? "error" : ""}
              help={window.store.objectEpisode.errors.title}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Alias:"
              name="alias"
              rules={[{ whitespace: true }]}
              initialValue={null}
              validateStatus={window.store.objectEpisode.errors.alias ? "error" : ""}
              help={window.store.objectEpisode.errors.alias}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Duration:"
              name="duration"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.objectEpisode.errors.duration ? "error" : ""}
              help={window.store.objectEpisode.errors.duration}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Source:"
              name="source"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.objectEpisode.errors.source ? "error" : ""}
              help={window.store.objectEpisode.errors.source}>
              <Input addonBefore={<Form.Item noStyle name="source_prefix">
                <Select style={{ width: 120 }}>
                  <Select.Option value="bgm_ep_">bgm_ep_</Select.Option>
                </Select>
              </Form.Item>} />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Released at:"
              name="released_at"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.objectEpisode.errors.released_at ? "error" : ""}
              help={window.store.objectEpisode.errors.released_at}>
              <DatePicker />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Bio:"
              name="bio"
              initialValue={''}
              rules={[{ whitespace: true }]}
              validateStatus={window.store.objectEpisode.errors.bio ? "error" : ""}
              help={window.store.objectEpisode.errors.bio}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
