import React from 'react'
import { Link } from 'react-router-dom'
import Simple from '../../../components/shared/ant/table/simple'
import Status from '../../../components/shared/format/status'
import DateTime from '../../../components/shared/format/datetime'
import LengthLimiter from '../../../components/shared/format/length_limiter'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='source bgm'
        store={this.props.history.store}
        columns='source carrier category name alias rating rank eps_count ep crt staff released_at created_at'
        control={{
        }}
        options={{
          id: (value, item) => <Link to={`/objs/create?sid=${item.id}`} target="_blank" rel="noopener noreferrer">{value}</Link>,
          carrier: value => <Status type="status" text={value}></Status>,
          name: (_, item) => <LengthLimiter width={256} text={item.name}></LengthLimiter>,
          alias: value => <LengthLimiter width={256} text={value.join(', ')}></LengthLimiter>,
          rating: (_, item) => <span>{item.data.rating ? item.data.rating.score : ''}</span>,
          rank: (_, item) => <span>{item.data.rank}</span>,
          eps_count: (_, item) => <span>{item.data.eps_count}</span>,
          ep: (_, item) => <span><Link to={`/object_episodes/bulk?sid=${item.id}`} target="_blank" rel="noopener noreferrer">{item.data.eps_music ? `[${item.data.eps_music.length} songs]` : ''}</Link><LengthLimiter width={128} text={item.data.eps ? item.data.eps[0].name : ''}></LengthLimiter></span>,
          crt: (_, item) => <LengthLimiter width={128} text={item.data.crt ? item.data.crt[0].name : ''}></LengthLimiter>,
          staff: (_, item) => <LengthLimiter width={128} text={item.data.staff ? item.data.staff[0].name : ''}></LengthLimiter>,
          released_at: value => <DateTime text={value} type="date"></DateTime>,
          created_at: 'date',
          size: 'small',
          features: 'datepicker',
        }}
      />
    )
  }
}

export default Index
