import queryString from 'query-string'
import { notification, message } from 'antd'
import util from './'

function processData(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.json()
  }

  const error = new Error(response.statusText)
  if (response.status === 400) {
    return response.json().then((json) => {
      if (json.message === undefined) {
        notification['error']({
          message: 'Unknown error',
          description: JSON.stringify(json),
        })
      }
      else {
        window.debug('=> error:', JSON.stringify(json.message))
        error.response = json.message
        let msg = ''
        for (let item in json.message) {
          msg += `${item}: ${json.message[item]}; `
        }
        message.error(msg, 3)
        throw error
      }
    })
  }
  else {
    notification['error']({
      message: `HTTP ${response.status}`,
      description: error.toString(),
    })
    if (response.status === 403) {
      window.location.href = '/#/login'
    }
    error.response = { message: error.toString() }
    throw error
  }
}

function debug(response) {
  window.debug('=> response:', response)
  return response
}

function getHeaders(path) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: path.includes('http') ? localStorage.getItem('tokenMT') : localStorage.getItem('token'),
  }
}

const api = (path) => {
  if (path.includes('http')) return path
  return util.API + path
}

export const get = (path, data, options) => {
  window.debug('=> geting:', path, data)
  return fetch(`${api(path)}?${queryString.stringify(data)}`, {
    headers: getHeaders(path),
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => ({ data }))
  .catch((error) => ({ error }))
}

export const post = (path, data, options) => {
  window.debug('=> posting:', path, data)
  return fetch(api(path), {
    method: 'post',
    headers: getHeaders(path),
    body: JSON.stringify(data),
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => ({ data }))
  .catch((error) => ({ error }))
}

export const put = (path, data, options) => {
  window.debug('=> puting:', path, data)
  return fetch(api(path), {
    method: 'put',
    headers: getHeaders(path),
    body: JSON.stringify(data),
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => ({ data }))
  .catch((error) => ({ error }))
}

export const patch = (path, data, options) => {
  window.debug('=> puting:', path)
  return fetch(api(path), {
    method: 'PATCH',
    headers: getHeaders(path),
    body: JSON.stringify(data),
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => ({ data }))
  .catch((error) => ({ error }))
}

export const del = (path, data, options) => {
  window.debug('=> puting:', path, data)
  return fetch(api(path), {
    method: 'delete',
    headers: getHeaders(path),
    body: JSON.stringify(data),
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => ({ data }))
  .catch((error) => ({ error }))
}

export const getExternal = (path, data, options) => {
  window.debug('=> geting:', path, data)
  return fetch(`${path}?${queryString.stringify(data)}`, {
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => ({ data }))
  .catch((error) => ({ error }))
}

function getGraphQLHeaders() {
  let token = localStorage.getItem('bear')
  if (!token) {
    token = prompt('token:')
    if (token) {
      localStorage.setItem('bear', token)
    }
  }
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }
}

export const query = (data, options) => {
  window.debug('=> querying:', data)
  return fetch(`${util.endpoint()}`, {
    method: 'post',
    headers: getGraphQLHeaders(),
    body: JSON.stringify({
      query: data
    }),
    ...options
  })
  .then(processData)
  .then(debug)
  .then((data) => {
    const keys = Object.keys(data.data)
    return {
      data: {
        items: data.data[keys[0]],
        total: keys.length > 1 ? data.data[keys[1]].aggregate.count : 0,
      }
    }
  })
  .catch((error) => ({ error }))
}
