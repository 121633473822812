import React from 'react'
import PropTypes from 'prop-types'
import parseMarkdown from '../../../utils/markdown'

const styles = {
  maxHeight: 262,
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  margin: '0.2em 0 !important',
  fontSize: '0.9em !important',
}

class Fold extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fold: true,
    }
  }

  onClick = () => {
    this.setState({fold: false})
  }

  render() {
    return <div onClick={this.onClick}>
      <pre
        style={{
          ...styles,
          maxHeight: this.state.fold ? 262 : null,
        }}
        dangerouslySetInnerHTML={{__html: parseMarkdown(this.props.code)}}>
      </pre>
    </div>
  }
}

Fold.propTypes = {
  code: PropTypes.string.isRequired,
}

export default Fold
