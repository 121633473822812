import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const IssueBase = types
  .model({
    currentPriority: '',
    currentStatus: 'working',
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/issues', payload).then(({ error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#/issues`
      })
    },

    list() {
      self.loading = true
      get('/issues', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        priority: self.currentPriority,
        status: self.currentStatus,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    update(id, data, index) {
      self.loading = true
      put(`/issues/${id}`, data).then(({ data }) => {
        self.modifyItem(index, {
          priority: data.item.priority,
          status: data.item.status,
          loading: false,
        })
      })
    },

    delete(id) {
      del(`/issues/${id}`).then(() => self.list())
    },
  }))

const Issue = types
  .compose(
    BasicObject,
    IssueBase,
  )

export default Issue
