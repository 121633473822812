import React from 'react'
import { observer } from 'mobx-react'
import { DeleteOutlined, EyeInvisibleFilled, EyeOutlined, DownCircleFilled, DownCircleOutlined } from '@ant-design/icons'
import { Row, Col, Pagination, Modal, Popconfirm, Slider } from 'antd'
import util from '../../../utils'
import Spin from '../../../components/shared/form/spin'
import styles from './thumbnails.module.css'

class Index extends React.Component {
  state = {
    modalVisible: false,
    previewUrl: null,
    columnCount: 4,
  }
  main = window.store.tweetImageFile

  constructor(props) {
    super(props)

    this.main.modify({
      currentStatus: 'none',
    })
    this.main.list()
  }

  onPageChange = (page) => {
    this.main.modify({
      currentPage: page,
    })
    this.main.list()
  }

  onClickImage = (item) => {
    this.setState({ previewUrl: item })
    this.setModalVisible(true)
  }

  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  }

  onClickHide = (index, item) => {
    this.main.update(item.id, index, {
      status: item.status === 'hidden' ? 'none' : 'hidden',
    })
  }

  onClickMirror = (index, item) => {
    this.main.update(item.id, index, {
      mirror: item.mirror === 'local' ? 'none' : 'local',
    })
  }

  onClickDelete = (item) => {
    this.main.delete(item.id)
  }

  onColumnCountChange = (value) => {
    this.setState({ columnCount: value })
  }

  render() {
    let images = []
    for (let [index, i] of this.main.items.entries()) {
      let uri = i.source_url
      let icon
      let icon_
      if (i.status === 'hidden') {
        uri = 'https://noisy-morning-bfc5.eternal.workers.dev/320x240'
      }
      else if (i.mirror === 'local') {
        uri = util.mirror(i.key)
      }
      if (i.status === 'hidden') {
        icon = <EyeInvisibleFilled style={{ marginRight: 4 }} onClick={() => this.onClickHide(index, i)} />
      } else {
        icon = <EyeOutlined style={{ marginRight: 4 }} onClick={() => this.onClickHide(index, i)} />
      }
      if (i.mirror === 'local') {
        icon_ = <DownCircleFilled style={{ marginRight: 4 }} onClick={() => this.onClickMirror(index, i)} />
      } else {
        icon_ = <DownCircleOutlined style={{ marginRight: 4 }} onClick={() => this.onClickMirror(index, i)} />
      }
      images.push(
        <div className={styles.image} key={Math.random()}>
          <img src={uri} onClick={() => this.onClickImage(uri)} alt='thumbnail' />
          <div className={styles.editButton}>
            {icon}
            {icon_}
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => this.onClickDelete(i)}
              okText="Yes"
              cancelText="No">
              <DeleteOutlined style={{ marginRight: 4 }} />
            </Popconfirm>
          </div>
        </div>
      )
    }

    return (
      <Row>
        <Modal
          title="Preview"
          style={{ top: 20 }}
          visible={this.state.modalVisible}
          width={window.innerWidth * 0.9}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}>
          <div className={styles.previewImage}>
            <img src={this.state.previewUrl} alt='preview' />
          </div>
        </Modal>
        <Col span={24}>
          <div className={styles.pagination}>
            <Pagination current={this.main.pagination ? this.main.pagination.current : 1} onChange={this.onPageChange} total={this.main.pagination ? this.main.pagination.total : 0} />
            <div className={styles.statusBar}>
              <Slider step={1} value={this.state.columnCount} onChange={this.onColumnCountChange} max={10} />
            </div>
          </div>
          <div className={styles.images} style={{ columnCount: this.state.columnCount}}>
            {images}
          </div>
          <div style={{ clear: 'both' }} />
          <Spin loading={this.main.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
