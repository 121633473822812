import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  BookOutlined,
  HeartOutlined,
  HeartFilled,
  CheckCircleOutlined,
  CheckCircleFilled,
  PauseCircleOutlined,
  PauseCircleFilled,
  ReadOutlined,
  ReadFilled,
} from '@ant-design/icons'
import { Button, Form } from 'antd'
import Simple from '../../../components/shared/ant/table/simple'
import Status from '../../../components/shared/format/status'
import LengthLimiter from '../../../components/shared/format/length_limiter'
import ExternalLinks from '../../../components/shared/format/external_links'
import FloatDesc from '../../../components/shared/format/float_desc'
import Title from './components/title'
import util from '../../../utils'

const titleCSS = { position: 'relative', top: -8 }

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='bookmark'
        store={this.props.history.store}
        columns={'title link category status folder created_at control'}
        control={{
          status: {
            pending: {
              outlined: <PauseCircleOutlined />,
              filled: <PauseCircleFilled />,
            },
            fav: {
              outlined: <HeartOutlined />,
              filled: <HeartFilled />,
            },
            unread: {
              outlined: <ReadOutlined />,
              filled: <ReadFilled />,
            },
            done: {
              outlined: <CheckCircleOutlined />,
              filled: <CheckCircleFilled />,
            },
          }
        }}
        options={{
          setTitle: util.setTitle,
          title: (value, item) => <div style={titleCSS}>
            <Title status={item.status} text={<LengthLimiter length={96} text={value}></LengthLimiter>}></Title>
            <FloatDesc>
              <LengthLimiter length={120} text={item.url}></LengthLimiter>
            </FloatDesc>
          </div>,
          link: (value, item) => <ExternalLinks list={[item.url]} ></ExternalLinks>,
          category: value => <Status type="status" text={value}></Status>,
          folder: value => <span>{value ? value.title : ''}</span>,
          created_at: 'sinceThin',
          create: null,
          edit: null,
          filters: ['title', 'url'],
        }}
        additionalButtons={
          <>
            <Form.Item>
              <Link to='/folders'>
                <Button>Folders</Button>
              </Link>
            </Form.Item>
            <Form.Item>
              <a href={`javascript:window.open('${window.location.origin}/#/bookmarks/create?title='+window.btoa(encodeURIComponent(window.document.title)).replace('=', '|')+'&url='+window.btoa(encodeURIComponent(window.location.href)).replace('=', '|'));`}>
                <Button icon={<BookOutlined />} />
              </a>
            </Form.Item>
          </>
        }
      />
    )
  }
}

export default observer(Index)
