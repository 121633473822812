import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import moment from 'moment'

class Ranger extends React.Component {
  onChange = (e) => {
    if (this.props.onChange) this.props.onChange(e)
  }

  render() {
    return (
      <DatePicker.RangePicker
        ranges={{
          Today: [moment(), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
        }}
        showTime
        format="YYYY/MM/DD HH:mm:ss"
        onChange={this.onChange}
        value={this.props.value}
      />
    )
  }
}

Ranger.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any,
}

export default Ranger
