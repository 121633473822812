import { types } from 'mobx-state-tree'
import BasicObject from './base'
import utils from '../../utils'
import queryString from 'query-string'

const UserBase = types
  .model({
    token: '',
    user_id: '',
    nickname: 'User',
    level: '',

    hideCover: localStorage.getItem('hideCover') === 'true' ? true : false,

    uuid: utils.uuidv4(),
  })
  .actions(self => ({
    checkLogin() {
      const hash = queryString.parse(window.location.search)
      if (hash.token) {
        localStorage.setItem('tokenMT', hash.token)
         window.location = window.location.origin + window.location.pathname + window.location.hash
      }
      self.token = hash.token || localStorage.getItem('token') || ''
      self.user_id = localStorage.getItem('user_id') || ''
      self.username = localStorage.getItem('username') || 'User'
      self.nickname = localStorage.getItem('nickname') || 'User'
      self.level = localStorage.getItem('level') || ''
      if (!self.token && window.location.pathname.startsWith('/#/login')) {
        window.location = '/#/login'
      }
    },
  }))

const User = types
  .compose(
    BasicObject,
    UserBase,
  )

export default User
