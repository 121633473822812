import { types } from 'mobx-state-tree'
import _ from 'lodash'
import { post, get, put, patch } from '../../utils/request'
import BasicObject from '../basic/base'
import { WindowsFilled } from '@ant-design/icons'

const TodoBase = types
  .model({
    currentStatus: 'open',

    target: types.optional(types.frozen(), {}),
    moveMode: false,
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/todos', payload).then(({ data, error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#/todos/${data.todo_id}`
      })
    },

    list() {
      self.loading = true
      get('/todos', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    read(id) {
      get('/todos/' + id).then(self.setItemData)
    },

    update(target, data) {
      self.loading = true
      let item = _.clone(self.item)
      _.set(item, target, data)
      self.item = item
      put(`/todos/${self.item.id}`, item).then(self.hideLoading)
    },
    moveFromPathToPath(level, index) {
      self.loading = true
      let item = _.clone(self.item)
      let key = self.target.level + `[${self.target.index}]`
      let i = _.get(item, key)
      // remove
      let levelDataA = _.get(item, self.target.level)
      _.pullAt(levelDataA, self.target.index)
      _.set(item, self.target.level, levelDataA)
      // determine moveTo index
      let targetIndex = index + 1
      if (level === self.target.level && self.target.index < index) {
        targetIndex = index
      }
      // insert
      let levelDataB = _.get(item, level)
      levelDataB.splice(targetIndex, 0, i)
      _.set(item, level, levelDataB)
      self.item = item
      self.moveMode = false
      self.target = {}
      put(`/todos/${self.item.id}`, item).then(self.hideLoading)
    },

    updateStatus(payload) {
      patch(`/todos/${self.item.id}/status`, payload).then(({ error }) => {
        if (error) return
        window.location.href = `/#/todos`
      })
    },

    clone(){
      post('/todos', self.item).then(({ data }) => {
        put(`/todos/${data.todo_id}`, self.item).then(() => {
          window.location.href = `/#/todos/${data.todo_id}`
          window.location.reload()
        })
      })
    },
  }))

const Todo = types
  .compose(
    BasicObject,
    TodoBase,
  )

export default Todo
