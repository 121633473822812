import React from 'react'
import { observer } from 'mobx-react'
import { PauseCircleOutlined } from '@ant-design/icons'
import { Row, Col, Pagination, Popconfirm, Input, Button, Form, Tag } from 'antd'
import moment from 'moment'
import util from '../../../utils'
import LengthLimitedInput from '../../../components/shared/form/length_limited_input'
import Spin from '../../../components/shared/form/spin'
import Fold from '../../../components/shared/format/fold'
import styles from './view.module.css'
import stylesIndex from './index.module.css'

class View extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      fold: false,
      titleLength: 0,
      savedAt: null,
    }

    window.store.snippet.modify({
      currentViewPage: 1,
    })
    window.store.snippet.read(this.props.match.params.id)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      content: localStorage.getItem('snippet.textarea'),
    })
  }

  handleFold = () => {
    this.setState({ fold: !this.state.fold })
  }

  handleRemove = () => {
    window.store.snippet.delete(this.props.match.params.id)
  }

  preventNull = (value, def) => {
    return value === null ? def : value
  }

  handleOnChange = (page) => {
    window.store.snippet.modify({
      currentViewPage: page,
    })
    window.store.snippet.read(this.props.match.params.id)
  }

  passCheck = (rule, value, callback) => {
    callback()
  }

  handleSubmit = values => {
    window.store.snippet.create({
      parent_id: this.props.match.params.id,
      status: 'private',
      ...values,
    }, () => {
      localStorage.setItem('snippet.textarea', '')
      this.formRef.current.setFieldsValue({
        filename: '',
        content: '',
      })
    })
  }

  onClickMarkAsInvalid = (item) => {
    window.store.snippet.update(
      item.id,
      this.props.match.params.id,
      { status: item.status === 'invalid' ? 'private' : 'invalid' }
    )
  }

  handleTextareaChange = (item) => {
    localStorage.setItem('snippet.textarea', item.target.value)
    this.setState({ savedAt: moment().millisecond() })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 20 },
    }

    if (window.store.snippet.item.item) {
      util.setTitle(window.store.snippet.item.item.title)
    }

    let snippets = []
    if (window.store.snippet.item.items) {
      for (let item of window.store.snippet.item.items) {
        let more = ''
        if (item.status === 'invalid') {
          more = ' ' + styles.invalid
        }
        snippets.push(
          <div key={item.id} className={styles.subItem}>
            <div className="filename">
              <span className={styles.length}>
                <Tag color="blue">{item.content.length}C</Tag>
              </span>
              {item.filename || '-'}
              <div className={styles.rightBar}>
                <Button type="link" size="small" icon={<PauseCircleOutlined />} onClick={() => this.onClickMarkAsInvalid(item)} />
              </div>
            </div>
            {!this.state.fold &&
              <div className={styles.content + more}>
                <Fold code={item.content}/ >
              </div>
            }
          </div>
        )
      }
    }

    return (
      <Row>
        <Col span={24}>
          <Row>
            <div className="inner snippet">
              {window.store.snippet.item.item !== undefined &&
                <div>
                  <div className={styles.item}>
                    <div className={stylesIndex.titleBar}>
                      <span className={stylesIndex.title}>
                        {window.store.snippet.item.item.title || 'Untitled'}
                      </span>
                      <span className="status">
                        {window.store.snippet.item.item.status}
                      </span>
                      <span className="category">
                        {window.store.snippet.item.item.category || 'plain'}
                      </span>
                      <span className={stylesIndex.time}>
                        {moment(window.store.snippet.item.item.created_at).fromNow()}
                      </span>
                      <span className={styles.operationWithoutLink} onClick={this.handleFold}>
                        ({this.state.fold ? 'unfold' : 'fold'} content)
                      </span>
                      <span className={styles.operationWithoutLink}>
                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={this.handleRemove}>
                          (remove)
                        </Popconfirm>
                      </span>
                    </div>
                    <div className={styles.filename}>
                      {window.store.snippet.item.item.filename || '-'}
                    </div>
                    {!this.state.fold &&
                      <div className={styles.content}>
                        <Fold code={window.store.snippet.item.item.content} / >
                      </div>
                    }
                  </div>
                  <div className="divider"></div>
                  {snippets}
                  <div className={styles.pagination}>
                    <Pagination
                      total={window.store.snippet.itemPagination.total}
                      defaultCurrent={window.store.snippet.currentViewPage}
                      current={window.store.snippet.currentViewPage}
                      defaultPageSize={5}
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
              }
            </div>
            <Spin loading={window.store.snippet.loading} />
          </Row>
          <Row>
            <div className="viewForm">
              <Form ref={this.formRef}
                layout="horizontal"
                onFinish={this.handleSubmit}
                style={{ marginTop: 24 }}>
                <Form.Item {...formItemLayout}
                  name="filename"
                  rules={[{ whitespace: true }]}
                  label="Filename:"
                  validateStatus={window.store.snippet.errors.filename ? "error" : ""}
                  help={window.store.snippet.errors.filename}>
                  <LengthLimitedInput max={64} />
                </Form.Item>

                <Form.Item {...formItemLayout}
                  name="content"
                  rules={[{ whitespace: true }]}
                  label={`Content ${this.state.savedAt || ''}`}
                  validateStatus={window.store.snippet.errors.content ? "error" : ""}
                  help={window.store.snippet.errors.content}>
                  <Input.TextArea autoSize={{ minRows: 8, maxRows: 24 }} onChange={this.handleTextareaChange} />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 16, offset: 2 }}>
                  <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default observer(View)
