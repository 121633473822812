import React from 'react'
import PropTypes from 'prop-types'

class HighlightNum extends React.Component {
   render() {
    if (this.props.min && this.props.num >= this.props.min) {
      return (
        <span style={{ background: '#e6f7ff', color: '#1890ff' }}>
          {this.props.num}
        </span>
      )
    }
    if (this.props.max && this.props.num <= this.props.max) {
      return (
        <span style={{ background: '#e6f7ff', color: '#1890ff' }}>
          {this.props.num}
        </span>
      )
    }
    if (this.props.alert) {
      return (
        <span style={{ background: '#fff1f0', color: '#f5222d' }}>
          {this.props.num}
        </span>
      )
    }
    return <span>{this.props.num}</span>
  }
}

HighlightNum.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  num: PropTypes.number,
  alert: PropTypes.bool,
}

export default HighlightNum

/**
import HighlightNum from '../../components/shared/format/highlight_num'

price: value => <HighlightNum max={3500} num={value / 10000} />,
building_area: value => <HighlightNum min={100} num={value} />
*/
