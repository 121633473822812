import React from 'react'
import Simple from '../../../components/shared/ant/form/simple'

class Create extends React.Component {
  render() {
    return (
      <Simple
        keywords='subject'
        parent={this.props}
        columns={'app_id title uri category status'}
        options={{
          category: {
            type: 'select',
            values: 'ios android'
          },
          status: {
            type: 'select',
            values: 'none up down'
          },
        }}
      />
    )
  }
}

export default Create
