import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const ShoppingItemBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/shopping_items', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        category: self.currentCategory,
        keywords: self.currentKeywords,
        order_by: self.currentOrderBy,
      }).then(self.setData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/shopping_items/${id}`, data).then(() => {
        self.list()
      })
    },
  }))

const ShoppingItem = types
  .compose(
    BasicObject,
    ShoppingItemBase,
  )

export default ShoppingItem
