import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const WarehouseItemBase = types
  .model({
    currentTag: '',
    currentRisk: '',
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/warehouse_items', payload).then(({ data, error }) => {
        if (self.checkError(error)) return
        window.location.href = '/#/warehouse_items'
      })
    },

    list() {
      self.loading = true
      get('/warehouse_items', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        name: self.currentKeywords,
        tag: self.currentTag,
        category: self.currentCategory,
        risk: self.currentRisk,
      }).then(self.setData)
    },

    read(id) {
      get('/warehouse_items/' + id).then(self.setItemData)
    },

    update(id, data) {
      self.loading = true
      put(`/warehouse_items/${id}`, data).then(() => {
        window.location.href = '/#/warehouse_items'
      })
    },
  }))

const WarehouseItem = types
  .compose(
    BasicObject,
    WarehouseItemBase,
  )

export default WarehouseItem
