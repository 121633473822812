import React from 'react'
import { Link } from 'react-router-dom'
import {
  FileOutlined,
} from '@ant-design/icons'
import Simple from '../../../components/shared/ant/table/simple'
import util from '../../../utils'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='doc'
        store={this.props.history.store}
        columns={'title preview status updated_at created_at'}
        control={{
        }}
        options={{
          setTitle: util.setTitle,
          title: (value, item) => {
            return <Link to={`/docs/edit/${item.id}`}>
              {value}
            </Link>
          },
          preview: (value, item) => {
            return <Link to={`/docs/${item.id}/preview`}>
              <FileOutlined />
            </Link>
          },
          create: null,
          edit: null,
          size: 'small',
        }}
      />
    )
  }
}

export default Index
