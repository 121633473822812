import { types } from 'mobx-state-tree'
import _ from 'lodash'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const TranslationBase = types
  .model({
    currentPageSlave: 1,
    currentSentence: '',
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/translations', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    create(data) {
      self.loading = true
      post('/translations', data).then(() => {
        window.location.href = '/#/translations'
      })
    },
    createText() {
      self.loading = true
      post('/translation_texts', {
        master_id: self.item.item.id,
        raw: self.currentSentence,
      }).then(() => {
        self.read(self.item.item.id)
      })
    },

    setItem(data) {
      self.item = data.data
      self.itemPagination = {
        current: self.subCurrentPage,
        total: data.data.total,
      }
      self.loading = false
    },
    read(id) {
      get('/translations/' + id, {
        page: self.currentPageSlave,
      }).then(self.setItem)
    },
    modifyItemsOfItem(index, data) {
      let item = _.cloneDeep(self.item)
      for (let index_ in data) {
        item.items[index][index_] = data[index_]
      }
      self.item = item
    },

    update(id, index, data) {
      self.loading = true
      put(`/translations/${id}`, data).then(() => {
        self.modifyItem(index, data)
      })
    },
  }))

const Translation = types
  .compose(
    BasicObject,
    TranslationBase,
  )

export default Translation
