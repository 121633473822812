import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const FolderBase = types
  .model({
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/folders', payload).then(({ error }) => {
        if (self.checkError(error)) return
        self.list()
      })
    },

    beforeSetData(data) {
      data.data.items = data.data.items.map((item) => {
        return {
          title: item.title,
          key: item.id,
        }
      }).concat([
        {
          title: '[+]',
          key: 'create_root_folder',
          isLeaf: true,
        },
      ])
      self.setData(data)
    },
    list() {
      self.loading = true
      get('/folders', {
        page: self.currentPage,
      }).then(self.beforeSetData)
    },

    read(id) {
      get('/folders/' + id).then(self.setItemData)
    },
  }))

const Folder = types
  .compose(
    BasicObject,
    FolderBase,
  )

export default Folder
