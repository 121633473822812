import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  CloudOutlined,
  HomeOutlined,
  WindowsOutlined,
} from '@ant-design/icons'
import styles from './siderbase.module.css'

const useHash = true

function SiderBase(props) {
  const uri = useHash ? window.location.hash.replace('#', '') : window.location.pathname
  const selectedKeysFilter = () => '/' + uri.split('/')[1]
  const selectedKeysFilterForAdmin = () => uri.split('/')[2] === undefined ? '/admin' : '/admin/' + uri.split('/')[2]

  let history = useHistory()
  const [collapsed, setCollapsed] = useState(true)

  const handleClick = (e) => {
    history.push(e.key)
  }

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  let menu = null
  if (window.location.pathname.startsWith('/admin')) {
    let adminMenuItems = []
    if (props.menu) {
      for (let i of props.menu.adminMenu) {
        adminMenuItems.push(
          <Menu.Item key={i.key}>
            {i.name}
          </Menu.Item>
        )
      }
    }

    menu = (
      <Menu
        theme="dark"
        mode="inline"
        onClick={handleClick}
        className={styles.menu}
        defaultSelectedKeys={[selectedKeysFilterForAdmin()]}>
        <Menu.Item key="/admin" className={styles.first} icon={<HomeOutlined />}>
          Index
        </Menu.Item>
        {adminMenuItems}
        <Menu.Item className={styles.item} key="/" icon={<WindowsOutlined />}>
          Back to normal
        </Menu.Item>
      </Menu>
    )
  } else {
    let userMenuItems = []
    if (props.menu) {
      for (let i of props.menu.userMenu) {
        if (i.sub) {
          let subs = []
          for (let j of i.sub) {
            subs.push(
              <Menu.Item
                key={j.key}
                icon={j.icon ? j.icon : null}>
                {j.name}
              </Menu.Item>
            )
          }
          userMenuItems.push(
            <Menu.SubMenu
              key={'main_' + i.key}
              className={styles.item}
              title={i.name}
              icon={i.icon ? i.icon : <CloudOutlined />}>
              <Menu.Item key={i.key}>
                {i.name}
              </Menu.Item>
              {subs}
            </Menu.SubMenu>
          )
        } else {
          userMenuItems.push(
            <Menu.Item
              key={i.key}
              icon={i.icon ? i.icon : <CloudOutlined />}>
              {i.name}
            </Menu.Item>
          )
        }
      }
    }

    var userLoggedIn
    if (window.store.user) {
      userLoggedIn = (
        <Menu.SubMenu
          key="userLoggedIn"
          className={styles.item}
          title={<span>{window.store.user.nickname}</span>}
          icon={<UserOutlined />}>
          <Menu.Item key="/settings">
            Settings
          </Menu.Item>
          <Menu.Item key="/logout">
            Logout
          </Menu.Item>
        </Menu.SubMenu>
      )
    }

    let user = []
    let admin = null
    if (window.store.user && window.store.user.token) {
      user = userLoggedIn
      if (window.store.user.level === 'admin') {
        admin = (
          <Menu.Item
            className={styles.item}
            key="/admin"
            icon={<WindowsOutlined />}>
            Admin
          </Menu.Item>
        )
      }
      menu = (
        <Menu
          theme="dark"
          mode="inline"
          onClick={handleClick}
          className={styles.menu}
          defaultSelectedKeys={[selectedKeysFilter()]}>
          <Menu.Item key="/" className={styles.first} icon={<HomeOutlined />}>
            Index
          </Menu.Item>
          {userMenuItems}
          {user}
          {props.adminMenu ? admin : null}
        </Menu>
      )
    }
    else {
      user.push(
        <Menu.Item className={styles.item} key="/login" icon={<UserOutlined />}>
          Login
        </Menu.Item>
      )
      if (props.showRegister) user.push(
        <Menu.Item className={styles.item} key="/register" icon={<UserOutlined />}>
          Register
        </Menu.Item>
      )
      menu = (
        <Menu
          theme="dark"
          mode="inline"
          onClick={handleClick}
          className={styles.menu}
          selectedKeys={[selectedKeysFilter()]}>
          <Menu.Item key="/" className={styles.first} icon={<HomeOutlined />}>
            Index
          </Menu.Item>
          {user}
        </Menu>
      )
    }
  }

  return (
    <Layout className="wrapper">
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={styles.sider}>
        <div className={styles.logo}>
          <Link to="/" className={styles.title}>
            {props.menu ? props.menu.siteName : 'Admin'}
          </Link>
        </div>
        {menu}
      </Layout.Sider>
      <Layout className="layout">
        <Layout.Header className="layout_header">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            style: {
              fontSize: '18px',
              lineHeight: '64px',
              padding: '0 24px',
              cursor: 'pointer',
              transition: 'color 0.3s',
              // color: '#1890ff',
            },
            onClick: toggle,
          })}
        </Layout.Header>
        <Layout.Content className="layout_content">
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default SiderBase
