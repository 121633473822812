import React from 'react'
import { Link } from 'react-router-dom'
import {
  CheckCircleOutlined,
  CheckCircleFilled,
} from '@ant-design/icons'
import Simple from '../../../components/shared/ant/table/simple'
import DateTime from '../../../components/shared/format/datetime'
import util from '../../../utils'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='map plan'
        slug={`map_plans/${this.props.match.params.id}`}
        store={this.props.history.store}
        columns={'day_at day_name name status created_at edit'}
        options={{
          setTitle: util.setTitle,
          param: this.props.match.params.id,
          name: (value, item) => {
            if (item.status === 'done') {
              value = <span style={{ opacity: 0.2 }}>{value}</span>
            }
            return <span>{value}</span>
          },
          edit: (value, item) => {
            return <span>
              <Link to={`/map_plans/${item.id}/timeline`}>timeline</Link> / <Link to={`/map_plans/edit/${item.id}`}>edit</Link>
            </span>
          },
          day_at: value => <DateTime type='date' text={value}></DateTime>,
          create: null,
          size: 'small',
        }}
      />
    )
  }
}

export default Index
