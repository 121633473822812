import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form } from 'antd'
import Status from '../../../components/shared/format/status'
import Simple from '../../../components/shared/ant/table/simple'

const category = {
  device: 'green',
  cable: 'blue',
  consumeable: 'orange',
  container: 'purple',
}
const risk = {
  large: 'red',
  medium: 'orange',
}
const status = {
  transfer: 'red',
  backpack: 'green',
  car: 'green',
  installed: 'green',
  room: 'green',
  warehouse: 'purple',
  pending: 'orange',
}

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='warehouse item'
        store={this.props.history.store}
        columns='status name tag tag_sn amount comment category risk control updated_at'
        control={{
        }}
        options={{
          size: 'small',
          name: (value, item) => {
            if (item.location === 'transfer') {
              return (
                <span style={{ background: '#e6f7ff', color: '#1890ff' }}>
                  {value}
                </span>
              )
            }
            if (item.status === 'recycle' || item.status === 'invalid') {
              return (
                <span style={{ opacity: 0.2, textDecoration: 'line-through' }}>
                  {value}
                </span>
              )
            }
            return value
          },
          category: value => <Status type="status" text={value} pairs={category}></Status>,
          risk: value => <Status type="status" text={value} pairs={risk}></Status>,
          status: value => <Status type="status" text={value} pairs={status}></Status>,
          comment: value => <span className="longest">{value}</span>,
          create: null,
          edit: null,
          filters2: {
            status: ' room warehouse transfer backpack car installed pending recycle',
            category: ' device cable consumable container grocery tool',
            risk: ' small medium large',
          },
        }}
        additionalButtons={
          <>
            <Form.Item>
              <Link to='/warehouse_changelogs'>
                <Button>Changelogs</Button>
              </Link>
            </Form.Item>
          </>
        }
      />
    )
  }
}

export default Index
export { status }
