import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Card } from 'antd'
import Spin from '../../../components/shared/form/spin'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showWarning: false,
    }

    window.store.bookmark.read(this.props.match.params.id)
  }

  render() {
    return (
      <Row>
        <Col span={24}>
          <Card title={window.store.bookmark.item.title} extra={<Link to={`/bookmarks/edit/${window.store.bookmark.item.id}`}>Edit</Link>}>
            <p>{window.store.bookmark.item.url}</p>
          </Card>
          <Spin loading={window.store.bookmark.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(View)
