import React from 'react'
import {
  Switch,
  Route
} from 'react-router-dom'
// basic
import Mobx from '../models/mobx'
import Login from './basic/login'
import Logout from './basic/logout'
import Admin from './admin'
import AdminApps from './basic/apps'
import AdminAppsCreate from './basic/apps/create'
import Nav from '../components/nav'
import Footer from '../components/footer'
// warehouse
import Tweets from './warehouse/tweets'
import TweetShards from './warehouse/tweet_shards'
import TweetUsers from './warehouse/tweet_users'
import TweetImages from './warehouse/tweet_images'
import TweetImageFiles from './warehouse/tweet_image_files'
import TweetImageFilesThumbnails from './warehouse/tweet_image_files/thumbnails'
import TelegramLogs from './warehouse/telegram_logs'
import ShoppingItems from './warehouse/shopping_items'
import Uploads from './warehouse/uploads'
import UploadsCreate from './warehouse/uploads/create'
import UploadsView from './warehouse/uploads/view'
import WarehouseItems from './warehouse/warehouse_items'
import WarehouseItemsCreate from './warehouse/warehouse_items/create'
import WarehouseChangelogs from './warehouse/warehouse_changelogs'
// vault
import Books from './vault/books'
// works
import Snippets from './works/snippets'
import SnippetsCreate from './works/snippets/create'
import SnippetsView from './works/snippets/view'
import Todos from './works/todos'
import TodosCreate from './works/todos/create'
import TodosView from './works/todos/view'
import TodosBackup from './works/todos/backup'
import Docs from './works/docs'
import DocsEdit from './works/docs/edit'
import DocsPreview from './works/docs/preview'
import Bookmarks from './works/bookmarks'
import BookmarksView from './works/bookmarks/view'
import BookmarksCreate from './works/bookmarks/create'
import Folders from './works/folders'
import Issues from './works/issues'
import IssuesCreate from './works/issues/create'
import Charts from './works/charts'
import ChartsCreate from './works/charts/create'
import ChartsView from './works/charts/view'
import Translations from './works/translations'
import TranslationsCreate from './works/translations/create'
import TranslationsView from './works/translations/view'
import Maps from './works/maps'
import MapsCreate from './works/maps/create'
import MapsView from './works/maps/view'
import MapsBookmark from './works/maps/bookmark'
import MapPlans from './works/map_plans'
import MapPlansCreate from './works/map_plans/create'
import MapPlansView from './works/map_plans/view'
import Subscriptions from './works/subscriptions'
import SubscriptionsCreate from './works/subscriptions/create'
import Subjects from './works/subjects'
import SubjectsCreate from './works/subjects/create'
import Pages from './works/pages'
import PagesCreate from './works/pages/create'
import PagesView from './works/pages/view'
// moetrack
import Objs from './moetrack/objs'
import ObjsCreate from './moetrack/objs/create'
import ObjsView from './moetrack/objs/view'
import ObjectEpisodesCreate from './moetrack/object_episodes/create'
import ObjectEpisodesBulk from './moetrack/object_episodes/bulk'
import Collections from './moetrack/collections'
import CollectionsCreate from './moetrack/collections/create'
import CollectionsView from './moetrack/collections/view'
import UserTokens from './moetrack/user_tokens'
import SourceBgms from './moetrack/source_bgms'
import Tags from './moetrack/tags'
import TagsCreate from './moetrack/tags/create'
import Characters from './moetrack/characters'
import Lists from './moetrack/lists'
import People from './moetrack/people'

window.store = Mobx.create({})
window.store.user.checkLogin()

const RouterConfig = () => (
  <Nav>
    <div style={{
      paddingRight: '.9375rem',
      paddingLeft: '.9375rem',
      marginTop: 12,
      marginBottom: 12,
    }}>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/admin" exact component={Admin} />
        <Route path="/admin/apps" exact component={AdminApps} />
        <Route path="/admin/apps/create" exact component={AdminAppsCreate} />
        <Route path="/admin/apps/edit/:id" exact component={AdminAppsCreate} />

        <Route path="/tweets" exact component={Tweets} />
        <Route path="/tweet_shards" exact component={TweetShards} />
        <Route path="/tweet_users" exact component={TweetUsers} />
        <Route path="/tweet_images" exact component={TweetImages} />
        <Route path="/tweet_image_files" exact component={TweetImageFiles} />
        <Route path="/tweet_image_files/thumbnails" exact component={TweetImageFilesThumbnails} />
        <Route path="/telegram_logs" exact component={TelegramLogs} />
        <Route path="/shopping_items" exact component={ShoppingItems} />

        <Route path="/uploads" exact component={Uploads} />
        <Route path="/uploads/create" exact component={UploadsCreate} />
        <Route path="/uploads/:id/view" exact component={UploadsView} />
        <Route path="/uploads/:id" exact component={UploadsCreate} />
        <Route path="/warehouse_items" exact component={WarehouseItems} />
        <Route path="/warehouse_items/create" exact component={WarehouseItemsCreate} />
        <Route path="/warehouse_items/edit/:id" exact component={WarehouseItemsCreate} />
        <Route path="/warehouse_changelogs" exact component={WarehouseChangelogs} />
        <Route path="/books" exact component={Books} />

        <Route path="/snippets/create" exact component={SnippetsCreate} />
        <Route path="/snippets/:id" exact component={SnippetsView} />
        <Route path="/todos" exact component={Todos} />
        <Route path="/todos/create" exact component={TodosCreate} />
        <Route path="/todos/:id" exact component={TodosView} />
        <Route path="/todos/:id/backup" exact component={TodosBackup} />
        <Route path="/docs" exact component={Docs} />
        <Route path="/docs/create" exact component={DocsEdit} />
        <Route path="/docs/edit/:id" exact component={DocsEdit} />
        <Route path="/docs/:id/preview" exact component={DocsPreview} />
        <Route path="/bookmarks" exact component={Bookmarks} />
        <Route path="/bookmarks/create" exact component={BookmarksCreate} />
        <Route path="/bookmarks/:id" exact component={BookmarksView} />
        <Route path="/bookmarks/edit/:id" exact component={BookmarksCreate} />
        <Route path="/folders" exact component={Folders} />
        <Route path="/issues" exact component={Issues} />
        <Route path="/issues/create" exact component={IssuesCreate} />
        <Route path="/charts" exact component={Charts} />
        <Route path="/charts/create" exact component={ChartsCreate} />
        <Route path="/charts/edit/:id" exact component={ChartsCreate} />
        <Route path="/charts/:id" exact component={ChartsView} />
        <Route path="/translations" exact component={Translations} />
        <Route path="/translations/create" exact component={TranslationsCreate} />
        <Route path="/translations/:id" exact component={TranslationsView} />
        <Route path="/maps" exact component={Maps} />
        <Route path="/maps/create" exact component={MapsCreate} />
        <Route path="/maps/edit/:id" exact component={MapsCreate} />
        <Route path="/maps/bookmark" exact component={MapsBookmark} />
        <Route path="/maps/:id" exact component={MapsView} />
        <Route path="/map_plans/:id/create" exact component={MapPlansCreate} />
        <Route path="/map_plans/edit/:id" exact component={MapPlansCreate} />
        <Route path="/map_plans/:id/timeline" exact component={MapPlansView} />
        <Route path="/map_plans/:id" exact component={MapPlans} />
        <Route path="/subscriptions" exact component={Subscriptions} />
        <Route path="/subscriptions/create" exact component={SubscriptionsCreate} />
        <Route path="/subscriptions/edit/:id" exact component={SubscriptionsCreate} />
        <Route path="/subjects" exact component={Subjects} />
        <Route path="/subjects/create" exact component={SubjectsCreate} />
        <Route path="/pages" exact component={Pages} />
        <Route path="/pages/create" exact component={PagesCreate} />
        <Route path="/pages/edit/:id" exact component={PagesCreate} />
        <Route path="/pages/:id" exact component={PagesView} />

        <Route path="/objs" exact component={Objs} />
        <Route path="/objs/create" exact component={ObjsCreate} />
        <Route path="/objs/:id" exact component={ObjsView} />
        <Route path="/objs/edit/:id" exact component={ObjsCreate} />
        <Route path="/object_episodes/create" exact component={ObjectEpisodesCreate} />
        <Route path="/object_episodes/edit/:id" exact component={ObjectEpisodesCreate} />
        <Route path="/object_episodes/bulk" exact component={ObjectEpisodesBulk} />
        <Route path="/collections" exact component={Collections} />
        <Route path="/collections/create" exact component={CollectionsCreate} />
        <Route path="/collections/edit/:id" exact component={CollectionsCreate} />
        <Route path="/collections/:id" exact component={CollectionsView} />
        <Route path="/user_tokens" exact component={UserTokens} />
        <Route path="/source_bgms" exact component={SourceBgms} />
        <Route path="/tags" exact component={Tags} />
        <Route path="/tags/edit/:id" exact component={TagsCreate} />
        <Route path="/characters" exact component={Characters} />
        <Route path="/lists" exact component={Lists} />
        <Route path="/people" exact component={People} />

        <Route path="/" exact component={Snippets} />
      </Switch>
    </div>
    <Footer />
  </Nav>
)

export default RouterConfig
