import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Select, DatePicker, Form, InputNumber } from 'antd'
import { get } from '../../../utils/request'
import moment from 'moment'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      editMode: this.props.match.path === '/subscriptions/edit/:id',
    }

    if (this.state.editMode) {
      this.loadData()
    }
  }

  loadData = async () => {
    let origin = (await get('/subscriptions/' + this.props.match.params.id)).data
    this.formRef.current.setFieldsValue({
      ...origin,
      start_or_end_at: moment(origin.start_or_end_at),
    })
  }

  handleSubmit = values => {
    if (window.store.subscription.loading) return

    if (this.state.editMode) {
      window.store.subscription.update(this.props.match.params.id, values, null)
    } else {
      window.store.subscription.create(values)
    }
  }

  passCheck(rule, value, callback) {
    callback()
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout}
              name="name"
              rules={[{ whitespace: true }]}
              label="Name:"
              validateStatus={window.store.subscription.errors.name ? "error" : ""}
              help={window.store.subscription.errors.name}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="desc"
              initialValue=''
              rules={[{ whitespace: true }]}
              label="Description:"
              validateStatus={window.store.subscription.errors.desc ? "error" : ""}
              help={window.store.subscription.errors.desc}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="amount"
              label="Amount:"
              validateStatus={window.store.subscription.errors.amount ? "error" : ""}
              help={window.store.subscription.errors.amount}>
              <InputNumber />
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="start_or_end_at"
              rules={[{ validator: this.passCheck, }]}
              label="Start or terminate:"
              validateStatus={window.store.subscription.errors.start_or_end_at ? "error" : ""}
              help={window.store.subscription.errors.start_or_end_at}>
              <DatePicker />
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="currency"
              rules={[{ validator: this.passCheck, }]}
              initialValue='cny'
              label="Currency:"
              validateStatus={window.store.subscription.errors.currency ? "error" : ""}
              help={window.store.subscription.errors.currency}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key="cny">cny</Select.Option>
                <Select.Option key="usd">usd</Select.Option>
                <Select.Option key="jpy">jpy</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="category"
              rules={[{ validator: this.passCheck, }]}
              initialValue='onetime'
              label="Category:"
              validateStatus={window.store.subscription.errors.category ? "error" : ""}
              help={window.store.subscription.errors.category}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key="onetime">onetime</Select.Option>
                <Select.Option key="monthly">monthly</Select.Option>
                <Select.Option key="yearly">yearly</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.subscription.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
