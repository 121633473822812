import React from 'react'
import PropTypes from 'prop-types'

class ClickToView extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: false }
  }

  handleClick = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  render() {
    return (
      <span onClick={this.handleClick} style={{ cursor: 'pointer' }}>
        {this.state.show &&
          <span>{this.props.text}</span>
        }
        {!this.state.show &&
          <span style={{ color: '#aaa' }}>-- view --</span>
        }
      </span>
    )
  }
}

ClickToView.propTypes = {
  text: PropTypes.string,
}

export default ClickToView
