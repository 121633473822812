import { types } from 'mobx-state-tree'
// basic
import App from './basic/app'
import User from './basic/user'
// warehouse
import ShoppingItem from './warehouse/shopping_item'
import TweetImageFile from './warehouse/tweet_image_file'
import TweetUser from './warehouse/tweet_user'
import TweetImage from './warehouse/tweet_image'
import Tweet from './warehouse/tweet'
import TelegramLog from './warehouse/telegram_log'
import TweetShard from './warehouse/tweet_shard'
import Upload from './warehouse/upload'
import WarehouseItem from './warehouse/warehouse_item'
import WarehouseChangelog from './warehouse/warehouse_changelog'
// vault
import Book from './vault/book'
// works
import Translation from './works/translation'
import TranslationText from './works/translation_text'
import Subject from './works/subject'
import MapPlan from './works/map_plan'
import MapMark from './works/map_mark'
import Map from './works/map'
import Bookmark from './works/bookmark'
import Folder from './works/folder'
import Snippet from './works/snippet'
import Issue from './works/issue'
import Todo from './works/todo'
import TodoArchive from './works/todo_archive'
import Doc from './works/doc'
import Chart from './works/chart'
import Subscription from './works/subscription'
import Page from './works/page'
// moetrack
import Obj from './moetrack/obj'
import SourceBgm from './moetrack/source_bgm'
import ObjectEpisode from './moetrack/object_episode'
import Collection from './moetrack/collection'
import UserToken from './moetrack/user_token'
import Tag from './moetrack/tag'
import Character from './moetrack/character'
import Person from './moetrack/person'
import List from './moetrack/list'

const Mobx = types
  .model({
    // basic
    app: types.optional(App, {}),
    user: types.optional(User, {}),
    // warehouse
    shoppingItem: types.optional(ShoppingItem, {}),
    tweetImageFile: types.optional(TweetImageFile, {}),
    tweetUser: types.optional(TweetUser, {}),
    tweetImage: types.optional(TweetImage, {}),
    tweet: types.optional(Tweet, {}),
    telegramLog: types.optional(TelegramLog, {}),
    tweetShard: types.optional(TweetShard, {}),
    upload: types.optional(Upload, {}),
    warehouseItem: types.optional(WarehouseItem, {}),
    warehouseChangelog: types.optional(WarehouseChangelog, {}),
    // vault
    book: types.optional(Book, {}),
    // works
    translation: types.optional(Translation, {}),
    translationText: types.optional(TranslationText, {}),
    subject: types.optional(Subject, {}),
    mapPlan: types.optional(MapPlan, {}),
    mapMark: types.optional(MapMark, {}),
    map: types.optional(Map, {}),
    bookmark: types.optional(Bookmark, {}),
    folder: types.optional(Folder, {}),
    snippet: types.optional(Snippet, {}),
    issue: types.optional(Issue, {}),
    todo: types.optional(Todo, {}),
    todoArchive: types.optional(TodoArchive, {}),
    doc: types.optional(Doc, {}),
    chart: types.optional(Chart, {}),
    subscription: types.optional(Subscription, {}),
    page: types.optional(Page, {}),
    // moetrack
    sourceBgm: types.optional(SourceBgm, {}),
    obj: types.optional(Obj, {}),
    objectEpisode: types.optional(ObjectEpisode, {}),
    collection: types.optional(Collection, {}),
    userToken: types.optional(UserToken, {}),
    tag: types.optional(Tag, {}),
    character: types.optional(Character, {}),
    person: types.optional(Person, {}),
    list: types.optional(List, {}),
  })

export default Mobx
