import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Form,
  Input,
} from 'antd'

const PopupInput = (props) => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    const value = form.getFieldValue('value')
    props.callback(value)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button onClick={showModal}>
        Fill
      </Button>
      <Modal title="Input" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}>
          <Form.Item
            label="Value"
            name="value"
            rules={[{ required: true, message: 'Please input your value!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default PopupInput
