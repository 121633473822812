import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'antd'
import moment from 'moment'
import util from '../../../../utils'

class Indicator extends React.Component {
  render() {
    let time = moment(this.props.item.start_or_end_at)
    if (this.props.item.category !== 'onetime') {
      return <span></span>
    }
    let diffDays = time.diff(moment(), 'days')
    if (diffDays < 0) {
      return <span></span>
    }
    if (diffDays >= 30) {
      return <Progress percent={100} size="small" />
    }
    return <Progress percent={util.roundedToDecimals(diffDays / 30 * 100, 1)} size="small" />
  }
}

Indicator.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

export default Indicator
