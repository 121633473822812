import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import utils from '../../utils'
import BasicObject from '../basic/base'

const ObjBase = types
  .model({
  })
  .actions(self => ({
    create(data) {
      self.loading = true
      post(`${utils.MT}/admin/objs`, data).then(({ data, error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#/objs/${data.object_id}`
      })
    },

    batchCreateEpisode(sid, data) {
      self.loading = true
      post(`${utils.MT}/admin/objs/${sid}/ep_batch`, data).then(() => self.read(sid))
    },

    list() {
      self.loading = true
      get(`${utils.MT}/admin/objs`, {
        page: self.currentPage,
        per: self.pagination.pageSize,
        key: self.currentKey,
        keywords: self.currentKeywords,
        category: self.currentCategory,
      }).then(self.setData)
    },

    read(id) {
      get(`${utils.MT}/admin/objs/` + id).then(self.setItemData)
    },

    update(sid, data) {
      put(`${utils.MT}/admin/objs/${sid}`, data).then(({ data, error }) => {
        if (!error) window.location.href = `/#/objs/${sid}`
      })
    },

    delete(id, data) {
      del(`${utils.MT}/admin/objs/${id}`, data).then(() => self.read(id))
    },
  }))

export const Obj = types
  .compose(
    BasicObject,
    ObjBase,
  )

export default Obj
