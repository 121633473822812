import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Row, Col, Pagination, Input, Button } from 'antd'
import FilterBar from '../../../components/shared/filter_bar'
import util from '../../../utils'
import moment from 'moment'
import styles from './index.module.css'

class Snippets extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      keywords: '',
    }

    util.setTitle('Snippet')
    window.store.snippet.list()
  }

  handleOnChange = (page) => {
    window.store.snippet.modify({
      currentPage: page,
    })
    window.store.snippet.list()
  }

  preventNull = (value, def) => {
    return value === null ? def : value
  }

  handleSubmit = () => {
    window.store.snippet.modify({
      currentPage: 1,
      currentKeywords: this.state.keywords,
    })
    window.store.snippet.list()
  }

  emitEmpty = () => {
    this.setState({ keywords: '' })
    window.store.snippet.modify({
      currentPage: 1,
      currentKeywords: '',
    })
    window.store.snippet.list()
  }

  onChangeKeywords = (e) => {
    this.setState({ keywords: e.target.value })
  }

  render() {
    let items = []
    for (let item of window.store.snippet.items) {
      items.push(
        <div className={styles.item} key={item.id}>
          <div className={styles.titleBar}>
            <span className={styles.title}>
              <Link to={`/snippets/${item.id}`}>
                {item.title || 'Untitled'}
              </Link>
            </span>
            <span className="status">
              {item.status}
            </span>
            <span className="category">
              {item.category || 'plain'}
            </span>
            <span className={styles.time}>
              {moment(item.updated_at).fromNow()}
            </span>
          </div>
          <div className={styles.content + ' content'}>
            <pre>{item.cache}</pre>
          </div>
        </div>
      )
    }

    const filterLeft = <Link to={`/snippets/create`}>
      <Button type="dashed">New</Button>
    </Link>

    const filterRight = <span className="inlineFilter">
      <Input
        suffix={<CloseCircleOutlined onClick={this.emitEmpty} />}
        onChange={this.onChangeKeywords}
        onPressEnter={this.handleSubmit}
        value={this.state.keywords} />
      <Button type="primary" onClick={this.handleSubmit}>Filter</Button>
    </span>

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
          <div className={styles.items}>{items}</div>
          <div className={styles.pagination}>
            <Pagination
              total={window.store.snippet.pagination.total}
              defaultCurrent={window.store.snippet.currentPage}
              current={window.store.snippet.currentPage}
              defaultPageSize={10}
              onChange={this.handleOnChange}
              showTotal={total => `Total ${total} items`} />
          </div>
        </Col>
      </Row>
    )
  }
}

export default observer(Snippets)
