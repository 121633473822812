import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input } from 'antd'
import utils from '../../../utils'

class View extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
    }

    if (this.props.match.params.id) {
      window.store.upload.read(this.props.match.params.id)
    }
  }

  render() {
    const url = `${utils.bucketUpload}${window.store.upload.item.source_uri}`
    return (
      <Row>
        <Col span={24}>
          <Input style={{ width: '90%' }} disabled value={`uploads/${window.store.upload.item.source_uri}`} />
          <br />
          {window.store.upload.item.source_uri &&
            <img src={url} />
          }
        </Col>
      </Row>
    )
  }
}

export default observer(View)
