import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class Date extends React.Component {
  render() {
    let format = 'YYYY/MM/DD'
    if (this.props.type === 'year') format = 'YYYY'

    return (
      <span>{this.props.text ? moment(this.props.text).format(format) : null}</span>
    )
  }
}

Date.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
}

export default Date
