import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Button, Select, DatePicker, Form } from 'antd'
import { get } from '../../../../utils/request'
import queryString from 'query-string'
import moment from 'moment'

class Simple extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      editMode: this.props.parent.match.path.includes('/edit/'),
      data: {},
    }
    this.main = window.store[this.props.keywords.lowerCamelCase()]

    if (this.state.editMode) {
      this.loadData(this.props.id)
      return
    }
  }

  handleSubmit = values => {
    for (let item of this.props.columns.split(' ')) {
      if (item in this.props.options) {
        if (this.props.options[item].as === 'array') {
          values[item] = values[item] ? values[item].split('\n') : []
        }
      }
    }
    if (this.props.params) {
      for (let item in this.props.params) {
        values[item] = this.props.params[item]
      }
    }
    if (this.state.editMode) {
      this.main.update(this.props.id, values, this.state.data.id)
    } else {
      this.main.create(values)
    }
  }

  passCheck(rule, value, callback) {
    callback()
  }

  componentDidMount = () => {
    let parsed = queryString.parse(this.props.parent.location.search)
    parsed = Object.fromEntries(Object.entries(parsed).map(([k, v]) => {
      if (v.includes('|')) {
        v = decodeURIComponent(window.atob(v.replace('|', '=')))
      }
      return [k, v]
    }))
    for (let key in parsed) {
      if (this.props.options[key] && this.props.options[key].defaultValue) {
        delete parsed[key]
      }
    }
    this.formRef.current.setFieldsValue(parsed)
  }

  loadData = async (id) => {
    let origin = (await get(`/${this.props.slug}/${id}`)).data

    for (let item of this.props.columns.split(' ')) {
      if (item in this.props.options && this.props.options[item].type) {
        if (this.props.options[item].type === 'date') {
          origin = {
            ...origin,
            [item]: moment.utc(origin[item]),
          }
        }
      }
    }

    this.formRef.current.setFieldsValue(origin)
    this.setState({ data: origin })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    let items = []
    let id = 0
    for (let item of this.props.columns.split(' ')) {
      if (item in this.props.options && this.props.options[item].type) {
        if (this.props.options[item].type === 'select') {
          const optionsValue = this.props.options[item].values.split(' ')
          let options = []
          for (let option of optionsValue) {
            options.push(<Select.Option key={option}>{option}</Select.Option>)
          }
          items.push(<Form.Item {...formItemLayout}
            name={item}
            rules={[{ validator: this.passCheck, }]}
            initialValue={optionsValue[0]}
            key={id++}
            label={item.upperCamelCase() + ':'}
            validateStatus={this.main.errors[item] ? "error" : ""}
            help={this.main.errors[item]}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                {options}
              </Select>
          </Form.Item>)
        } else if (this.props.options[item].type === 'date') {
          items.push(<Form.Item {...formItemLayout}
            name={item}
            rules={[{ validator: this.passCheck, }]}
            key={id++}
            label={item.upperCamelCase() + ':'}
            validateStatus={this.main.errors[item] ? "error" : ""}
            help={this.main.errors[item]}>
              <DatePicker />
          </Form.Item>)
        } else if (this.props.options[item].type === 'textarea') {
          items.push(<Form.Item {...formItemLayout}
            name={item}
            rules={[{ validator: this.passCheck, }]}
            initialValue={this.props.options[item].defaultValue ? this.props.options[item].defaultValue() : ''}
            key={id++}
            label={item.upperCamelCase() + ':'}
            validateStatus={this.main.errors[item] ? "error" : ""}
            help={this.main.errors[item]}>
              <Input.TextArea autoSize={{ minRows: this.props.options[item].rows ? this.props.options[item].rows : 2, maxRows: this.props.options[item].rows ? this.props.options[item].rows * 2 : 6 }} />
          </Form.Item>)
        } else if (this.props.options[item].type === 'number') {
          items.push(<Form.Item {...formItemLayout}
            name={item}
            rules={[{ validator: this.passCheck, }]}
            initialValue={(this.props.options[item] && this.props.options[item].defaultValue) ? this.props.options[item].defaultValue() : ''}
            key={id++}
            label={item.upperCamelCase() + ':'}
            validateStatus={this.main.errors[item] ? "error" : ""}
            help={this.main.errors[item]}>
              <Input />
          </Form.Item>)
        }
      } else {
        items.push(<Form.Item {...formItemLayout}
          name={item}
          rules={[{ whitespace: true, }]}
          initialValue={(this.props.options[item] && this.props.options[item].defaultValue) ? this.props.options[item].defaultValue() : ''}
          key={id++}
          label={item.upperCamelCase() + ':'}
          validateStatus={this.main.errors[item] ? "error" : ""}
          help={this.main.errors[item]}>
            <Input />
        </Form.Item>)
      }
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            {items}

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={this.main.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

/**
<Simple
  keywords='subject'

  // for edit
  slug='warehouse_items'
  id={this.props.match.params.id}

  parent={this.props}
  columns={'app_id title uri category status'}
  options={{
    title: {
      defaultValue: () => '',
    },
    status: {
      type: 'select',
      values: 'none up down'
    },
    day_at: {
      type: 'date',
    },
    alias: {
      type: 'textarea',
      rows: 12,
      as: 'array',
      defaultValue: () => '',
    },
  }}
/>
 */
Simple.propTypes = {
  keywords: PropTypes.string,
  slug: PropTypes.string,
  /**
    'admin/tags'
   */
  id: PropTypes.string,
  /**
    13
   */
  parent: PropTypes.object,
  columns: PropTypes.string,
  options: PropTypes.object,
  /**
    {
      alias: {
        type: 'date',
      },
    }
  */
  params: PropTypes.object,
  /**
    // additional values for posting
    {
      map_id: this.props.match.params.id,
    }
  */
}

export default Simple
