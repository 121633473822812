import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  LinkOutlined,
  StarOutlined,
  StarFilled,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { Table, Radio, Button, Form, Popconfirm } from 'antd'
import DateTime from '../../../components/shared/format/datetime'
import Status from '../../../components/shared/format/status'
import FilterBar from '../../../components/shared/filter_bar'
import LengthLimiter from '../../../components/shared/format/length_limiter'

const styles = {
  icon: {
    marginRight: 4,
  },
  button: {
    marginLeft: 8,
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.tweetShard.list()
  }

  handleOnChange = (pagination) => {
    window.store.tweetShard.modify({
      currentPage: pagination.current,
    })
    window.store.tweetShard.list()
  }

  onStatusChange = (e) => {
    window.store.tweetShard.modify({
      currentPage: 1,
      currentStatus: e.target.value,
    })
    window.store.tweetShard.list()
  }

  onOrderByChange = (e) => {
    window.store.tweetShard.modify({
      currentPage: 1,
      currentOrderBy: e.target.value,
    })
    window.store.tweetShard.list()
  }

  onClickFavorite = (item, value) => {
    window.store.tweetShard.update(item.id, {
      flag: value
    })
  }

  favoriteAll = () => {
    window.store.tweetShard.favoriteAll()
  }

  onDeleteItem = (item) => {
    window.store.tweetShard.delete(item.id)
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: value => <Link to={`/tweet_shards/${value}`}>{value}</Link>,
      },
      {
        title: 'Tweet ID',
        dataIndex: 'tweet_id',
        key: 'tweet_id',
      },
      {
        title: 'Tweet User',
        dataIndex: 'tweet_user_id',
        key: 'tweet_user_id',
        render: (value, record) => <LengthLimiter length={12} text={record.tweet_user ? record.tweet_user.name : record.tweet_user_id}></LengthLimiter>
      },
      {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        render: (value, record) => <span style={record.flag === 'favorite' ? { background: '#e6f7ff', color: '#1890ff' } : {}}>
          <LengthLimiter length={64} text={value} showLinks={true}></LengthLimiter>
        </span>,
      },
      {
        title: 'Flag',
        dataIndex: 'flag',
        key: 'flag',
        render: value => <Status text={value}></Status>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status text={value}></Status>,
      },
      {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          {item.status !== 'deleted' &&
            <a href={`https://twitter.com/${item.tweet_user?.screen_name}/status/${item.tweet_id}`} target='_blank' rel="noopener noreferrer" style={{ cursor: 'pointer', marginRight: 5 }}><LinkOutlined /></a>
          }
          <span
            style={{ cursor: 'pointer', padding: '10px 5px 10px 0' }}
            onClick={() => this.onClickFavorite(item, item.flag === 'favorite' ? 'none' : 'favorite', index)}
          >
            {item.flag === 'favorite' &&
              <StarFilled style={styles.icon} />
            }
            {item.flag !== 'favorite' &&
              <StarOutlined style={styles.icon} />
            }
          </span>
          <span key={Math.random()}>
            <Popconfirm title="Are you sure?" onConfirm={() => this.onDeleteItem(item)} okText="Yes" cancelText="No">
              <span style={{ cursor: 'pointer', padding: '10px 5px 10px 0' }}>
                <CloseCircleOutlined style={{ marginRight: 4 }} />
              </span>
            </Popconfirm>
          </span>
        </span>,
      },
    ]

    const filterRight = (
      <Form layout="inline">
        <Button type="dashed" style={styles.button} onClick={this.favoriteAll}>Favorite all</Button>
        <Radio.Group style={styles.button} defaultValue='' onChange={this.onStatusChange}>
          <Radio.Button value=''>All</Radio.Button>
          <Radio.Button value='deleted'>Deleted</Radio.Button>
        </Radio.Group>
        <Radio.Group style={styles.button} defaultValue='' onChange={this.onOrderByChange}>
          <Radio.Button value=''>ID</Radio.Button>
          <Radio.Button value='timestamp'>Timestamp</Radio.Button>
        </Radio.Group>
      </Form>
    )

    return (
      <div>
        <FilterBar filterRight={filterRight} />
        <Table
          rowClassName={(_, index) =>
            index % 2 === 1 ? 'dark-row' : 'light-row'
          }
          bordered
          size="small"
          columns={columns}
          rowKey='id'
          dataSource={window.store.tweetShard.items}
          pagination={window.store.tweetShard.pagination}
          loading={window.store.tweetShard.loading}
          onChange={this.handleOnChange}
        />
      </div>
    )
  }
}

export default observer(Index)
