import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const TweetUserBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/tweet_users', {
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then(self.setData)
    },
  }))

const TweetUser = types
  .compose(
    BasicObject,
    TweetUserBase,
  )

export default TweetUser
