import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Input } from 'antd'
import _ from 'lodash'
import styles from './span.module.css'

class Span extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editMode: false,
      content: '',
    }
    this.editField = React.createRef()
  }

  componentDidUpdate = () => {
    if (this.editField.current) this.editField.current.focus()
  }

  handleClick = () => {
    this.setState({
      editMode: true,
      content: _.get(this.props.item, this.props.target)
    })
  }

  removeLeadingAndTrailingSpaces(content) {
    return content.replace(/^\s+|\s+$/g,'');
  }

  handleChange = () => {
    this.setState({ content: this.editField.current.textContent })
  }

  handleOk = () => {  // for modal
    this.setState({ editMode: false })
    if (this.state.content !== _.get(this.props.item, this.props.target)) {
      this.props.handle({
        target: this.props.target,
        value: this.removeLeadingAndTrailingSpaces(this.state.content)
      })
    }
  }

  handleCancel = (e) => {  // for modal
    this.setState({ editMode: false })
  }

  handleChangeModal = (e) => {  // for modal
    this.setState({ content: e.target.value })
  }

  render() {
    let value = _.get(this.props.item, this.props.target)
    if (this.props.isLink) {
      return <span className={this.props.highlight ? ('highlight' + this.props.highlight) : ''}>Link: <a href={value} target='_blank' rel="noopener noreferrer" className={styles.link}>{decodeURI(value)}</a></span>
    } else if (this.props.mode === 'modal') {
      return (
        <span>
          <Modal title="Editor" visible={this.state.editMode} onOk={this.handleOk} onCancel={this.handleCancel} okText="Submit" cancelText="Cancel">
            <Input.TextArea placeholder="Please input" value={this.state.content} onChange={this.handleChangeModal} onPressEnter={this.handleOk} ref={this.editField} autoSize={{ minRows: 3, maxRows: 10 }} />
          </Modal>

          <span className={styles.clickable + (this.state.editMode ? ' ' + styles.editMode : '')} onClick={this.handleClick}>
            {value &&
              <span className={this.props.highlight ? ('highlight' + this.props.highlight) : ''}>{value}</span>
            }
            {(!value || value.replace(' ', '').length <= 0) &&
              <span className={styles.placeholder}>______________________</span>
            }
          </span>
          {this.props.suffix &&
            <span className={styles.identity}>{this.props.suffix}</span>
          }
        </span>
      )
    } else if (this.state.editMode) {
      return (
        <span
          ref={this.editField}
          contentEditable
          onInput={this.handleChange}
          onBlur={this.handleOk}>
          {value}
        </span>
      )
    } else {
      return (
        <span>
          <span className={styles.clickable} onClick={this.handleClick}>
            {value &&
              <span className={this.props.highlight ? ('highlight' + this.props.highlight) : ''}>{value}</span>
            }
            {(!value || value.replace(' ', '').length <= 0) &&
              <span className={styles.placeholder}>______________________</span>
            }
          </span>
          {this.props.suffix &&
            <span className={styles.id}>{this.props.suffix}</span>
          }
        </span>
      )
    }
  }
}

Span.propTypes = {
  mode: PropTypes.string,  // editable / modal
  target: PropTypes.string,
  item: PropTypes.object,
  handle: PropTypes.func,
  suffix: PropTypes.string,
  highlight: PropTypes.string,
  isLink: PropTypes.bool,
}

export default Span
