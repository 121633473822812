import React from 'react'
import Simple from '../../../components/shared/ant/form/simple'

class Create extends React.Component {
  render() {
    return (
      <Simple
        id={this.props.match.params.id}
        keywords='map plan'
        slug={`map_plans`}
        parent={this.props}
        columns={'name day_at day_name'}
        options={{
          day_at: {
            type: 'date',
          },
        }}
        params={{
          map_id: this.props.match.params.id,
        }}
      />
    )
  }
}

export default Create
