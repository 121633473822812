import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { LinkOutlined } from '@ant-design/icons';
import { Row,
  Col,
  Input,
  InputNumber,
  Button,
  Select,
  DatePicker,
  Collapse,
  Form,
  Divider,
} from 'antd'
import moment from 'moment'
import queryString from 'query-string'
import PopupInput from '../../../components/shared/ant/input/popup_input'
import { get } from '../../../utils/request'
import utils from '../../../utils'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      carrier: 'anime',
      textInput: '',
      textInputMode: false,
      textInputAlias: '',
      textInputModeAlias: false,
      mode: this.props.match.path === '/objs/edit/:id' ? 'edit' : null,
      collections: [],
    }
    this.relations = {
      anime: ['tv', 'ova', 'movie', 'oad', 'sp', 'web'],
      book: ['comic_series', 'comic_offprint', 'novel_series', 'novel_offprint', 'general_book'],
      music: ['album'],
      game: ['games', 'dlc'],
      '3d': ['tv', 'ova', 'movie', 'oad', 'sp', 'web'],
    }
    this.main = window.store.obj

    if (this.props.match.path === '/objs/edit/:id') {
      this.loadData(this.props.match.params.id)
      return
    }

    this.season_generator = false
    let parsed = queryString.parse(this.props.location.search)
    if (parsed.sid) {
      this.loadSourceData(parsed.sid)
    } else if (parsed.oid) {
      this.season_generator = true
      this.loadData(parsed.oid)
    }
  }

  loadSourceData = async (sid, apply=true) => {
    this.main.startLoading()
    let source = (await get(`${utils.MT}/admin/source_bgms/` + sid)).data
    let alias = source.alias.filter((item) => item)
    if (alias.length <= 0) alias = [source.name]
    let data = {
      carrier: source.carrier,
      name: utils.cleanCrawledData(source.name),
      alias: utils.cleanCrawledData(alias.join("\n")),
      bio_long: source.data.summary,
      released_at: moment(source.released_at),
    }
    if (source.source.indexOf('bgm_') >= 0) {
      data.bgm_id = source.data.id
      data.bgm_score = source.data.rating ? source.data.rating.score : null
    }
    if (source.source.indexOf('douban_') >= 0) {
      data.douban_id = source.data.id
      data.douban_score = source.data.rating ? source.data.rating.score : null
    }
    if (source.source.indexOf('steam_') >= 0) {
      data.steam_id = source.data.id
      data.steam_score = source.data.rating ? source.data.rating.score : null
    }
    if (source.data.imdb_id) {
      data.imdb_id = source.data.imdb_id
    }
    if (source.data.images) {
      data.cover = source.data.images.cover
    }
    if (source.data.eps) {
      data.count_ep = source.data.eps.length
    } else if (source.data.eps_count) {
      data.count_ep = source.data.eps_count
    }
    if (source.data.vols_count) {
      setTimeout(() => {
        data.count_volume = source.data.vols_count
      }, 1000)
    }
    if (source.category) {
      data.category = source.category
    } else {
      this.onChangeCarrier(source.carrier)
    }
    if (apply) {
      setTimeout(() => {
        this.formRef.current.setFieldsValue(data)
        this.onBlurNameInput()
      }, 500)
      this.setState({
        carrier: source.carrier,
      })
    } else {
      const current = this.formRef.current.getFieldsValue()
      let extra = {}
      for (let key in data) {
        if (current[key] !== data[key]) {
          extra[key] = data[key]
        }
      }
      this.main.setExtra(extra)
    }
    this.main.hideLoading()
  }

  loadData = async (sid) => {
    let origin = (await get(`${utils.MT}/admin/objs/${sid}`)).data.obj
    if (this.season_generator) {
      let data = {
        carrier: origin.carrier,
        category: origin.category,
        name: origin.name,
        alias: origin.alias,
        count_ep: origin.count_ep,
      }
      if (/season/i.test(data.name)) {
        /(\d+).?$/.test(data.name)
        let season = Number(RegExp.$1)
        data.name = data.name.replace('Season ' + season, 'Season ' + (season + 1))
        let seasons = {
          1: '一',
          2: '二',
          3: '三',
          4: '四',
          5: '五',
          6: '六',
          7: '七',
          8: '八',
          9: '九',
          10: '十',
          11: '十一',
          12: '十二',
          13: '十三',
          14: '十四',
          15: '十五',
          16: '十六',
          17: '十七',
          18: '十八',
          19: '十九',
        }
        for (let i in data.alias) {
          data.alias[i] = data.alias[i].replace('第' + seasons[season] + '季', ' 第' + seasons[season + 1] + '季')
        }
      } else {
        data.name = data.name + ' Season 2'
        for (let i in data.alias) {
          data.alias[i] = data.alias[i] + ' 第二季'
        }
      }
      data.alias = data.alias.join("\n")
      if (!this.season_generator) {
        data.released_at = moment(origin.released_at)
      }
      this.formRef.current.setFieldsValue(data)
    } else {
      let data = {
        ...origin,
        alias: origin.alias.join("\n"),
        released_at: moment(origin.released_at),
      }
      if (origin.cover) {
        data.cover = origin.cover.replace(/^\/\//, 'https://')
      }
      this.formRef.current.setFieldsValue(data)
      this.setState({
        carrier: origin.carrier,
      })
    }
    let i = origin.name.split(' ')
    this.doSearchCollection(i.length > 1 ? i.slice(0, -1).join(' ') : origin.name)
  }

  handleSubmit = values => {
    let payload = {
      ...values,
      alias: values.alias ? values.alias.split('\n') : [],
      count_ep: values.count_ep ? values.count_ep : 0,
      count_volume: values.count_volume ? values.count_volume : 0,
    }
    if (this.state.mode === 'edit') {
      this.main.update(this.props.match.params.id, payload)
    } else {
      this.main.create(payload)
    }
  }

  passCheck(rule, value, callback) {
    callback()
  }

  onChangeCarrier = (value) => {
    this.setState({ carrier: value })
    this.formRef.current.setFieldsValue({
      category: this.relations[value][0]
    })
  }

  onClickTextInput = () => {
    this.setState({ textInputMode: true })
  }

  onTextInputChange = (e) => {
    this.setState({ textInput: e.target.value })
  }

  onClickTextInputConvert = () => {
    this.setState({ textInputMode: false })
    this.formRef.current.setFieldsValue({
      released_at: this.state.textInput ? moment(this.state.textInput.replace(/年|月/g, '-').replace(/日/g, ''), 'YYYY-MM-DD') : null,
    })
  }

  onClickTextInputAlias = () => {
    this.setState({ textInputModeAlias: true })
  }

  onTextInputChangeAlias = (e) => {
    this.setState({ textInputAlias: e.target.value })
  }

  onClickTextInputConvertAlias = () => {
    this.setState({ textInputModeAlias: false })
    if (this.state.textInputAlias) {
      let values = this.formRef.current.getFieldsValue()
      this.formRef.current.setFieldsValue({
        alias: (values.alias ? values.alias + '\n' : '') + this.state.textInputAlias.replace(/ \/ /g, '\n'),
      })
    }
  }

  onBlurNameInput = () => {
    window.store.obj.modify({
      currentPage: 1,
      currentKey: 'name',
      currentKeywords: this.formRef.current.getFieldsValue().name,
    })
    window.store.obj.list()
  }

  onClickBGMLink = () => {
    let data = this.formRef.current.getFieldsValue().bgm_id
    window.open(`https://bgm.tv/subject/${data}`)
  }

  onClickDoubanLink = () => {
    let data = this.formRef.current.getFieldsValue().douban_id
    window.open(`https://movie.douban.com/subject/${data}/`)
  }

  onClickSteamLink = () => {
    let data = this.formRef.current.getFieldsValue().steam_id
    window.open(`https://store.steampowered.com/app/${data}/`)
  }

  handleSearchCollection = (keywords) => {
    if (keywords) {
      this.doSearchCollection(keywords)
    } else {
      this.setState({ collections: [] })
    }
  }
  doSearchCollection = (keywords) => {
    get(`${utils.MT}/admin/collections`, {
      page: 1,
      per: 12,
      keywords: keywords,
    }).then(res => {
      this.setState({ collections: res.data.items })
    })
  }

  onClickExtra = (name, value) => {
    this.formRef.current.setFieldsValue({ [name]: value })
    this.main.setExtra({ [name]: null })
  }
  extra = (name) => {
    if (this.main.extra[name]) {
      return <span onClick={() => this.onClickExtra(name, this.main.extra[name])}>{this.main.extra[name]}</span>
    }
    return null
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }
    const formItemLayoutInline = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    }

    let categories = []
    for (let item of this.relations[this.state.carrier]) {
      categories.push(<Select.Option key={item}>{item}</Select.Option>)
    }

    const countEP = (
      <Col span={6}>
        <Form.Item {...formItemLayoutInline} label='Episodes:'
          name="count_ep"
          rules={[{ whitespace: true, type: 'number', }]}
          validateStatus={this.main.errors.count_ep ? "error" : ""}
          help={this.main.errors.count_ep}>
          <InputNumber />
        </Form.Item>
      </Col>
    )

    let countEPView
    if (this.state.carrier === 'book') {
      countEPView = countEP
    } else if (this.state.mode !== 'edit' && (this.state.carrier === 'anime' || this.state.carrier === '3d')) {
      countEPView = countEP
    }

    let objs = []
    for (let [key, item] of window.store.obj.items.entries()) {
      objs.push(<p key={key}>
        <Link target='_blank' rel="noopener noreferrer" to={`/objs/${item.id}`}>{item.id} | {item.carrier} | {item.category} | {item.name} | {item.alias?.join(', ')}</Link>
      </p>)
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            initialValues={{
              carrier: 'anime',
              category: 'tv',
              collection_relation: 'none',
            }}
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Carrier:"
              name="carrier"
              rules={[{ validator: this.passCheck, }]}
              extra={this.extra('carrier')}
              validateStatus={this.main.errors.carrier ? "error" : ""}
              help={this.main.errors.carrier}>
              <Select style={{ width: '100%' }} placeholder="Please select"
                onChange={this.onChangeCarrier}>
                <Select.Option key="anime">anime</Select.Option>
                <Select.Option key="book">book</Select.Option>
                <Select.Option key="music">music</Select.Option>
                <Select.Option key="game">game</Select.Option>
                <Select.Option key="3d">non-anime</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="category"
              rules={[{ validator: this.passCheck, }]}
              label="Category:"
              extra={this.extra('category')}
              validateStatus={this.main.errors.category ? "error" : ""}
              help={this.main.errors.category}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                {categories}
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout}
              name="name"
              rules={[{ whitespace: true }]}
              label="Name:"
              extra={this.extra('name')}
              validateStatus={this.main.errors.name ? "error" : ""}
              help={this.main.errors.name}>
              <Input onBlur={this.onBlurNameInput} />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Similar:">
              <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header="Search index by name:" key="1">
                  {objs}
                  {!objs &&
                    <p>Loading ...</p>
                  }
                </Collapse.Panel>
              </Collapse>
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Alias:">
              <Form.Item
                noStyle
                name="alias"
                rules={[{ whitespace: true }]}
                extra={this.extra('alias')}
                validateStatus={this.main.errors.alias ? "error" : ""}
                help={this.main.errors.alias}>
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
              </Form.Item>
              <div style={{ marginTop: 8 }}>
                {!this.state.textInputModeAlias &&
                  <Button type="dashed" size="small" onClick={this.onClickTextInputAlias}>Text input</Button>
                }
                {this.state.textInputModeAlias &&
                  <span>
                    <Input value={this.state.textInputAlias} onChange={this.onTextInputChangeAlias} style={{
                      width: 202,
                    }} />
                    <Button type="dashed" onClick={this.onClickTextInputConvertAlias} style={{ marginLeft: 12 }}>Convert</Button>
                  </span>
                }
              </div>
            </Form.Item>

            <Row gutter={24}>
              {countEPView}

              {this.state.carrier === 'book' &&
                <Col span={6}>
                  <Form.Item {...formItemLayoutInline} label='Volume amount:'
                    name="count_volume"
                    rules={[{ whitespace: true, type: 'number', }]}
                    validateStatus={this.main.errors.count_volume ? "error" : ""}
                    help={this.main.errors.count_volume}>
                    <InputNumber />
                  </Form.Item>
                </Col>
              }

              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                  label="Released at:">
                  <Form.Item
                    noStyle
                    name="released_at"
                    rules={[{ validator: this.passCheck, }]}
                    extra={this.extra('released_at')}
                    validateStatus={this.main.errors.released_at ? "error" : ""}
                    help={this.main.errors.released_at}>
                    <DatePicker />
                  </Form.Item>
                  {!this.state.textInputMode &&
                    <Button type="dashed" size="small" onClick={this.onClickTextInput} style={{ marginLeft: 12 }}>Text input</Button>
                  }
                  {this.state.textInputMode &&
                    <span>
                      <Input value={this.state.textInput} onChange={this.onTextInputChange} style={{
                        width: 202,
                        marginLeft: 12
                      }} />
                      <Button type="dashed" onClick={this.onClickTextInputConvert} style={{ marginLeft: 12 }}>Convert</Button>
                    </span>
                  }
                </Form.Item>
              </Col>
            </Row>

            <Form.Item {...formItemLayout}
              name="bio_long"
              rules={[{ whitespace: true }]}
              label="Bio:"
              extra={this.extra('bio_long')}
              validateStatus={this.main.errors.bio_long ? "error" : ""}
              help={this.main.errors.bio_long}>
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
            </Form.Item>

            <Row gutter={24}>
              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  label="BGM ID:">
                  <Form.Item
                    noStyle
                    name="bgm_id"
                    rules={[{ whitespace: true, type: 'number', }]}
                    extra={this.extra('bgm_id')}
                    validateStatus={this.main.errors.bgm_id ? "error" : ""}
                    help={this.main.errors.bgm_id}>
                    <InputNumber />
                  </Form.Item>
                  <LinkOutlined style={{ marginLeft: '0.5rem' }} onClick={this.onClickBGMLink} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="bgm_score"
                  rules={[{ whitespace: true, type: 'number', }]}
                  label="BGM score:"
                  extra={this.extra('bgm_score')}
                  validateStatus={this.main.errors.bgm_score ? "error" : ""}
                  help={this.main.errors.bgm_score}>
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  label="Douban ID:">
                  <Form.Item
                    noStyle
                    name="douban_id"
                    rules={[{ whitespace: true, type: 'number', }]}
                    extra={this.extra('douban_id')}
                    validateStatus={this.main.errors.douban_id ? "error" : ""}
                    help={this.main.errors.douban_id}>
                    <InputNumber />
                  </Form.Item>
                  <LinkOutlined style={{ marginLeft: '0.5rem' }} onClick={this.onClickDoubanLink} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="douban_score"
                  rules={[{ whitespace: true, type: 'number', }]}
                  label="Douban score:"
                  extra={this.extra('douban_score')}
                  validateStatus={this.main.errors.douban_score ? "error" : ""}
                  help={this.main.errors.douban_score}>
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="imdb_id"
                  rules={[{ whitespace: true }]}
                  label="IMDB ID:"
                  extra={this.extra('imdb_id')}
                  validateStatus={this.main.errors.imdb_id ? "error" : ""}
                  help={this.main.errors.imdb_id}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="mal_id"
                  rules={[{ whitespace: true, type: 'number', }]}
                  label="MyAnimeList ID:"
                  extra={this.extra('mal_id')}
                  validateStatus={this.main.errors.mal_id ? "error" : ""}
                  help={this.main.errors.mal_id}>
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={6}>
                 <Form.Item {...formItemLayoutInline}
                  name="collection_id"
                  rules={[{ validator: this.passCheck, }]}
                  label="Collection ID:"
                  extra={this.extra('collection_id')}
                  validateStatus={this.main.errors.collection_id ? "error" : ""}
                  help={this.main.errors.collection_id}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline} label="Relation:"
                  name="collection_relation"
                  rules={[{ validator: this.passCheck, }]}
                  extra={this.extra('collection_relation')}
                  validateStatus={this.main.errors.collection_relation ? "error" : ""}
                  help={this.main.errors.collection_relation}>
                  <Select style={{ width: '50%' }} placeholder="Please select">
                    <Select.Option key="none">Related</Select.Option>
                    <Select.Option key="same_worldview">Same worldview</Select.Option>
                    <Select.Option key="derivative">Derivative</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="isbn"
                  rules={[{ whitespace: true }]}
                  label="ISBN:"
                  extra={this.extra('isbn')}
                  validateStatus={this.main.errors.isbn ? "error" : ""}
                  help={this.main.errors.isbn}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="anidb_id"
                  rules={[{ whitespace: true, type: 'number', }]}
                  label="Anidb ID:"
                  extra={this.extra('anidb_id')}
                  validateStatus={this.main.errors.anidb_id ? "error" : ""}
                  help={this.main.errors.anidb_id}>
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  label="Steam ID:">
                  <Form.Item
                    noStyle
                    name="steam_id"
                    rules={[{ whitespace: true, type: 'number', }]}
                    extra={this.extra('steam_id')}
                    validateStatus={this.main.errors.steam_id ? "error" : ""}
                    help={this.main.errors.steam_id}>
                    <InputNumber />
                  </Form.Item>
                  <LinkOutlined style={{ marginLeft: '0.5rem' }} onClick={this.onClickSteamLink} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item {...formItemLayoutInline}
                  name="steam_score"
                  rules={[{ whitespace: true, type: 'number', }]}
                  label="Steam score:"
                  extra={this.extra('steam_score')}
                  validateStatus={this.main.errors.steam_score ? "error" : ""}
                  help={this.main.errors.steam_score}>
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item {...formItemLayout}
              name="cover"
              rules={[{ whitespace: true }]}
              label="Cover:"
              extra={this.extra('cover')}
              validateStatus={this.main.errors.cover ? "error" : ""}
              help={this.main.errors.cover}>
              <Input />
            </Form.Item>

            <Row gutter={24}>
              <Col span={6}>
                <Form.Item {...formItemLayoutInline} label="Status:"
                  name="status"
                  rules={[{ validator: this.passCheck, }]}
                  extra={this.extra('status')}
                  validateStatus={this.main.errors.status ? "error" : ""}
                  help={this.main.errors.status}>
                  <Select style={{ width: '50%' }} placeholder="Please select">
                    <Select.Option key="none">None</Select.Option>
                    <Select.Option key="nsfw">NSFW</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <PopupInput callback={(sid) => this.loadSourceData(sid, false)} />
              <Divider type="vertical" />
              <Button type="primary" htmlType="submit" loading={this.main.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
