import React from 'react'
import { Link } from 'react-router-dom'
import Simple from '../../../components/shared/ant/table/simple'
import LengthLimiter from '../../../components/shared/format/length_limiter'
import ExternalLinks from '../../../components/shared/format/external_links'
import Date from '../../../components/shared/format/date'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='obj'
        slug='objs'
        store={this.props.history.store}
        columns={'carrier category name count_ep count_volume count_fav count_comment source status released_at imdb_id bgm_id bgm_score douban_id douban_score mal_id'}
        options={{
          id: value => <Link to={`/objs/${value}`}>{value}</Link>,
          name: value => <LengthLimiter text={value} length={24}></LengthLimiter>,
          released_at: value => <Date text={value}></Date>,
          source: value => <span>{value === 'deprecated' ? 'D' : '-'}</span>,
          imdb_id: value => <ExternalLinks list={['https://www.imdb.com/title/' + value]} show={!!value}></ExternalLinks>,
          bgm_id: value => <ExternalLinks list={['https://bgm.tv/subject/' + value]} show={!!value}></ExternalLinks>,
          douban_id: value => <ExternalLinks list={['https://movie.douban.com/subject/' + value]} show={!!value}></ExternalLinks>,
          mal_id: value => <ExternalLinks list={['https://myanimelist.net/anime/' + value]} show={!!value}></ExternalLinks>,
          create: null,
          edit: null,
          filters: ['id', 'name', 'bgm_id', 'douban_id', 'source'],
          plural: 'objs',
          categories: {
            currentCategory: 'bgm douban manual',
          },
          size: 'small',
        }}
      />
    )
  }
}

export default Index
