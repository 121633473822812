import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'antd'
import util from '../../../utils'
import parseMarkdown from '../../../utils/markdown'
import { get } from '../../../utils/request'
import styles from './edit.module.css'

class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      preview: '',
    }

    if (this.props.match.params.id) {
      get(`/docs/${this.props.match.params.id}`).then((data) => {
        this.setState({
          title: data.data.title,
          preview: parseMarkdown(data.data.content),
        })
      })
    }
  }

  render() {
    util.setTitle(this.state.title)

    window.document.body.style.background = '#fbf0d9'

    return (
      <Row>
        <Col span={24}>
          <Col span={24}>
            <div className={'markdown ' + styles.innerInner} style={{ padding: 24 }} dangerouslySetInnerHTML={{__html: parseMarkdown(this.state.preview)}}></div>
          </Col>
        </Col>
      </Row>
    )
  }
}

export default observer(Preview)
