import L from 'leaflet'

const makeSport = (filename) => L.icon({
  iconUrl: `https://static.3facfe.com/alpha/works/sports33/${filename}`,
  iconSize: [26, 26],
  iconAnchor: [13, 13],
  popupAnchor: [0, -13]
})

export const availableIcons = [
  'none',
  'fav',
  'cancelled',
  'food',
  'hotel',
  'transport',
  'fitness',
  'casino',
]

export default {
  leaf: L.icon({
    iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
    iconSize: [38, 95],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76]
  }),
  none: L.icon({
    iconUrl: 'https://static.3facfe.com/alpha/works/loc_grey_1x.png',
    iconSize: [21, 21],
    iconAnchor: [10.5, 10.5],
    popupAnchor: [0, -14]
  }),
  gooH: L.icon({
    iconUrl: 'https://static.3facfe.com/alpha/works/loc_green_1x.png',
    iconSize: [21, 21],
    iconAnchor: [10.5, 10.5],
    popupAnchor: [0, -14]
  }),
  // https://www.svgrepo.com/collection/sports-33/
  fav: makeSport('crosshair-focus-svgrepo-com.svg'),
  food: makeSport('sport-chess-piece-svgrepo-com.svg'),
  hotel: makeSport('weight-scale-svgrepo-com.svg'),
  transport: makeSport('sailing-sail-svgrepo-com.svg'),
  fitness: makeSport('fitness-svgrepo-com.svg'),
  casino: makeSport('casino-gambling-svgrepo-com.svg'),
  cancelled: L.divIcon({
    className: 'leaflet-icon-cancelled',
    iconSize: [26, 26],
    iconAnchor: [13, 13],
    popupAnchor: [0, -13]
  }),
}
