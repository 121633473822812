import React from 'react'
import {
  EyeInvisibleOutlined,
  EyeInvisibleFilled,
  HeartOutlined,
  HeartFilled,
} from '@ant-design/icons'
import Simple from '../../../components/shared/ant/table/simple'
import ExternalLinks from '../../../components/shared/format/external_links'
import Status from '../../../components/shared/format/status'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='tweet image file'
        store={this.props.history.store}
        columns='tweet_image_id source_url key content_type size md5 fingerprint mirror status created_at control'
        control={{
          status: {
            like: {
              outlined: <HeartOutlined />,
              filled: <HeartFilled />,
            },
            hidden: {
              outlined: <EyeInvisibleOutlined />,
              filled: <EyeInvisibleFilled />,
            },
          }
        }}
        options={{
          size: 'small',
          source_url: value => <ExternalLinks list={[value]} prefix=''></ExternalLinks>,
          key: value => <ExternalLinks list={[value]} prefix='https://static.3facfe.com/slayer/'></ExternalLinks>,
          mirror: value => <Status text={value} oranges={['text']} greens={['group_text']}></Status>,
        }}
      />
    )
  }
}

export default Index
