import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const PageBase = types
  .model({
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/pages', payload).then(({ error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#/pages`
      })
    },

    list() {
      self.loading = true
      get('/pages', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    read(title) {
      get('/pages/' + title).then((data) => self.setItemData(title, data))
    },
    setItemData(title, { error, data }) {
      if (error) return window.location.href = `/#/pages/create?title=${title}`
      if (!data) return
      self.item = data
      self.loading = false
    },

    update(title, data, id) {
      self.loading = true
      put(`/pages/${id}`, data).then(({ error }) => {
        if (error) return
        window.location.href = `/#/pages/${title}`
      })
    },

    delete(id) {
      del(`/pages/${id}`).then(() => self.list())
    },
  }))

const Page = types
  .compose(
    BasicObject,
    PageBase,
  )

export default Page
