import React from 'react'
import { Row, Col, Descriptions, message } from 'antd'
import * as L from 'leaflet'
import _ from 'lodash'
import queryString from 'query-string'
import coordtransform from 'coordtransform2'
import MapConfig from '../../../components/shared/thirdparty/map_config'
import styles from './view.module.css'

var icons = {
  goo: L.icon({
    iconUrl: 'https://static.3facfe.com/alpha/works/loc_grey_1x.png',
    iconSize: [21, 21],
    iconAnchor: [10.5, 10.5],
    popupAnchor: [0, -14]
  }),
  gooH: L.icon({
    iconUrl: 'https://static.3facfe.com/alpha/works/loc_green_1x.png',
    iconSize: [21, 21],
    iconAnchor: [10.5, 10.5],
    popupAnchor: [0, -14]
  }),
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      map: null,

      lat: '',
      lon: '',
      name: '',
      link: '',
    }
  }

  componentDidMount = () => {
    var map = L.map('mapid')
    this.setState({
      map: map,
    })

    L.tileLayer(MapConfig.tileURI, MapConfig.tileOption).addTo(map)

    let parsed = queryString.parse(this.props.location.search)
    if (parsed.url) {
      let url = decodeURIComponent(window.atob(parsed.url.replace('|', '=')))
      console.log('url:', url)
      let finder = url.match(/https:\/\/www\.google\.[a-z.]+\/maps\/place\/|https:\/\/www\.google\.com\/search\?safe=active/g)
      if (finder) {
        let res = url.matchAll(/!8m2!3d([0-9.-]+)!4d([0-9.-]+)|!8m2!3d([0-9.-]+)!4d([0-9.-]+)\?|!1m2!1d([0-9.-]+)!2d([0-9.-]+)/g)
        let lat
        let lon
        for (let item of res) {
          lat = item[1]
          lon = item[2]
          if (!lat) {
            lat = item[3]
            lon = item[4]
          }
          if (!lat) {
            lat = item[5]
            lon = item[6]
          }
        }
        /place\/([^/]+)\/|&oq=([^&]+)/g.test(url)
        let name = RegExp.$1.replace(/\+/g, ' ')
        if (!name) {
          name = RegExp.$2.replace(/\+/g, ' ')
        }
        let mark = {
          lat: lat,
          lon: lon,
          name: decodeURIComponent(name),
          link: url,
        }
        this.setState(mark)
        this.saveMark(mark)
      }
      finder = url.match(/https:\/\/(www\.)?amap\.com\/dir\?from/g)
      if (finder) {
        let res = url.matchAll(/([0-9.]+)%2C([0-9.]+)/g)
        let last = null
        for (let item of res) {
          last = item
        }
        let latRaw = last[2]
        let lonRaw = last[1]
        let coo = coordtransform.gcj02towgs84(parseFloat(last[2]), parseFloat(last[1]))
        res = url.matchAll(/name%5D=([^&]+)/g)
        for (let item of res) {
          last = item
        }
        let mark = {
          lat: coo[0],
          lon: coo[1],
          name: decodeURIComponent(last[1]),
          link: url,
          latRaw: latRaw,
          lonRaw: lonRaw,
        }
        this.setState(mark)
        this.saveMark(mark)
      }
    }
  }

  loadData = () => {
    let data = localStorage.getItem('import')
    if (!data) return []
    return JSON.parse(data)
  }

  saveMark = (mark) => {
    let data = this.loadData()
    if (!data) return
    for (let item of data) {
      if (item.lat === mark.lat && item.lon === mark.lon) {
        message.warning('Duplicated data!')
        return
      }
    }
    data.push(mark)
    localStorage.setItem('import', JSON.stringify(data))
  }

  componentDidUpdate = () => {
    this.state.map.setView([this.state.lat, this.state.lon], 14)
    let marker = L.marker([this.state.lat, this.state.lon], {icon: icons.gooH})
    marker.addTo(this.state.map)
    if (this.state.latRaw) {
      let markerRaw = L.marker([this.state.latRaw, this.state.lonRaw], {icon: icons.goo})
      markerRaw.addTo(this.state.map)
    }
  }

  render() {
    return (
      <Row>
        <Col span={24}>
          <Descriptions
            title="Import data"
            bordered
            column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Coordinates">({this.state.lat}, {this.state.lon})</Descriptions.Item>
            <Descriptions.Item label="Name">{this.state.name}</Descriptions.Item>
            <Descriptions.Item label="Url">
              <div style={{ wordBreak: 'break-all' }}>
                {this.state.link}
              </div>
            </Descriptions.Item>
          </Descriptions>
          <div className={styles.map}>
            <div id="mapid" className={styles.mapidsec}></div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Index
