import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Modal, Form } from 'antd'
import moment from 'moment'
import { get } from '../../../utils/request'
import ChartLine from './components/chart_line'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      editMode: this.props.match.path === '/charts/edit/:id',
      points: []
    }

    if (this.state.editMode) {
      this.loadData()
    }
  }

  loadData = async () => {
    let origin = (await get('/charts/' + this.props.match.params.id)).data
    this.formRef.current.setFieldsValue(origin)
  }

  componentDidMount = () => {
    if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
      alert('The File APIs are not fully supported in this browser.')
    }
  }

  handleSubmit = values => {
    if (this.state.editMode) {
      window.store.chart.update(this.props.match.params.id, values)
    } else {
      window.store.chart.create(values)
    }
  }

  onClickConvert = () => {
    let fields = this.formRef.current.getFieldsValue()
    if (!fields.data) return
    let final = {
      min: Infinity,
      max: 0,
      version: 2,
      unit: null,
      data: [],
    }
    for (let line of fields.data.split('\n')) {
      let res = line.match(/"[^"]+";"([^"]+)";([0-9,]+)/)
      if (res) {
        let date = moment(res[1]).unix() * 1000
        if (res[2] !== 'null') {
          let value = parseFloat(res[2].replace(/,/g, ''))
          if (value > final.max) final.max = value
          if (value < final.min) final.min = value
          final.data.push({
            time: date,
            value: value
          })
        }
      }
    }
    this.setState({
      points: final,
      visible: true,
    })
  }

  handleOk = () => {
    this.setState({
      visible: false,
    })
    this.formRef.current.setFieldsValue({
      data: JSON.stringify(this.state.points),
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24} style={{ position: 'relative' }}>
          <Form ref={this.formRef} layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Title:"
              name="title"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.chart.errors.title ? "error" : ""}
              help={window.store.chart.errors.title}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Data:"
              name="data"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.chart.errors.data ? "error" : ""}
              help={window.store.chart.errors.data}>
              <Input.TextArea rows={25} />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button onClick={this.onClickConvert}>Convert</Button>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.chart.loading}>Save</Button>
            </Form.Item>
          </Form>

          <Modal title="Preview"
            visible={this.state.visible}
            width={1024}
            onOk={this.handleOk}
            onCancel={this.handleCancel}>
            <ChartLine data={this.state.points} width={900} height={300} />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
