import React from 'react'
import {
  HeartOutlined,
  HeartFilled,
  PauseCircleFilled,
  PauseCircleOutlined,
} from '@ant-design/icons'
import Simple from '../../../components/shared/ant/table/simple'
import ExternalLinks from '../../../components/shared/format/external_links'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='subject'
        store={this.props.history.store}
        columns={'app_id title uri category status created_at control'}
        control={{
          status: {
            up: {
              outlined: <HeartOutlined />,
              filled: <HeartFilled />,
            },
            down: {
              outlined: <PauseCircleOutlined />,
              filled: <PauseCircleFilled />,
            },
          }
        }}
        options={{
          title: (value, item) => {
            if (item.status === 'up') {
              return (
                <span style={{ background: '#e6f7ff', color: '#1890ff' }}>
                  {value}
                </span>
              )
            }
            if (item.status === 'down') {
              return (
                <span style={{ opacity: 0.2, textDecoration: 'line-through' }}>
                  {value}
                </span>
              )
            }
            return value
          },
          uri: value => <ExternalLinks list={[value]} prefix=''></ExternalLinks>,
          create: null,
          size: 'small',
        }}
      />
    )
  }
}

export default Index
