import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Select, Form } from 'antd'

class Create extends React.Component {
  formRef = React.createRef()

  handleSubmit = values => {
    window.store.snippet.create(values)
  }

  componentDidMount = () => {
    if (window.debug.name === 'debug') this.formRef.current.setFieldsValue({
      content: Math.random().toString(36).substring(20),
    })
    this.formRef.current.setFieldsValue({
      status: 'private',
    })
  }

  passCheck = (rule, value, callback) => {
    callback()
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Title:"
              name="title"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.snippet.errors.title ? "error" : ""}
              help={window.store.snippet.errors.title}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Category:"
              name="category"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.snippet.errors.category ? "error" : ""}
              help={window.store.snippet.errors.category}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key="bash">Bash</Select.Option>
                <Select.Option key="markdown">Markdown</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout} label="Filename:"
              name="filename"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.snippet.errors.filename ? "error" : ""}
              help={window.store.snippet.errors.filename}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Status:"
              name="status"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.snippet.errors.status ? "error" : ""}
              help={window.store.snippet.errors.status}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key="private">Private</Select.Option>
                <Select.Option key="public">Public</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout} label="Content:"
              name="content"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.snippet.errors.content ? "error" : ""}
              help={window.store.snippet.errors.content}>
              <Input.TextArea autoSize={{ minRows: 8, maxRows: 24 }} />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
