import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { CloseCircleOutlined, FileOutlined } from '@ant-design/icons'
import { Row, Col, Button, Switch, Popconfirm, Form, Input, PageHeader } from 'antd'
import util from '../../../utils'
import parseMarkdown from '../../../utils/markdown'
import { post, get, put, del } from '../../../utils/request'
import Spin from '../../../components/shared/form/spin'
import styles from './edit.module.css'

window.process = {
  cwd: () => ''
}

class Edit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      showPreview: true,
      showEditor: true,
      editMode: this.props.match.path === '/docs/edit/:id',

      title: 'Untitled',
      content: '# Untitled\n\nThis is a paragraph\n',
      updated_at: null,
      preview: '',
    }

    if (this.props.match.params.id) {
      this.loadData()
    }
  }

  loadData = () => {
    get(`/docs/${this.props.match.params.id}`).then((data) => {
      this.setState({
        title: data.data.title,
        content: data.data.content,
        updated_at: data.data.updated_at,
        preview: parseMarkdown(data.data.content),
      })
    })
  }

  handleRemove = () => {
    window.store.doc.delete(this.props.match.params.id)
  }

  handleChange = ({ target }) => {
    let match = target.value.match(/# (.+)/)
    this.setState({
      title: match ? match[1] : 'Untitled',
      content: target.value,
      preview: parseMarkdown(target.value),
    })
  }

  handleSubmit = () => {
    if (this.props.match.params.id) {
      put(`/docs/${this.props.match.params.id}`, {
        title: this.state.title,
        content: this.state.content,
      }).then(() => this.loadData())
    } else {
      post(`/docs`, {
        title: this.state.title,
        content: this.state.content,
      }).then(({ data }) => window.location.href = `/#/docs/edit/${data.id}`)
    }
  }

  onSwitchPreviewChange = (checked) => {
    this.setState({ showPreview: checked })
  }

  onSwitchEditorChange = (checked) => {
    this.setState({ showEditor: checked })
  }

  render() {
    util.setTitle(window.store.doc.title)

    return (
      <Row className="flex">
        <Col span={24}>
          <div className={styles.inner + ' flex'}>
            <div className="innerTopBar" style={{ position: 'relative' }}>
              <div className={styles.title}>
                <PageHeader
                  className="site-page-header"
                  onBack={() => window.history.back()}
                  title={this.state.title}
                  subTitle={this.state.updated_at} />
              </div>
              <div className={styles.innerTopBarRight}>
                <Form layout="inline" onFinish={this.handleSubmit}>
                  <Form.Item label="Delete:">
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={this.handleRemove}>
                      <CloseCircleOutlined />
                    </Popconfirm>
                  </Form.Item>
                  <Form.Item label="Preview:">
                    <Link to={`/docs/${this.props.match.params.id}/preview`} target='_blank' rel="noopener noreferrer">
                      <FileOutlined />
                    </Link>
                  </Form.Item>
                  <Form.Item label="Show preview:">
                    <Switch defaultChecked checked={this.state.showPreview} onChange={this.onSwitchPreviewChange} />
                  </Form.Item>
                  <Form.Item label="Show editor:">
                    <Switch defaultChecked checked={this.state.showEditor} onChange={this.onSwitchEditorChange} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Save</Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className={styles.innerInner + ' flex'}>
              {this.state.showPreview &&
                <Col span={this.state.showEditor ? 12 : 24}>
                  <div className={styles.fullHeight + ' ' + styles.code + ' markdown'} dangerouslySetInnerHTML={{__html: parseMarkdown(this.state.preview)}}>
                  </div>
                </Col>
              }
              {this.state.showEditor &&
                <Col span={this.state.showPreview ? 12 : 24} className="flex">
                  <Input.TextArea value={this.state.content} onChange={this.handleChange} className={styles.textarea} />
                  <Spin loading={this.state.loading} />
                </Col>
              }
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default observer(Edit)
