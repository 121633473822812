import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Popconfirm } from 'antd'
import util from '../../../utils'
import Spin from '../../../components/shared/form/spin'
import Enum from '../../../components/specific/enum'
import DateTime from '../../../components/shared/format/datetime'
import EditableSpan from '../../../components/specific/span'
import Todo from './components/todo'

const styles = {
  title: {
    fontSize: '32px',
  },
  fakeLink: {
    color: '#aaa',
    cursor: 'pointer',
    marginLeft: '6px',
  },
}

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.todo.read(this.props.match.params.id)
  }

  handleSave = (payload) => {
    window.store.todo.update(payload.target, payload.value)
  }

  handleClone = () => {
    window.store.todo.clone()
  }

  handleClose = () => {
    window.store.todo.updateStatus({ status: 'closed' })
  }

  render() {
    util.setTitle(window.store.todo.item.title)

    return (
      <Row>
        <Col span={24}>
          <div className="inner">
            {window.store.todo.item.id &&
              <div>
                <div style={styles.title}>
                  <EditableSpan target="title" editModeTarget="editModeTitle" item={window.store.todo.item} handle={this.handleSave} suffix={`#${window.store.todo.item.id}`} mode='modal' />
                </div>
                <div className="desc">
                  <Enum type="hasBackground" text={window.store.todo.item.status} /> ·
                  opened <DateTime type="since" text={window.store.todo.item.created_at} />
                  <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={this.handleClose}>
                    <span style={styles.fakeLink}>
                      (close)
                    </span>
                  </Popconfirm>
                  <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={this.handleClone}>
                    <span style={styles.fakeLink}>
                      (clone)
                    </span>
                  </Popconfirm>
                  <Link to={`/todos/${window.store.todo.item.id}/backup`}>
                    <span style={styles.fakeLink}>
                      (backup)
                    </span>
                  </Link>
                </div>
                <Todo source={window.store.todo.item} level="data" levelCount={0} />
              </div>
            }
          </div>
          <Spin loading={window.store.todo.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(View)
