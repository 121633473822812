import React from 'react'
import {
  HeartOutlined,
  HeartFilled,
  PauseCircleOutlined,
  PauseCircleFilled,
} from '@ant-design/icons'
import Simple from '../../../components/shared/ant/table/simple'
import DateTime from '../../../components/shared/format/datetime'
import LengthLimiter from '../../../components/shared/format/length_limiter'
import HighlightNum from '../../../components/shared/format/highlight_num'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='book'
        store={this.props.history.store}
        columns={'uri title published_at flag size language count_fav count_reply count_image count_rater rating status control'}
        control={{
          status: {
            like: {
              outlined: <HeartOutlined />,
              filled: <HeartFilled />,
            },
            dislike: {
              outlined: <PauseCircleOutlined />,
              filled: <PauseCircleFilled />,
            },
          }
        }}
        options={{
          title: (value, item) => {
            value = value ? <LengthLimiter length={32} text={value}></LengthLimiter> : '-'
            if (item.status === 'up') {
              return (
                <span style={{ background: '#e6f7ff', color: '#1890ff' }}>
                  {value}
                </span>
              )
            }
            if (item.status === 'down') {
              return (
                <span style={{ opacity: 0.2, textDecoration: 'line-through' }}>
                  {value}
                </span>
              )
            }
            return value
          },
          count_fav: value => <HighlightNum min={1024} num={value} />,
          count_image: value => <HighlightNum min={64} num={value} />,
          published_at: value => <DateTime type="sinceThin" text={value} />,
          rating: value => <HighlightNum min={4.5} num={value / 100} />,
          size: 'small',
        }}
      />
    )
  }
}

export default Index
