import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import utils from '../../utils'
import BasicObject from '../basic/base'

const CollectionBase = types
  .model({
    currentViewPage: 1,
    searchedSubjects: types.optional(types.frozen(), []),
  })
  .actions(self => ({
    create(data) {
      self.loading = true
      post(`${utils.MT}/admin/collections`, data).then(() => {
        window.location.href = '/#/collections'
      })
    },

    list() {
      self.loading = true
      get(`${utils.MT}/admin/collections`, {
        page: self.currentPage,
        per: self.pagination.pageSize,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    setItemData(data) {
      self.item = data.data
      self.loading = false
      if (self.searchedSubjects.length <= 0) {
        self.currentKeywords = data.data.name
        this.search()
      }
    },
    read(id) {
      get(`${utils.MT}/admin/collections/` + id).then(self.setItemData)
    },

    update(id, data) {
      put(`${utils.MT}/admin/collections/${id}`, data).then(({ error }) => {
        if (!error) window.location.href = `/#/collections/${id}`
      })
    },

    setSearchData(data) {
      self.searchedSubjects = data.data.items
      self.pagination = {
        ...self.pagination,
        current: self.currentViewPage,
        total: data.data.total,
      }
      self.loading = false
    },
    search() {
      get(`${utils.MT}/objs`, {
        keywords: self.currentKeywords,
        page: self.currentViewPage,
      }).then(self.setSearchData)
    },

    batch_insert(ids, cid) {
      self.loading = true
      post(`${utils.MT}/admin/collections/${cid}/batch_insert`, {
        subjects: ids
      }).then(() => {
        self.read(cid)
        self.search()
      })
    },
  }))

export const Collection = types
  .compose(
    BasicObject,
    CollectionBase,
  )

export default Collection
