import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const SubscriptionBase = types
  .model({
    currentGroup: 'valid',
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/subscriptions', payload).then(({ error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#/subscriptions`
      })
    },

    list() {
      self.loading = true
      get('/subscriptions', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        group: self.currentGroup,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    update(id, data, index) {
      self.loading = true
      put(`/subscriptions/${id}`, data).then(({ data, error }) => {
        if (error) return
        if (index !== null && index !== undefined) {
          self.modifyItem(index, {
            priority: data.item.priority,
            status: data.item.status,
            loading: false,
          })
        } else {
          window.location.href = `/#/subscriptions`
        }
      })
    },

    delete(id) {
      del(`/subscriptions/${id}`).then(() => self.list())
    },
  }))

const Subscription = types
  .compose(
    BasicObject,
    SubscriptionBase,
  )

export default Subscription
