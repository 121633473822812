import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Table, Radio } from 'antd'
import DateTime from '../../../components/shared/format/datetime'
import Status from '../../../components/shared/format/status'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.tweetImage.list()
  }

  handleOnChange = (pagination) => {
    window.store.tweetImage.modify({
      currentPage: pagination.current,
    })
    window.store.tweetImage.list()
  }

  onStatusChange = (e) => {
    this.props.dispatch({ type: 'tweet_image/read', payload: {
      page: 1,
      status: e.target.value
    }})
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: value => <Link to={`/tweet_images/${value}`}>{value}</Link>,
      },
      {
        title: 'Tweet url',
        dataIndex: 'tweet_url',
        key: 'tweet_url',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status text={value}></Status>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
    ]

    return (
      <Row>
        <Col span={24}>
          <div style={{ padding: '0 0 9px 18px', textAlign: 'right' }}>
            <Radio.Group defaultValue='' size='middle' onChange={this.onStatusChange}>
              <Radio.Button value=''>All</Radio.Button>
              <Radio.Button value='protected_tweet'>Protected</Radio.Button>
              <Radio.Button value='deleted_tweet'>No tweet</Radio.Button>
              <Radio.Button value='image_not_found'>No image</Radio.Button>
              <Radio.Button value='none'>None</Radio.Button>
            </Radio.Group>
          </div>
          <Table
            rowClassName={(_, index) =>
              index % 2 === 1 ? 'dark-row' : 'light-row'
            }
            bordered
            size="small"
            columns={columns}
            rowKey="id"
            dataSource={window.store.tweetImage.items}
            pagination={window.store.tweetImage.pagination}
            loading={window.store.tweetImage.loading}
            onChange={this.handleOnChange}
          />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
