import React from 'react'
import PropTypes from 'prop-types'

const Length = (props) => {
  let style = {
    color: props.current <= props.max ? '' : 'red',
  }

  return (
    <span style={style}>
      {props.current} / {props.max}
    </span>
  )
}

Length.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default Length
