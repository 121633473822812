import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { BookOutlined, EditOutlined } from '@ant-design/icons'
import { Tree, Modal, Input, List } from 'antd'

const flex = {
  display: 'flex',
}
const flex1 = {
  flex: 1,
}
const folders = {
  flex: 1,
  maxWidth: 200,
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editMode: false,
      content: '',
      selectedKeys: [],
    }
    this.editField = React.createRef()

    window.store.folder.list()
  }

  onRightClick = ({_, node}) => {
  }

  onSelect = (selectedKeys, _) => {
    if (selectedKeys.length <= 0) return
    if (selectedKeys.indexOf('create_root_folder') >= 0) {
      this.setState({ editMode: true })
      return
    }
    this.setState({ selectedKeys: selectedKeys })
    this.props.history.push(`/folders?fid=${selectedKeys[0]}`)
    window.store.folder.read(selectedKeys[0])
  }

  handleOk = (e) => {  // for modal
    this.setState({ editMode: false })

    window.store.folder.create({
      title: this.state.content,
      parent_id: null,
    })
  }

  handleCancel = (e) => {  // for modal
    this.setState({ editMode: false })
  }

  handleChangeModal = (e) => {  // for modal
    this.setState({ content: e.target.value })
  }

  componentDidUpdate = () => {
    if (this.state.selectedKeys.length <= 0 && window.store.folder.items.length > 0) {
      if (window.store.folder.items.length > 1) {
        const fid = parseInt(new URLSearchParams(this.props.location.search).get('fid')) || window.store.folder.items[0].key
        this.setState({ selectedKeys: [fid] })
        window.store.folder.read(fid)
      } else if (!this.state.editMode) {
        this.setState({ editMode: true })
      }
    }
  }

  render() {
    return (
      <div style={flex}>
        <div style={folders}>
          <Modal title="Create Folder" visible={this.state.editMode} onOk={this.handleOk} onCancel={this.handleCancel} okText="Submit" cancelText="Cancel">
            <Input placeholder="Please input" value={this.state.content} onChange={this.handleChangeModal} onPressEnter={this.handleOk} ref={this.editField} />
          </Modal>
          <Tree onRightClick={this.onRightClick} treeData={window.store.folder.items} selectable={true} onSelect={this.onSelect} selectedKeys={this.state.selectedKeys} />
          <div style={{
            width: '56px',
            lineBreak: 'anywhere',
            margin: '12px 0 0 28px',
          }}>
            {window.store.folder.item.bookmarks?.map((item) => <a key={item.id} href={item.url} target="_blank" rel="noopener noreferrer">.</a>)}
          </div>
        </div>
        <div style={flex1}>
          <List
            itemLayout="horizontal"
            dataSource={window.store.folder.item.bookmarks}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Link to={`bookmarks/edit/${item.id}`}><BookOutlined /></Link>}
                  title={<span>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                  </span>}
                  description={item.url}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    )
  }
}

export default observer(Index)
