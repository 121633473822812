import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Tag, Divider, Progress } from 'antd'
import styles from './workspace.module.css'

class Workspace extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemID: this.props.itemID,
    }

    this.main = window.store.translationText
    this.mainUpper = window.store.translation
    this.main.read(this.props.itemID)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.itemID !== prevState.itemID) {
      window.store.translationText.read(nextProps.itemID)
      return { itemID: nextProps.itemID }
    }
    return null
  }

  clear = () => {
    this.main.modify({
      currentTranslation: '',
    })
  }
  reset = () => {
    this.main.modify({
      currentTranslation: this.main.item.result,
    })
  }
  copyRaw = () => {
    this.main.modify({
      currentTranslation: this.main.item.raw,
    })
  }
  copyMachine = () => {
    this.main.modify({
      currentTranslation: this.main.item.references?.machine?.google || '',
    })
  }
  save = () => {
    this.mainUpper.modifyItemsOfItem(this.props.index, {
      result: this.main.currentTranslation,
    })
    this.main.update(this.props.itemID, {
      result: this.main.currentTranslation,
    })
  }

  startJob = () => {
    this.main.startJob()
  }

  onInputChange = (event) => {
    this.main.modify({
      currentTranslation: event.target.value,
    })
  }

  isJapanese = (text) => {
    return /[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu.test(this.main.item.raw)
  }

  onInputChangeComment = (event) => {
    this.main.modify({
      currentComment: event.target.value,
    })
  }
  onClickAddComment = () => {
    this.main.createComment()
  }

  render() {
    let romaji = []
    let furigana = []
    let histories = []
    let comments = []

    if (this.main.item.references) {
      romaji = this.main.item.references.romaji?.map((item, index) =>
        <ruby key={index}>{item.text}
          <rt>{item.rt}</rt>
        </ruby>
      )
      furigana = this.main.item.references.furigana?.map((item, index) =>
        <ruby key={index}>{item.text}
          <rt className="furigana">{item.rt}</rt>
        </ruby>
      )
      histories = this.main.item.translation_text_histories.map((item, index) =>
        <div className={styles.historyItem} key={index}>
          <Tag color="green">{item.user.nickname} ({item.id})</Tag>{item.result}
        </div>
      )
      comments = this.main.item.translation_text_comments.map((item, index) =>
        <div key={item.id} className={styles.commentBody}>
          <Tag color="green">{item.user.nickname}</Tag>{item.comment}
        </div>
      )
    }

    return (
      <Row>
        <Col span={19}>
          <div className={styles.wrapper}>
            <div>
              <Divider orientation="left" plain>Source{this.isJapanese() ? ' (JP)' : ''}</Divider>
              <div className={styles.text}>
                {this.main.item.raw}
              </div>
            </div>
            <div>
              <Divider orientation="left" plain>Rōmaji</Divider>
              <div className={styles.text}>
                {romaji}
              </div>
            </div>
            <div>
              <Divider orientation="left" plain>Furigana</Divider>
              <div className={styles.text}>
                {furigana}
              </div>
            </div>
            <div>
              <Divider orientation="left" plain>Machine</Divider>
              <div className={styles.text}>
                {this.main.item.references?.machine?.google}
              </div>
            </div>
            <div>
              <Divider orientation="left" plain>Translation</Divider>
              <div>
                <Input.TextArea rows={4} value={this.main.currentTranslation} onChange={this.onInputChange} />
              </div>
              <div className={styles.control}>
                <Button onClick={this.clear}>Clear</Button>
                <Divider type="vertical" />
                <Button onClick={this.reset}>Reset</Button>
                <Divider type="vertical" />
                <Button onClick={this.startJob}>Start Job</Button>
                {this.main.jobStatus &&
                  <span style={{ margin: '0 24px' }}>
                    <Progress percent={this.main.jobProgress} steps={10} size="small" strokeColor="#52c41a" />
                  </span>
                }
                <Divider type="vertical" />
                <Button onClick={this.copyRaw}>Copy Raw</Button>
                <Divider type="vertical" />
                <Button onClick={this.copyMachine}>Copy Machine</Button>
                <Divider type="vertical" />
                <Button onClick={this.save}>Save</Button>
              </div>
            </div>
            <div>
              <Divider orientation="left" plain>Recent Changes</Divider>
              {histories}
            </div>
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.comment}>
            <Divider orientation="left" plain>Comments
            </Divider>
            <div className={styles.commentInput}>
              <Input.TextArea rows={4} value={this.main.currentComment} onChange={this.onInputChangeComment} />
              <div style={{ marginTop: 12 }}>
                <Button type="dashed" size="small" onClick={this.onClickAddComment}>Submit</Button>
              </div>
            </div>
            <div className={styles.comments}>
              {comments}
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

Workspace.propTypes = {
  store: PropTypes.object.isRequired,
  itemID: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default observer(Workspace)
