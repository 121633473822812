import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'

class ButtonGridHeader extends React.Component {
  onClick = (e) => {
    this.props.click(e)
  }

  render() {
    let items = []
    for (let item of this.props.value) {
      items.push(
        <Radio.Button value={item.value} key={Math.random()}>{item.text}</Radio.Button>
      )
    }

    return (
      <div style={{ padding: '0 0 9px 8px', textAlign: 'right', display: 'inline-block' }}>
        <Radio.Group style={{ marginLeft: 8 }} defaultValue={this.props.default ? this.props.default : this.props.value[0].value} size='middle' onChange={this.onClick}>
          {items}
        </Radio.Group>
      </div>
    )
  }
}

ButtonGridHeader.propTypes = {
  value: PropTypes.array,
  click: PropTypes.func,
  default: PropTypes.string,
}

export default ButtonGridHeader
