import React from 'react'
import Status from '../../../components/shared/format/status'
import Simple from '../../../components/shared/ant/table/simple'
import { status } from '../warehouse_items/index.jsx'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='warehouse changelog'
        store={this.props.history.store}
        columns='user_id status name amount before after created_at'
        control={{
        }}
        options={{
          size: 'small',
          status: (_, item) => <Status type="status" text={item.warehouse_item.status} pairs={status}></Status>,
          name: (_, item) => <span>{item.warehouse_item.name}</span>,
          amount: (_, item) => <span>{item.warehouse_item.amount}</span>,
          before: value => <span>{JSON.stringify(value)}</span>,
          after: value => <span>{JSON.stringify(value)}</span>,
        }}
      />
    )
  }
}

export default Index
