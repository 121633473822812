import React from 'react'
import Simple from '../../../components/shared/ant/table/simple'
import ExternalLinks from '../../../components/shared/format/external_links'
import LengthLimiter from '../../../components/shared/format/length_limiter'
import utils from '../../../utils'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='upload'
        store={this.props.history.store}
        columns='category ref source_uri status created_at control'
        control={{
        }}
        options={{
          size: 'small',
          create: null,
          deletion: null,
          ref: value => (
            <span>
              <ExternalLinks list={[value]} prefix=''></ExternalLinks>
              <LengthLimiter length={96} text={value} headtail={true}></LengthLimiter>
            </span>
          ),
          source_uri: value => (
            <span>
              <ExternalLinks list={[value]} prefix={utils.bucketUpload}></ExternalLinks>
              {value}
            </span>
          ),
        }}
      />
    )
  }
}

export default Index
