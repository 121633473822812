import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const ChartBase = types
  .model({
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/charts', payload).then(({ error }) => {
        if (self.checkError(error)) return
        window.location.href = '/#/charts'
      })
    },

    list() {
      self.loading = true
      get('/charts', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    read(id) {
      get('/charts/' + id).then(self.setItemData)
    },

    update(id, data) {
      self.loading = true
      put(`/charts/${id}`, data).then(({ data, error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#/charts/${data.id}`
      })
    },
  }))

const Chart = types
  .compose(
    BasicObject,
    ChartBase,
  )

export default Chart
