import React from 'react'
import Simple from '../../../components/shared/ant/form/simple'

class Create extends React.Component {
  render() {
    return (
      <Simple
        keywords='warehouse item'
        slug='warehouse_items'
        id={this.props.match.params.id}
        parent={this.props}
        columns={'name tag tag_sn amount comment category risk status'}
        options={{
          tag_sn: {
            type: 'number',
          },
          amount: {
            type: 'number',
          },
          category: {
            type: 'select',
            values: 'device cable consumable container grocery tool'
          },
          risk: {
            type: 'select',
            values: 'small medium large'
          },
          status: {
            type: 'select',
            values: 'transfer backpack car installed recycle room warehouse pending'
          },
        }}
      />
    )
  }
}

export default Create
