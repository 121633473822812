import React from 'react'
import Simple from '../../../components/shared/ant/form/simple'

class Create extends React.Component {
  render() {
    return (
      <Simple
        keywords='tag'
        slug='tags'
        id={this.props.match.params.id}
        parent={this.props}
        columns={'name display_name'}
        options={{
        }}
      />
    )
  }
}

export default Create
