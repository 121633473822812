import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const SubjectBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/subjects', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    create(data) {
      self.loading = true
      post('/subjects', data).then(() => {
        window.location.href = '/#/subjects'
      })
    },

    update(id, index, data) {
      self.loading = true
      put(`/subjects/${id}`, data).then(() => {
        self.list()
      })
    },
  }))

const Subject = types
  .compose(
    BasicObject,
    SubjectBase,
  )

export default Subject
