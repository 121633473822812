import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  BorderOuterOutlined,
  DownCircleOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  LinkOutlined,
  LogoutOutlined,
  MinusCircleOutlined,
  PauseCircleOutlined,
  PlusCircleOutlined,
  RightCircleOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { Modal, Input } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import EditableSpan from '../../../../components/specific/span'
import TodoMirror from './todo'
import styles from './todo.module.css'

class Todo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      textarea: '',
      textareaResult: '',
      textareaIndex: null,
      markdown: '',
      visible: false,
      visibleResult: false,
    }
  }

  structure () {
    return {
      description: '',
      completed_at: null,
      cancelled: false,
      priority: null,
      children: []
    }
  }

  handleSave = (payload) => {
    payload.value = payload.value.trim()
    window.store.todo.update(payload.target, payload.value)
  }

  handleClickCreate = () => {
    window.store.todo.update('data', [this.structure()])
  }

  handleClickCheck = (i) => {
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    value[i].completed_at = value[i].completed_at ? null : moment()
    if (value[i].priority) value[i].priority = null
    if (value[i].cancelled) value[i].cancelled = false
    window.store.todo.update(this.props.level, value)
  }

  handleClickPlus = (i) => {
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    value.splice(i, 0, this.structure())
    window.store.todo.update(this.props.level, value)
  }

  handleClickPlusBatch = (i) => {
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    for (let j of [1]) {
      let item = this.structure()
      item['description'] = value[i - 1].description
      value.splice(i, 0, item)
    }
    window.store.todo.update(this.props.level, value)
  }

  handleClickPlusTextarea = (i) => {
    this.setState({
      visible: true,
      textareaIndex: i
    })
  }

  onTextareaChange = (e) => {
    this.setState({ textarea: e.target.value })
  }

  handleOk = (e) => {
    this.setState({ visible: false })
    let i = this.state.textareaIndex
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    for (let j of this.state.textarea.split('\n').reverse()) {
      let item = this.structure()
      item['description'] = j
      value.splice(i, 0, item)
    }
    window.store.todo.update(this.props.level, value)
  }

  handleCancel = (e) => {
    this.setState({ visible: false })
  }

  handleOkResult = (e) => {
    this.setState({ visibleResult: false })
  }

  handleCancelResult = (e) => {
    this.setState({ visibleResult: false })
  }

  handleClickMinus = (i) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      onOk: () => {
        let value = _.cloneDeep(_.get(this.props.source, this.props.level))
        _.pullAt(value, i)
        window.store.todo.update(this.props.level, value)
      }
    })
  }

  handleClickPause = (i) => {
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    value[i].cancelled = value[i].cancelled ? false : true
    if (value[i].priority) value[i].priority = null
    if (value[i].completed_at) value[i].completed_at = null
    window.store.todo.update(this.props.level, value)
  }

  handleClickPriority = (i) => {
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    value[i].priority = value[i].priority ? null : 'high'
    if (value[i].completed_at) value[i].completed_at = null
    if (value[i].cancelled) value[i].cancelled = false
    window.store.todo.update(this.props.level, value)
  }

  handleClickRight = (i) => {
    let value = _.cloneDeep(_.get(this.props.source, this.props.level))
    value[i].children.push(this.structure())
    window.store.todo.update(this.props.level, value)
  }

  handleClickMarkdown = (i) => {
    let current = _.get(this.props.source, this.props.level)[i]
    this.setState({ markdown: '' })
    let res = ''
    res += '* 本周\n'
    res += this.handlerChildrenMarkdown(current.children, 2)
    this.setState({
      visibleResult: true,
      textareaResult: res,
    })
  }

  handlerChildrenMarkdown = (arr, indent) => {
    let res = ''
    for (let item of arr) {
      res += ' '.repeat(indent) + '* ' + item.description + '\n'
      if (item.children.length > 0) {
        res += this.handlerChildrenMarkdown(item.children, indent + 2)
      }
    }
    return res
  }

  handleClickMove = (i) => {
    window.store.todo.modify({
      moveMode: true,
      target: {
        level: this.props.level,
        index: i
      }
    })
  }

  handleClickMoveItToNext = (i) => {
    window.store.todo.moveFromPathToPath(this.props.level, i)
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  render() {
    if (this.props.source.data.length <= 0) {
      return (
        <span>
          {!this.props.readOnly &&
            <PlusCircleOutlined onClick={this.handleClickCreate} className={styles.clickable} />
          }
          {this.props.readOnly &&
            <PlusCircleOutlined />
          }
        </span>
      );
    }
    else {
      let data = this.props.readOnly ? _.get(this.props.source, this.props.level) : _.get(window.store.todo.item, this.props.level)
      if (data && data.length > 0) {
        let items = []
        for (const [i, item] of data.entries()) {
          let isLink = !!item.description.match(/^http:|^https:/g)

          let highlight = null
          if (this.props.level + `[${i}]` === window.store.todo.target.level + `[${window.store.todo.target.index}]`) {
            highlight = 'MovedFrom'
          } else if (item.cancelled) {
            highlight = 'Cancelled'
          } else if (isLink && item.completed_at) {
            highlight = 'Done'
          } else if (item.priority) {
            highlight = 'Priority' + this.capitalizeFirstLetter(item.priority)
          }

          let prefixIcon
          let additionalIcon
          if (this.props.readOnly) {
            if (item.completed_at) {
              prefixIcon = <CheckCircleFilled className={item.completed_at ? styles.completed : null} />
            } else {
              prefixIcon = <CheckCircleOutlined className={item.completed_at ? styles.completed : null} />
            }
          } else {
            if (item.completed_at) {
              prefixIcon = <CheckCircleFilled onClick={() => this.handleClickCheck(i)} className={item.completed_at ? styles.completed : styles.clickable} />
            } else {
              prefixIcon = <CheckCircleOutlined onClick={() => this.handleClickCheck(i)} className={item.completed_at ? styles.completed : styles.clickable} />
            }
          }
          if (isLink) {
            additionalIcon = prefixIcon
            prefixIcon = <LinkOutlined />
          }

          items.push(
            <div className={styles.item} key={Math.random()} style={{ marginLeft: this.props.levelCount > 0 ? 18 : 0 }}>
              <span className={styles.plus}>
                {prefixIcon}
              </span>

              {!this.props.readOnly &&
                <span className='hoverable break'>
                  <EditableSpan target={this.props.level + '[' + i + '].description'} item={this.props.source} handle={this.handleSave} highlight={highlight} isLink={isLink} />
                  <span className={`hover_content ${styles.controls}`}>
                    {window.store.todo.moveMode &&
                      <span>
                        <DownCircleOutlined onClick={() => this.handleClickMoveItToNext(i)} />
                      </span>
                    }
                    {!window.store.todo.moveMode &&
                      <span className={styles.icons}>
                        <PlusCircleOutlined onClick={() => this.handleClickPlus(i + 1)} />
                        <MinusCircleOutlined onClick={() => this.handleClickMinus(i)} />
                        <PlusCircleOutlined onClick={() => this.handleClickPlusBatch(i + 1)} />
                        <ExclamationCircleOutlined onClick={() => this.handleClickPriority(i)} />
                        <LogoutOutlined onClick={() => this.handleClickMove(i)} />
                        <PauseCircleOutlined onClick={() => this.handleClickPause(i)} />
                        <RightCircleOutlined onClick={() => this.handleClickRight(i)} />
                        <FileTextOutlined onClick={() => this.handleClickPlusTextarea(i + 1)} />
                        <BorderOuterOutlined onClick={() => this.handleClickMarkdown(i)} />
                        {additionalIcon}
                      </span>
                    }
                  </span>
                </span>
              }
              {this.props.readOnly &&
                <span>
                  {_.get(data, '[' + i + '].description')}
                </span>
              }

              <div className="children">
                <TodoMirror source={this.props.source} level={this.props.level + '[' + i + '].children'} levelCount={this.props.levelCount + 1} readOnly={this.props.readOnly} />
              </div>
            </div>
          )
        }

        return (
          <div>
            {items}
            <Modal title="Todos line by line:"
              visible={this.state.visible}
              width="85%"
              onOk={this.handleOk}
              onCancel={this.handleCancel} >
                <Input.TextArea className={styles.urls} rows={12} value={this.state.textarea} onChange={this.onTextareaChange} />
            </Modal>
            <Modal title="Markdown:"
              visible={this.state.visibleResult}
              width="85%"
              onOk={this.handleOkResult}
              onCancel={this.handleCancelResult} >
                <pre className="break">{this.state.textareaResult}</pre>
            </Modal>
          </div>
        )
      }
      else {
        return <span></span>
      }
    }
  }
}

Todo.propTypes = {
  source: PropTypes.object,
  level: PropTypes.string,
  levelCount: PropTypes.number,
  readOnly: PropTypes.bool,
}

function mapStateToProps(state) {
  return { todo: state.todo }
}

export default observer(Todo)
