import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const MapPlanBase = types
  .model({
    current: types.optional(types.frozen(), []),
  })
  .actions(self => ({
    create(data) {
      self.loading = true
      post('/map_plans', data).then(() => {
        window.location.href = '/#/map_plans/' + data.map_id
      })
    },

    list(map_id) {
      get('/map_plans', {
        map_id: map_id,
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then((data) => self.setData(data))
    },

    setItemData(data) {
      self.item = data.data
      self.current = data.data.meta
      self.loading = false
      window.store.map.read(data.data.map_id)
    },
    read(id) {
      get('/map_plans/' + id).then((data) => self.setItemData(data))
    },

    update(id, data, map_id) {
      self.loading = true
      put(`/map_plans/${id}`, data).then((res) => {
        window.location.href = '/#/map_plans/' + res.data.map_id
      })
    },

    getLatestArriveAt() {
      if (self.current.length <= 0) return 10
      let max = 0
      for (let item of self.current) {
        let end_at = item.arrive_at + item.duration
        if (end_at > max) max = end_at
      }
      return max
    },
    addMark(item) {
      self.current = self.current.concat([{
        mark_id: item.id,
        arrive_at: self.getLatestArriveAt(),
        duration: item.duration ? item.duration : 1,
      }])
      self.updateMeta()
    },
    deleteMark(index) {
      self.current = self.current.filter((_, arrIndex) => {
        return index !== arrIndex;
      })
      self.updateMeta()
    },
    updateMeta() {
      self.loading = true
      put(`/map_plans/${self.item.id}`, {
        meta: self.current,
      }).then(self.hideLoading)
    },
  }))

const MapPlan = types
  .compose(
    BasicObject,
    MapPlanBase,
  )

export default MapPlan
