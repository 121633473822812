import React from 'react'
import PropTypes from 'prop-types'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import FileSize from '../../../../components/shared/format/file_size'
import moment from 'moment'

var colors = [
  'rgb(137, 15, 2)',
  'rgb(88, 20, 12)',
  'rgb(153, 68, 10)',
  'rgb(193, 92, 23)',
  'rgb(150, 115, 2)',
  'rgb(204, 163, 0)',
  'rgb(63, 104, 51)',
  'rgb(47, 87, 94)',
  'rgb(100, 176, 200)',
  'rgb(5, 43, 81)',
  'rgb(10, 80, 161)',
  'rgb(88, 68, 119)',
  'rgb(63, 43, 91)',
  'rgb(81, 23, 73)',
  'rgb(226, 77, 66)',
  'rgb(191, 27, 0)',
  'rgb(239, 132, 60)',
  'rgb(244, 213, 152)',
  'rgb(229, 172, 14)',
  'rgb(154, 196, 138)',
  'rgb(80, 134, 66)',
  'rgb(110, 208, 224)',
  'rgb(101, 197, 219)',
  'rgb(10, 67, 124)',
  'rgb(68, 126, 188)',
  'rgb(97, 77, 147)',
  'rgb(214, 131, 206)',
  'rgb(109, 31, 98)',
  'rgb(234, 100, 96)',
  'rgb(224, 117, 45)',
  'rgb(249, 147, 78)',
  'rgb(252, 234, 202)',
  'rgb(234, 184, 57)',
  'rgb(183, 219, 171)',
  'rgb(98, 158, 81)',
  'rgb(112, 219, 237)',
  'rgb(130, 181, 216)',
  'rgb(31, 120, 193)',
  'rgb(174, 162, 224)',
  'rgb(112, 93, 160)',
  'rgb(229, 168, 226)',
  'rgb(150, 45, 130)',
  'rgb(242, 145, 145)',
  'rgb(252, 226, 222)',
  'rgb(249, 186, 143)',
  'rgb(249, 226, 210)',
  'rgb(242, 201, 109)',
  'rgb(224, 249, 215)',
  'rgb(126, 178, 109)',
  'rgb(207, 250, 255)',
  'rgb(186, 223, 244)',
  'rgb(81, 149, 206)',
  'rgb(222, 218, 247)',
  'rgb(128, 110, 183)',
  'rgb(249, 217, 249)',
  'rgb(186, 67, 169)',
]

class ChartLine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      unit: null,
    }
  }

  formatXAxis = (tickItem) => moment(tickItem).format('MM-DD HH:mm')

  formatYAxis = (tickItem) => {
    if (tickItem > 1000000) {
      return `${Math.round(tickItem / 10000) / 100}m`
    }
    return tickItem
  }

  formatTooltipLabel = (tickItem) => moment(tickItem).format('YYYY-MM-DD HH:mm')

  formatTooltipValue = (value, name, props) => {
    if (this.state.unit === 'bytes') {
      return <FileSize number={value} />
    }
    else if (this.state.unit === 'ms') {
      return `${Math.round(value * 100) / 100} ms`
    }
    else if (this.state.unit === 'percent') {
      return `${Math.round(value * 10000) / 100} %`
    }
    return value
  }

  getObjectLength = (obj) => {
    var size = 0, key
    for (key in obj) {
      size++
    }
    return size
  }

  render() {
    let data = this.props.data
    let min = Infinity
    let max = 0
    let points = []
    if (data.version !== 2) {
      points = []
      for (let item of data[0].data) {
        if (item[1] > max) max = item[1]
        if (item[1] < min) min = item[1]
        points.push({
          time: item[0],
          value: item[1],
        })
      }
      if (this.state.unit !== null) this.setState({ unit: null })
    } else {
      points = data.data
      min = data.min
      max = data.max
      if (this.state.unit !== data.unit) this.setState({ unit: data.unit })
    }

    let lines = []
    let count = 24
    let groups = new Set()
    let first = null
    let legend = this.getObjectLength(points)
    for (let point of points) {
      for (let item in point) {
        if (item !== 'time') {
          groups.add(item)
          if (first === null) first = item
        }
      }
    }
    if (groups.size <= 1) {
      lines.push(
        <Line type="monotone" dataKey={first} stroke="#8884d8" activeDot={{r: 8}} connectNulls={false} strokeWidth={2} dot={false} />
      )
    } else {
      for (let item of groups) {
        lines.push(
          <Line type="monotone" dataKey={item} stroke={colors[count]} activeDot={{r: 8}} connectNulls={false} strokeWidth={2} dot={false} />
        )
        count += 5
        if (count >= colors.length) count = count % colors.length
      }
    }
    return (
      <div style={{ background: '#fff' }}>
        <div style={{ textAlign: 'center', marginTop: 40 }}>
          {this.props.title}
        </div>

        <ResponsiveContainer
          width={this.props.width ? this.props.width : 1200}
          height={this.props.height ? this.props.height : 525}>
          <LineChart
            data={points}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            {/* <CartesianGrid strokeDasharray="3 4 5 2"/> */}
            <XAxis dataKey="time" tickFormatter={this.formatXAxis} />
            <YAxis type="number" domain={[min, max]} tickFormatter={this.formatYAxis} />
            <Tooltip labelFormatter={this.formatTooltipLabel} formatter={this.formatTooltipValue} />
            {legend <= 10 &&
              <Legend />
            }
            {lines}
            {/* <ReferenceLine x="Page C" label="Start" strokeWidth={2} /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

ChartLine.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
}

export default ChartLine
