import { types } from 'mobx-state-tree'
import { message } from 'antd'
import { post, get, put, getExternal } from '../../utils/request'
import BasicObject from '../basic/base'

const TranslationTextBase = types
  .model({
    currentTranslation: '',
    currentComment: '',
    jobStatus: false,
    jobProgress: 0,
  })
  .actions(self => ({
    setItem(data) {
      self.item = data.data.item
      self.currentTranslation = data.data.item.result || ''
      self.loading = false
    },
    read(id) {
      self.currentComment = ''
      get('/translation_texts/' + id).then(self.setItem)
    },

    update(id, data) {
      self.loading = true
      put(`/translation_texts/${id}`, data).then(self.setItem)
    },

    onStartJobResponseLater() {
      self.jobStatus = false
      self.read(self.item.id)
    },
    onStartJobResponse(intv, data) {
      message.success(`Consumed ${data.data.items} items!`);
      clearInterval(intv)
      self.jobProgress = 100
      setTimeout(self.onStartJobResponseLater, 1500)
    },
    onInterval() {
      if (self.jobProgress < 100) {
        self.jobProgress += 1
      }
    },
    startJob() {
      self.jobProgress = 0
      self.jobStatus = true
      let intv = setInterval(self.onInterval, 1000)
      getExternal(`https://eeajldtcjf.execute-api.ap-northeast-1.amazonaws.com/default/translator`).then((data) => self.onStartJobResponse(intv, data))
    },

    createComment() {
      self.loading = true
      post('/translation_text_comments', {
        master_id: self.item.id,
        comment: self.currentComment,
      }).then(() => {
        self.read(self.item.id)
      })
    },
  }))

const TranslationText = types
  .compose(
    BasicObject,
    TranslationTextBase,
  )

export default TranslationText
