import React from 'react'

const StandaloneBox = (props) => {
  return (
    <div style={{
      margin: '50px auto 30px',
      width: '500px',
      border: '1px solid #f1f1f1',
      borderTop: '2px solid #d0d0d0',
      padding: '40px 30px 20px',
      maxWidth: '100%',
      borderRadius: '5px',
      height: 'max-content',
    }}>
      {props.children}
    </div>
  )
}

export default StandaloneBox
