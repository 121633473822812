export default (markdown) => {
  markdown = markdown.replace(/^# (.*)$/gm, '<h1>$1</h1>')
  markdown = markdown.replace(/^## (.*)$/gm, '<h2>$1</h2>')
  markdown = markdown.replace(/^### (.*)$/gm, '<h3>$1</h3>')
  markdown = markdown.replace(/^#### (.*)$/gm, '<h4>$1</h4>')
  markdown = markdown.replace(/^##### (.*)$/gm, '<h5>$1</h5>')
  markdown = markdown.replace(/^###### (.*)$/gm, '<h6>$1</h6>')

  markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
  markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>')

  markdown = markdown.replace(/\[(.*?)\]\((.*?)\)/g, '<a target="_blank" href="$2">$1</a>')
  markdown = markdown.replace(/```\n([\s\S]+?)```/g, '<pre><code>$1</code></pre>');
  return markdown.replace(/^(?!<h|<a)([^\S\r\n]+)$/gm, '<p>$1</p>')
}
