import { types } from 'mobx-state-tree'
import util from '../../utils'

const BasicObject = types
  .model({
    loading: false,
    errors: types.optional(types.model(), {}),
    extra: types.optional(types.model(), {}),
    pagination: types.optional(types
      .model({
        pageSize: types.integer,
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: types.integer,
        current: types.integer,
        total: types.integer,
      }),
      {
        pageSize: util.pageSize || 14,
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 0,
        current: 1,
        total: 0,
      }
    ),

    currentPage: 1,
    currentStatus: '',
    currentKeywords: '',
    currentOrderBy: '',
    currentStartTime: '',
    currentEndTime: '',

    currentKey: '',
    currentURLType: '',
    currentCategory: '',
    currentSource: '',

    items: types.optional(types.frozen(), []),
    item: types.optional(types.frozen(), {}),
  })
  .actions(self => ({
    setData(data) {
      if (!data.data) return
      self.items = data.data.items
      self.pagination = {
        ...self.pagination,
        current: self.currentPage,
        total: data.data.total,
      }
      self.loading = false
    },
    setItemData(data) {
      if (!data.data) return
      self.item = data.data
      self.loading = false
    },

    checkError(error) {
      if (error) {
        for (let item in error.response) {
          self.errors[item] = error.response[item]
        }
      }
      self.loading = false
      return !!error
    },

    modify(dict) {
      for (let item in dict) {
        self[item] = dict[item]
      }
    },
    modifyItem(index, data) {
      for (let index_ in data) {
        self.items[index][index_] = data[index_]
      }
      self.loading = false
      // WTF: above wont't work without this line
    },

    setExtra(data) {
      for (let item in data) {
        self.extra[item] = data[item]
      }
    },

    startLoading() {
      self.loading = true
    },
    hideLoading() {
      self.loading = false
    },

    setPagination(data) {
      self.pagination = {
        defaultCurrent: self.currentPage,
        current: self.currentPage,
        total: data.data.total,
      }
    },
  }))

export default BasicObject
