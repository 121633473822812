import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DateTime from '../../../../components/shared/format/datetime'

class NextBillOrTerminate extends React.Component {
  render() {
    if (this.props.type === 'cycle') {
      let time = moment(this.props.item.start_or_end_at)
      if (this.props.item.category === 'onetime') {
        return <span>-</span>
      } else if (this.props.item.category === 'monthly') {
        time = moment().date(time.date())
        if (moment().isAfter(time)) {
          time = time.add(1, 'months')
        }
      } else if (this.props.item.category === 'yearly') {
        time = moment().month(time.month()).date(time.date())
        if (moment().isAfter(time)) {
          time = time.add(1, 'years')
        }
      }
      return <span>
        <DateTime type='date' text={time.toISOString()}></DateTime>
      </span>
    }
    if (this.props.type === 'onetime') {
      if (this.props.item.category !== 'onetime') {
        return <span>-</span>
      } else {
        return <DateTime type='date' text={this.props.item.start_or_end_at}></DateTime>
      }
    }
  }
}

NextBillOrTerminate.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

export default NextBillOrTerminate
