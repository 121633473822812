import React from 'react'
import PropTypes from 'prop-types'

class FilterBar extends React.Component {
  render() {
    return (
      <div style={{ padding: '0 0 9px 0', display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        <div style={{ marginRight: 'auto' }}>
          {this.props.filterLeft}
        </div>
        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
          {this.props.filterRight}
        </div>
      </div>

    )
  }
}

FilterBar.propTypes = {
  filterLeft: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  filterRight: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
}

export default FilterBar
