import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  PauseCircleFilled,
  PauseCircleOutlined,
} from '@ant-design/icons'
import {
  Row,
  Col,
  Table,
  Form,
  Button,
} from 'antd'
import ClickToView from '../../../components/shared/format/click_to_view'
import Status from '../../../components/shared/format/status'
import DateTime from '../../../components/shared/format/datetime'
import Spin from '../../../components/shared/form/spin'

const styles = {
  clickableIconWrapper: {
    cursor: 'pointer',
    padding: '10px 5px 10px 0',
  },
  icon: {
    marginRight: '4px',
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props)

    window.store.app.list()
  }

  handleOnChange = (pagination) => {
    window.store.app.modify({
      currentPage: pagination.current,
    })
    window.store.app.list()
  }

  onModifyItemStatus = (item, value, index) => {
    window.store.app.update(
      item.id,
      index,
      {
        status: value
      },
    )
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        key: 'desc',
      },
      {
        title: 'Redirect URI',
        dataIndex: 'redirect_uri',
        key: 'redirect_uri',
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        render: value => <ClickToView text={value}></ClickToView>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => {
          if (value === null) return <span>NULL</span>
          return <Status text={value}></Status>
        },
      },
      {
        title: 'Last Called',
        dataIndex: 'last_called_at',
        key: 'last_called_at',
        render: value => <DateTime type="since" text={value}></DateTime>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value}></DateTime>,
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          <span style={{ marginRight: 8 }}>
            <Link to={`apps/edit/${item.id}`}>
              <Button type="dashed" size="small">Edit</Button>
            </Link>
          </span>
          {item.status !== 'disabled' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'disabled', index)}>
              <PauseCircleOutlined style={styles.icon} />
            </span>
          }
          {item.status === 'disabled' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'none', index)}>
              <PauseCircleFilled style={styles.icon} />
            </span>
          }
        </span>,
      },
    ]

    return (
      <Row>
        <Col span={24}>
          <Form>
            <Form.Item>
              <Link to={`apps/create`}>
                <Button type="dashed">New</Button>
              </Link>
            </Form.Item>
          </Form>
          <Table
            rowClassName={(_, index) =>
              index % 2 === 1 ? 'dark-row' : 'light-row'
            }
            bordered
            size="small"
            columns={columns}
            dataSource={window.store.app.items}
            pagination={window.store.app.pagination}
            onChange={this.handleOnChange}
          />
          <Spin loading={window.store.app.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
