// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
// L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
// before: https://api.mapbox.com/v4/mapbox.outdoors/-115.84178,37.21776,12/500x500@2x.png?access_token=
// after: https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/-115.84178,37.21776,12/500x500@2x?access_token=
import L from 'leaflet'

const main = {
  tileURI: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
  tileOption: {
    maxZoom: 18,
    tileSize: 512,
    zoomOffset: -1,
    attribution: '',
    accessToken: 'pk.eyJ1IjoiZXZsb3MiLCJhIjoiY2p2ZXlzaDhrMDczMTN5azZ1cnhic2M0biJ9.CQYPnDVkoVd1TPIPqwUC6w',
    id: 'mapbox/outdoors-v11'
  },
}

export default main

export const baseMaps = {
  satelliteStreets: L.tileLayer(main.tileURI, {
    ...main.tileOption,
    id: 'mapbox/satellite-streets-v11',
  }),
  satellite: L.tileLayer(main.tileURI, {
    ...main.tileOption,
    id: 'mapbox/satellite-v9',
  }),
  dark: L.tileLayer(main.tileURI, {
    ...main.tileOption,
    id: 'mapbox/dark-v10',
  }),
  light: L.tileLayer(main.tileURI, {
    ...main.tileOption,
    id: 'mapbox/light-v10',
  }),
  outdoors: L.tileLayer(main.tileURI, {
    ...main.tileOption,
    id: 'mapbox/outdoors-v11',
  }),
  streets: L.tileLayer(main.tileURI, main.tileOption),
}
