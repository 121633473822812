import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const SnippetBase = types
  .model({
    currentViewPage: 1,
  })
  .actions(self => ({
    create(payload, success) {
      self.loading = true
      post('/snippets', payload).then(({ data, error }) => {
        if (self.checkError(error)) return
        if (payload.parent_id) {
          self.read(payload.parent_id)
          if (success) success()
        } else {
          window.location.href = `/#/snippets/${data.snippet_id}`
        }
      })
    },

    list() {
      self.loading = true
      get('/snippets', {
        page: self.currentPage,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    setItemData(data) {
      if (!data.data) return
      self.item = data.data
      self.itemPagination = {
        ...self.itemPagination,
        current: self.currentViewPage,
        total: data.data.total,
      }
      self.loading = false
    },
    read(id) {
      get('/snippets/' + id, {
        page: self.currentViewPage,
      }).then(self.setItemData)
    },

    update(id, parent_id, data) {
      self.loading = true
      put(`/snippets/${id}`, data).then(() => {
        self.read(parent_id)
      })
    },

    delete(id) {
      del(`/snippets/${id}`).then(() => window.location.href = '/#/')
    },
  }))

const Snippet = types
  .compose(
    BasicObject,
    SnippetBase,
  )

export default Snippet
