import React from 'react'
import Simple from '../../../components/shared/ant/form/simple'

class Create extends React.Component {
  render() {
    return (
      <Simple
        keywords='translation'
        parent={this.props}
        columns={'title raw'}
        options={{
          raw: {
            type: 'textarea',
            rows: 12,
            defaultValue: () => '',
          },
        }}
      />
    )
  }
}

export default Create
