import React from 'react'
import DateTime from '../../../components/shared/format/datetime'
import Status from '../../../components/shared/format/status'
import Bool from '../../../components/shared/format/bool'
import Simple from '../../../components/shared/ant/table/simple'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='user token'
        store={this.props.history.store}
        columns='user user.email user.level client_type client_version ip expired created_at user.login_at'
        control={{
        }}
        options={{
          size: 'small',
          create: null,
          user: value => <span>{value.nickname} ({value.username})</span>,
          'user.email': (value, row) => <span>{row.user.email}</span>,
          'user.level': (value, row) => <Status type="status" text={row.user.level} />,
          expired: value => <Bool value={!value} method="icon" />,
          'user.login_at': (value, row) => <DateTime text={row.user.login_at} type="sinceThin"></DateTime>,
        }}
      />
    )
  }
}

export default Index
