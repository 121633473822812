import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const TweetImageBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/tweet_images', {
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then(self.setData)
    },
  }))

const TweetImage = types
  .compose(
    BasicObject,
    TweetImageBase,
  )

export default TweetImage
