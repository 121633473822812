import React from 'react'
import { Link } from 'react-router-dom'
import Simple from '../../../components/shared/ant/table/simple'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='collection'
        store={this.props.history.store}
        columns='name alias count_fav count_object status created_at control'
        control={{
        }}
        options={{
          size: 'small',
          create: null,
          edit: null,
          id: (value, item) => <Link to={`/collections/${item.id}`}>{value}</Link>,
        }}
      />
    )
  }
}

export default Index
