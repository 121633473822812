import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Form } from 'antd'
import { get } from '../../../utils/request'
import Spin from '../../../components/shared/form/spin'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      editMode: this.props.match.path.indexOf('/edit/') >= 0,
    }

    if (this.state.editMode) {
      this.loadData()
    }
  }

  loadData = async () => {
    let origin = (await get((this.props.match.path.indexOf('/admin/') >= 0 ? '/admin/apps/' : '/apps/') + this.props.match.params.id)).data
    this.formRef.current.setFieldsValue(origin)
  }

  handleSubmit = values => {
    if (window.store.app.loading) return

    if (this.state.editMode) {
      window.store.app.update(this.props.match.params.id, null, values)
    } else {
      window.store.app.create(values)
    }
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            initialValues={{
              name: 'lambda.',
              desc: '',
              redirect_uri: '',
            }}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Name:"
              name="name" rules={[{ whitespace: true }]}
              validateStatus={window.store.app.errors.name ? "error" : ""}
              help={window.store.app.errors.name}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Role:"
              name="role" rules={[{ whitespace: true }]}
              validateStatus={window.store.app.errors.role ? "error" : ""}
              help={window.store.app.errors.role}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Description:"
              name="desc" rules={[{ whitespace: true }]}
              validateStatus={window.store.app.errors.desc ? "error" : ""}
              help={window.store.app.errors.desc}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Redirect uri:"
              name="redirect_uri" rules={[{ whitespace: true }]}
              validateStatus={window.store.app.errors.redirect_uri ? "error" : ""}
              help={window.store.app.errors.redirect_uri}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
          </Form>

          <Spin loading={window.store.app.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
