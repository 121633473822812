var local = window.location.hostname
var isLocal = function() {
  return window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.')
}

export default {
  bucketUpload: 'https://vault2.3facfe.com/uploads/',
  bucket: 'https://vault2.3facfe.com/',

  API: (function() {
    if (isLocal()) {
      return `http://${local}:6548/v1`
    } else if (localStorage.getItem('localMode') === 'on') {
      return 'https://slayerd0.3facfe.com/v1'
    } else {
      return 'https://slayerd.3facfe.com/v1'
    }
  })(),

  MT: (function() {
    if (isLocal()) {
      return `http://${local}:6544/v1`
    } else if (localStorage.getItem('localMode') === 'on') {
      return 'https://trackd0.3facfe.com/v1'
    } else {
      return 'https://trackd.3facfe.com/v1'
    }
  })(),

  surface() {
    if (isLocal()) {
      return `http://${local}:16546`
    } else {
      return 'https://track.3facfe.com'
    }
  },
  getSlayerFrontendURI() {
    if (isLocal()) {
      return 'http://localhost:16548/#/'
    } else {
      return 'https://slayer.3facfe.com/#/'
    }
  },

  endpoint() {
    return 'https://hasu.3facfe.com/v1/graphql'
  },

  setTitle(title=null) {
    if (title) {
      document.title = title + ' | Slayer'
    }
    else {
      document.title = 'Slayer'
    }
  },

  appID() {
    return 3
  },

  siteName() {
    return 'Slayer'
  },

  mirror(key) {
    let uri = 'https://static.3facfe.com/slayer/'
    return uri + key
  },

  cleanCrawledData(text) {
    return text.replace('&lt;', '<').replace('&gt;', '>').replace('&amp;', '&').replace('&quot;', '"')
  },

  isMobile() {
    return localStorage.getItem('mode') === 'mobile'
  },
  isProd() {
    return window.location.host === 'aoi.im'
  },
  isLocal: isLocal,

  switchToMobile() {
    localStorage.setItem('mode', 'mobile')
    window.location.href = '/'
    window.location.reload()
  },
  switchToDesktop() {
    localStorage.setItem('mode', 'desktop')
    window.location.href = '/'
    window.location.reload()
  },

  roundedToDecimals(number, decimals) {
    let multiple = Math.pow(10, decimals)
    return Math.ceil(number * multiple)/multiple
  },

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  },

  capitalize: (string) => string.charAt(0).toUpperCase() + string.slice(1),
}
