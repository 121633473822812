import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Form } from 'antd'

class Create extends React.Component {
  handleSubmit = values => {
    if (window.store.todo.loading) return
    window.store.todo.create(values)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout}
              name="title"
              rules={[{ whitespace: true }]}
              label="Title:"
              validateStatus={window.store.todo.errors.name ? "error" : ""}
              help={window.store.todo.errors.name}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.todo.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
