import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Table, Input, Button, Form, Pagination } from 'antd'
import FilterBar from '../../filter_bar'
import Status from '../../format/status'
import DateTime from '../../format/datetime'

class Complex extends React.Component {
  state = {
    selectedRowKeys: [],
  }

  constructor(props) {
    super(props)
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
    ]
    this.features = {}
    if (this.props.options.features) {
      for (let item of this.props.options.features.split(' ')) {
        this.features[item] = true
      }
    }
    if (this.props.options.id) {
      this.columns[0]['render'] = this.props.options.id
    }
    const statusKeywords = 'category status flag'.split(' ')
    const dateTimeKeywords = 'created_at updated_at released_at'.split(' ')
    const columns = this.props.columns.split(' ')
    for (let item of columns) {
      let res = {
        title: item.upperCamelCase(),
        dataIndex: item,
        key: item,
      }
      if (item in this.props.options) {
        res['render'] = this.props.options[item]
      } else if (statusKeywords.indexOf(item) >= 0) {
        res['render'] = value => <Status text={value}></Status>
      } else if (dateTimeKeywords.indexOf(item) >= 0) {
        res['render'] = value => <DateTime type='date' text={value}></DateTime>
      }
      this.columns.push(res)
    }
  }

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  }

  render() {
    let filterLeft = []
    if (this.props.options.handleSearch) {
      filterLeft.push(
        <Form layout="inline" onFinish={this.props.options.handleSearch} size="small" key="search" initialValues={{ keywords: this.props.options.defaultSearchKeywords }}>
          <Form.Item name="keywords">
            <Input addonBefore={<span>Search</span>} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Filter</Button>
          </Form.Item>
        </Form>
      )
    }

    const hasSelected = this.state.selectedRowKeys.length > 0
    const filterRight = (
      <span>
        <span style={{ marginRight: 8 }}>
          {hasSelected ? `Selected ${this.state.selectedRowKeys.length} items` : ''}
        </span>
        {this.props.options.topRightButtons && this.props.options.topRightButtons.map((item) => {
          return <Button key={item[0]} size="small" onClick={async () => {
            await item[1](this.state.selectedRowKeys)
            this.setState({
              selectedRowKeys: [],
            })
          }}>{item[0]}</Button>
        })}
      </span>
    )

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
          <Table
            rowClassName={(_, index) => index % 2 === 1 ? 'dark-row' : 'light-row'}
            bordered
            size='small'
            columns={this.columns}
            rowKey="id"
            dataSource={this.props.items}
            pagination={this.props.options.pagination}
            rowSelection={{
              selectedRowKeys: this.state.selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            onChange={(pagination, filters, sorter) => {
              this.props.options.handleChange(pagination.current)
              this.setState({
                selectedRowKeys: [],
              })
            }}
          />
        </Col>
      </Row>
    )
  }
}

Complex.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.string,
  /**
    {'title url link category status created_at control'}
   */
  options: PropTypes.object,
  /**
    {
      features: ''
      defaultSearchKeywords: ''
      handleSearch: (values) => { values.keywords }
      handleChange: (page) => {} || () => {}
      pagination: this.main.pagination || null
      topRightButtons: [
        <Button key="add" size="small" onClick={this.handleAdd}>Add</Button>,
      ]
    }
  */
}

export default observer(Complex)
