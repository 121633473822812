import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Alert } from 'antd'
import util from '../../../utils'
import Spin from '../../../components/shared/form/spin'
import ChartLine from './components/chart_line'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showWarning: false,
    }

    window.store.chart.read(this.props.match.params.id)
  }

  render() {
    let width = window.innerWidth - 120
    let height = window.innerHeight - 310
    let data = null
    if (window.store.chart.item && window.store.chart.item.data && !this.state.showWarning) {
      util.setTitle(window.store.chart.item.title)
      try {
        data = JSON.parse(window.store.chart.item.data)
      } catch (e) {
        this.setState({
          showWarning: true,
        })
      }
    }

    return (
      <Row>
        <Col span={24}>
          {this.state.showWarning &&
            <Alert message="Warning"
              description={`JSON parse failed: ${window.store.chart.item.data}`}
              type="warning" />
          }
          {data &&
            <div style={{
              border: '1px solid #00A0E9',
              boxShadow: '0 0 30px rgba(0, 160, 233, 0.5)',
              margin: '21px 24px 0',
              paddingBottom: '24px',
            }}>
              <ChartLine data={data} title={window.store.chart.item.title.toUpperCase()} width={width} height={height} />
            </div>
          }
          <Spin loading={window.store.chart.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(View)
