import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'

const SpinWrapper = (props) => {
  return (
    <div style={{
      position: 'fixed',
      top: '65px',
      right: '15px',
    }}>
      {props.loading &&
        <Spin />
      }
    </div>
  )
}

SpinWrapper.propTypes = {
  loading: PropTypes.bool,
}

export default SpinWrapper
