import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  Row,
  Col,
  Form,
  Button,
  Select,
  Tag,
  List,
} from 'antd'
import queryString from 'query-string'
import { get } from '../../../utils/request'
import LengthLimitedInput from '../../../components/shared/form/length_limited_input'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      editMode: this.props.match.path === '/bookmarks/edit/:id',
      folders: [],
      duplications: [],
    }

    this.loadFolders()

    if (this.state.editMode) {
      this.loadData()
    }
  }

  loadData = async () => {
    let origin = (await get('/bookmarks/' + this.props.match.params.id)).data
    this.formRef.current.setFieldsValue({
      ...origin,
      folder_id: origin.folder_id ? '' + origin.folder_id : '',
    })
  }

  loadFolders = async () => {
    let items = (await get('/folders/')).data.items
    this.setState({ folders: items })
  }

  passCheck = (rule, value, callback) => {
    callback()
  }

  handleSubmit = values => {
    if (window.store.bookmark.loading) return

    localStorage.removeItem('bookmark')
    if (this.state.editMode) {
      window.store.bookmark.update(this.props.match.params.id, null, values)
    } else {
      window.store.bookmark.create(values)
    }
  }

  doPresetCategory = (url) => {
    if (!url) return
    let category = 'none'
    if (url.match(/github\.com/g)) category = 'github'
    else if (url.match(/v2ex\.com/g)) category = 'v2ex'
    else if (url.match(/ruby-china\.org/g)) category = 'ruby-china'
    this.formRef.current.setFieldsValue({
      category: category,
      status: 'none',
    })
  }

  loadDuplicates = async (url) => {
    this.setState({
      duplications: (await get('/bookmarks/', {
        key: 'url',
        keywords: url
      }))?.data?.items,
    })
  }

  componentDidMount = () => {
    let parsed = queryString.parse(this.props.location.search)
    if (parsed.url && parsed.title) {
      let url = decodeURIComponent(window.atob(parsed.url.replace('|', '=')))
      this.doPresetCategory(url)
      if (parsed.title) {
        let data = {
          title: decodeURIComponent(window.atob(parsed.title.replace('|', '='))).slice(0, 128),
          url: url
        }
        localStorage.setItem('bookmark', JSON.stringify(data))
        this.loadDuplicates(url)
        this.formRef.current.setFieldsValue(data)
      }
    } else {
      let savedData = localStorage.getItem('bookmark')
      if (savedData) {
        let data = JSON.parse(savedData)
        this.formRef.current.setFieldsValue(data)
        this.doPresetCategory(data.url)
      }
    }
  }

  onClickRemoveParams = () => {
    let uri = this.formRef.current.getFieldsValue().url
    if (/item\.taobao\.com.+?[^a-zA-Z]+id=(\d+)/.test(uri)) {
      return this.formRef.current.setFieldsValue({
        url: uri.split('?')[0] + `?id=${RegExp.$1}`,
      })
    }
    if (/detail\.tmall\.com.+?[^a-zA-Z]+id=(\d+)/.test(uri)) {
      return this.formRef.current.setFieldsValue({
        url: uri.split('?')[0] + `?id=${RegExp.$1}`,
      })
    }
    this.formRef.current.setFieldsValue({
      url: uri.split('?')[0],
    })
  }

  onClickRemoveOverflow = () => {
    let title = this.formRef.current.getFieldsValue().title
    this.formRef.current.setFieldsValue({
      title: title.slice(0, 128),
    })
  }

  onClickTag = (tag) => {
    this.formRef.current.setFieldsValue({
      status: tag,
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }
    const statuses = ['none', 'pending', 'unread', 'done', 'fav']

    let URLExtra
    if (this.state.duplications.length > 0) {
      URLExtra = <List
        size="small"
        header={<div>Duplications</div>}
        bordered
        dataSource={this.state.duplications}
        renderItem={item => <List.Item><Link to={`/bookmarks/edit/${item.id}`} target='_blank'>{item.title}</Link></List.Item>}
      />
    } else {
      URLExtra = <Tag key='rp' onClick={this.onClickRemoveParams}>Remove Params</Tag>
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            initialValues={{
              category: 'none',
              status: 'none',
              folder_id: '',
            }}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Title:"
              name="title"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.bookmark.errors.title ? "error" : ""}
              extra={
                <div style={{ marginTop: 8 }}>
                  <Tag key='rp' onClick={this.onClickRemoveOverflow}>Remove Overflow</Tag>
                </div>
              }
              help={window.store.bookmark.errors.title}>
              <LengthLimitedInput max={128} style={{ width: 'calc(100% - 32px)' }} />
            </Form.Item>

            <Form.Item {...formItemLayout} label="URI:"
              name="url"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.bookmark.errors.url ? "error" : ""}
              extra={
                <div style={{ marginTop: 8 }}>
                  {URLExtra}
                </div>
              }
              help={window.store.bookmark.errors.url}>
              <LengthLimitedInput max={512} />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Category:"
              name="category"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.bookmark.errors.category ? "error" : ""}
              help={window.store.bookmark.errors.category}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key="none">none</Select.Option>
                <Select.Option key="github">github</Select.Option>
                <Select.Option key="v2ex">v2ex</Select.Option>
                <Select.Option key="ruby-china">ruby-china</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout} label="Status:"
              name="status"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.bookmark.errors.status ? "error" : ""}
              help={window.store.bookmark.errors.status}
              extra={
                <div style={{ marginTop: window.store.issue.errors.title ? -2 : 8 }}>
                  {statuses.map(item => <Tag key={item} onClick={() => this.onClickTag(item)}>{item}</Tag>)}
                </div>
              }>
              <Select style={{ width: '100%' }} placeholder="Please select">
                {statuses.map((item) => <Select.Option key={item}>{item}</Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item {...formItemLayout} label="Folder:"
              name="folder_id"
              rules={[{ validator: this.passCheck, }]}
              validateStatus={window.store.bookmark.errors.folder_id ? "error" : ""}
              help={window.store.bookmark.errors.folder_id}>
              <Select style={{ width: '100%' }} placeholder="Please select">
                <Select.Option key="">none</Select.Option>
                {this.state.folders.map((item) => {
                  return <Select.Option key={'' + item.id}>{item.title}</Select.Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.bookmark.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
