import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from './base'

const uri = '/admin/apps'
const AppBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get(uri, {
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then(self.setData)
    },

    create(data) {
      self.loading = true
      post(uri, data).then(({ error }) => {
        if (self.checkError(error)) return
        window.location.href = `/#${uri}`
      })
    },

    update(id, index, data) {
      self.loading = true
      put(`${uri}/${id}`, data).then(({ data, error }) => {
        if (error) return
        if (index !== null && index !== undefined) {
          self.modifyItem(index, {
            status: data.item.status,
            loading: false,
          })
        } else {
          window.location.href = `/#${uri}`
        }
      })
    },
  }))

const App = types
  .compose(
    BasicObject,
    AppBase,
  )

export default App
