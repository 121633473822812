import React from 'react'

function FloatDesc({ children }) {
  return (
    <div className="float_desc">
      {children}
    </div>
  )
}

export default FloatDesc
