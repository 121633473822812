import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const DocBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/docs', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    delete(id) {
      del(`/docs/${id}`).then(() => window.location.href = '/#/docs/')
    },
  }))

const Doc = types
  .compose(
    BasicObject,
    DocBase,
  )

export default Doc
