import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const MapBase = types
  .model({
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/maps', payload).then(({ error }) => {
        if (self.checkError(error)) return
        window.location.href = '/#/maps'
      })
    },

    list() {
      self.loading = true
      get('/maps', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    read(id) {
      window.store.mapMark.modify({ markers_loaded: false })
      get('/maps/' + id).then(self.setItemData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/maps/${id}`, data).then(({ error }) => {
        if (index === null) {
          return // View
        } else if (index === -1) {
          if (self.checkError(error)) return
          window.location.href = '/#/maps' // Create
        } else if (index !== null && index !== undefined) {
          if (error) return
          self.modifyItem(index, data) // List
        }
      })
    },
  }))

const Map = types
  .compose(
    BasicObject,
    MapBase,
  )

export default Map
