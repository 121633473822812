import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Popconfirm, Pagination } from 'antd'
import util from '../../../utils'
import Spin from '../../../components/shared/form/spin'
import Enum from '../../../components/specific/enum'
import DateTime from '../../../components/shared/format/datetime'
import Todo from './components/todo'
import styles from './backup.module.css'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.todo.read(this.props.match.params.id)
    window.store.todoArchive.list(this.props.match.params.id)
  }

  handleBackup = () => {
    window.store.todoArchive.create(this.props.match.params.id)
  }

  handleOnChange = (page) => {
    window.store.todoArchive.modify({
      currentPage: page,
    })
    window.store.todoArchive.list(this.props.match.params.id)
  }

  render() {
    util.setTitle(window.store.todo.item.title)

    let items = []
    for (let [index, item] of window.store.todoArchive.items.entries()) {
      items.push(
        <div key={index}>
          <div className={styles.boxHeader}>
            <DateTime type="since" text={item.created_at} />
          </div>
          <div className={styles.box}>
            <div className={styles.boxInner}>
              <Todo source={item} level="data" levelCount={0} readOnly={true} />
            </div>
          </div>
        </div>
      )
    }

    return (
      <Row>
        <Col span={24}>
          <div className="inner">
            {window.store.todo.item.id &&
              <div className="">
                <div className={styles.title}>
                  {window.store.todo.item.title}
                  <span className={styles.titleSuffix}>
                    #{window.store.todo.item.id}
                  </span>
                </div>
                <div className={styles.desc}>
                  <Enum type="hasBackground" text={window.store.todo.item.status} /> ·
                  opened <DateTime type="since" text={window.store.todo.item.created_at} /> ·
                  <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={this.handleBackup}>
                    <span className={styles.fakeLink}>
                      (create a backup)
                    </span>
                  </Popconfirm> ·&nbsp;
                  <Link to={`/todos/${window.store.todo.item.id}`}>(back)</Link>
                </div>
                {items}
                <div className={styles.pagination}>
                  <Pagination
                    total={window.store.todoArchive.pagination.total}
                    defaultCurrent={window.store.todoArchive.currentPage}
                    current={window.store.todoArchive.currentPage}
                    defaultPageSize={10}
                    onChange={this.handleOnChange}
                  />
                </div>
              </div>
            }
          </div>
          <Spin loading={window.store.todoArchive.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(View)
