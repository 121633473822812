import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Form, Button, Select, Progress, Modal } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import { get, post } from '../../../utils/request'
import util from '../../../utils'

const rules = [{ required: true }]

class Bulk extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      visibleUploading: false,
      progress: 0,
      textInput: '',
      textInputMode: false,
    }
    this.main = window.store.objectEpisode
    this.main_sb = window.store.sourceBgm
    this.add = null
  }

  loadData = async () => {
    let parsed = queryString.parse(this.props.location.search)
    if (parsed.oid) {
      this.formRef.current.setFieldsValue({
        obj_id: parsed.oid,
      })
    }
    else if (parsed.sid) {
      let source = (await get('/admin/source_bgms/' + parsed.sid)).data
      let subject = (await get('/admin/objs?page=1&category=&key=bgm_id&keywords=' + source.data.id)).data
      if (subject.items.length <= 0) {
        return
      }
      this.formRef.current.setFieldsValue({
        obj_id: '' + subject.items[0].id,
      })
      if (source.data.eps_music) {
        let last_key = 0
        let disk = 1
        for (let index in source.data.eps_music) {
          if (last_key > source.data.eps_music[index].order) {
            disk += 1
          }
          last_key = source.data.eps_music[index].order
          if (disk > 1) {
            source.data.eps_music[index].order += disk * 100
          }
        }
        for (let item of source.data.eps_music) {
          this.add({
            key: '' + item.order,
            title: util.cleanCrawledData(item.title),
            source: '' + item.id,
          })
        }
      }
    }
  }

  componentDidMount = () => {
    this.loadData()
  }

  onFinish = async(values) => {
    this.setState({
      loading: true,
      visibleUploading: true,
      progress: 0,
    })
    let total = values.episodes.length
    let current = 0
    for (let item of values.episodes) {
      await post('/admin/object_episodes', {
        ...item,
        obj_id: values.obj_id,
        alias: null,
        duration: null,
        released_at: null,
        source: item.source ? item.source_ + item.source : ''
      })
      current += 1
      this.setState({
        progress: Math.floor(current / total * 100),
      })
    }
    this.setState({
      progress: 100
    })
    window.location.href = '/#/objs/' + values.obj_id
  }

  handleOkUploading = () => this.setState({ visibleUploading: false })
  handleCancelUploading = () => this.setState({ visibleUploading: false })

  onClickTextInput = () => {
    this.setState({ textInputMode: true })
  }

  onTextInputChange = (e) => {
    this.setState({ textInput: e.target.value })
  }

  onClickTextInputConvert = () => {
    this.setState({ textInputMode: false })
    if (!this.state.textInput) return

    for (let [index, item] of Object.entries(this.state.textInput.split('\n'))) {
      this.add({
        key: '' + (parseInt(index) + 1),
        title: item,
        source: null,
      })
    }
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    }
    const formItemLayoutInline = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form ref={this.formRef} layout="horizontal" onFinish={this.onFinish}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Obj ID:"
              name="obj_id"
              rules={[{ required: true }]}
              validateStatus={this.main.errors.obj_id ? "error" : ""}
              help={this.main.errors.obj_id}>
              <Input disabled={true} />
            </Form.Item>

            <Form.List name="episodes">
              {(fields, { add, remove }) => {
                this.add = add
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Row gutter={24} key={field.key}>
                        <Col span={5}>
                          <Form.Item {...formItemLayoutInline} label="Category:"
                            name={[field.name, "category"]}
                            fieldKey={[field.fieldKey, "category"]}
                            rules={rules}
                            initialValue='none'
                            validateStatus={this.main.errors.category ? "error" : ""}
                            help={this.main.errors.category}>
                            <Select style={{ width: '100%' }} placeholder="Please select">
                              <Select.Option key='none'>Episode</Select.Option>
                              <Select.Option key='special'>Special</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item {...formItemLayoutInline} label="Key:"
                            name={[field.name, "key"]}
                            fieldKey={[field.fieldKey, "key"]}
                            rules={[{ whitespace: true }]}
                            validateStatus={this.main.errors.key ? "error" : ""}
                            help={this.main.errors.key}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item {...formItemLayoutInline} label="Title:"
                            name={[field.name, "title"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[{ whitespace: true }]}
                            validateStatus={this.main.errors.title ? "error" : ""}
                            help={this.main.errors.title}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item {...formItemLayoutInline} label="Source:"
                            name={[field.name, "source"]}
                            fieldKey={[field.fieldKey, "source"]}
                            rules={[{ whitespace: true }]}
                            validateStatus={this.main.errors.source ? "error" : ""}
                            help={this.main.errors.source}>
                            <Input addonBefore={
                              <Form.Item name={[field.name, "source_"]}
                                fieldKey={[field.fieldKey, "source_"]}
                                initialValue='bgm_ep_'
                                noStyle>
                                <Select style={{ width: 120 }} defaultValue='bgm_ep_'>
                                  <Select.Option value="bgm_ep_">bgm_ep_</Select.Option>
                                </Select>
                              </Form.Item>
                            } />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item {...formItemLayoutInline} label="Bio:"
                            name={[field.name, "bio"]}
                            fieldKey={[field.fieldKey, "bio"]}
                            initialValue=''
                            rules={[{ whitespace: true }]}
                            validateStatus={this.main.errors.bio ? "error" : ""}
                            help={this.main.errors.bio}>
                            <Input />
                          </Form.Item>
                        </Col>


                        <Col span={1}>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed"
                        onClick={() => { add() }}
                        style={{ width: "90%", margin: '0 5%' }}>
                        <PlusOutlined /> Add field
                      </Button>
                    </Form.Item>
                  </div>
                )
              }}
            </Form.List>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" loading={this.state.loading} htmlType="submit">Save</Button>
              {!this.state.textInputMode &&
                <Button type="dashed" onClick={this.onClickTextInput} style={{ marginLeft: 12 }}>Textarea</Button>
              }
            </Form.Item>

            {this.state.textInputMode &&
              <Form.Item {...formItemLayout} label="Textarea:"
                rules={[{ whitespace: true }]}>
                <Input.TextArea value={this.state.textInput}
                  onChange={this.onTextInputChange}
                  autoSize={{ minRows: 6, maxRows: 12 }}
                  style={{
                    width: '60%',
                    marginLeft: 12
                  }} />
                <Button type="dashed" onClick={this.onClickTextInputConvert} style={{ marginLeft: 12 }}>Convert</Button>
              </Form.Item>
            }
          </Form>

          <Modal title="Uploading"
            visible={this.state.visibleUploading}
            width={'96%'}
            style={{ top: 30 }}
            zIndex={1000}
            onOk={this.handleOkUploading}
            onCancel={this.handleCancelUploading}>
            <Progress percent={this.state.progress} status="active" />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default observer(Bulk)
