import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class DateTime extends React.Component {
  render() {
    if (!this.props.text) return <span style={{ color: '#aaa' }}>-</span>

    let time = moment(this.props.text)
    if (this.props.offset) {
      if (this.props.offset > 0) {
        time = time.add(Math.abs(this.props.offset), 'hours')
      } else {
        time = time.subtract(Math.abs(this.props.offset), 'hours')
      }
    }

    let excceed = Math.abs(time.diff(moment(), 'days')) > 7

    switch (this.props.type) {
      case 'since':
        if (excceed) {
          return <span>{time.format('YYYY/MM/DD HH:mm:ss')}</span>
        }
        return (
          <span>{time.fromNow()}</span>
        )
      case 'sinceThin':
        if (excceed) {
          return <span>{time.format('YYYY/MM/DD')}</span>
        }
        moment.updateLocale('en', {
          relativeTime : {
            future: "%s",
            past: "%s",
            s:  "1s",
            m:  "1m",
            mm: "%dm",
            h:  "1h",
            hh: "%dh",
            d:  "1d",
            dd: "%dd",
            M:  "1M",
            MM: "%dM",
            y:  "1y",
            yy: "%dy"
          }
        })
        return <span>{time.fromNow()}</span>
      case 'date':
        return <span>{time.format('YYYY/MM/DD')}</span>
      default:
        return <span>{time.format('YYYY/MM/DD HH:mm:ss')}</span>
    }
  }
}

DateTime.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  offset: PropTypes.number,
}

export default DateTime
