import React from 'react'
import PropTypes from 'prop-types'
import { LinkOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

class LengthLimiter extends React.Component {
  render() {
    let extra = {}
    let result = this.props.text
    if (this.props.width) {
      extra = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        maxWidth: this.props.width,
      }
    } else if (!this.props.length) {
      extra = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
      }
    } else if (this.props.text && this.props.text.length > this.props.length) {
      if (this.props.headtail) {
        let headLen = Math.ceil(this.props.length / 2)
        result = this.props.text.match(new RegExp(`(.{1,${headLen}})`), 'g')[0] + ' ... ' + this.props.text.match(new RegExp(`(.{1,${this.props.length - headLen}}$)`), 'g')[0]
      } else {
        result = this.props.text.match(new RegExp(`(.{1,${this.props.length}})`), 'g')[0] + ' ...'
      }
      if (this.props.type === 'suffix') {
        result += ' ' + this.props.text.match(new RegExp(`(.[a-z]+$)`), 'g')[0]
      }
    }

    let links = []
    if (this.props.showLinks) {
      let re = /http[^ ]+|magnet[^ ]+/g
      let link
      while ((link = re.exec(this.props.text)) !== null) {
        links.push(<a key={Math.random()} href={link} target='_blank' rel="noopener noreferrer" style={{ cursor: 'pointer', marginLeft: 5 }}><LinkOutlined /></a>)
      }
    }

    return (
      <Tooltip placement="bottomLeft" title={this.props.text}>
        <span style={{ cursor: 'pointer', ...extra }}>{result}</span>
        {links}
      </Tooltip>
    )
  }
}

LengthLimiter.propTypes = {
  type: PropTypes.string,
  length: PropTypes.number,
  width: PropTypes.number,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  showLinks: PropTypes.bool,
  headtail: PropTypes.bool,
}

export default LengthLimiter
