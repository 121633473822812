import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class FileSize extends React.Component {
  calculate = () => {
    let i = this.props.number

    if (i <= 1024) {
      return `${Number(i).toFixed(2)} Bytes`
    }

    i = i / 1024
    if (i <= 1024) {
      return `${Number(i).toFixed(2)} KB`
    }

    i = i / 1024
    if (i <= 1024) {
      return `${Number(i).toFixed(2)} MB`
    }

    i = i / 1024
    if (i <= 1024) {
      return `${Number(i).toFixed(2)} GB`
    }

    i = i / 1024
    return `${Number(i).toFixed(2)} TB`
  }

  render() {
    return (<span>{this.calculate()}</span>)
  }
}

FileSize.propTypes = {
  number: PropTypes.number,
}

export default FileSize
