import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Select, Form, Tag } from 'antd'
import LengthLimitedInput from '../../../components/shared/form/length_limited_input'

class Create extends React.Component {
  formRef = React.createRef()

  handleSubmit = values => {
    if (window.store.issue.loading) return
    window.store.issue.create(values)
  }

  onClickTag = item => {
    this.formRef.current.setFieldsValue({
      title: `${item}: `
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout}
              label="Title:"
              name="title"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.issue.errors.title ? "error" : ""}
              extra={
                <div style={{ marginTop: window.store.issue.errors.title ? -2 : 8 }}>
                  {'mass _ lambda moetrack works vault slayer crawler bots k8s blog external ml outsource'.split(' ').map(item => <Tag key={item} onClick={() => this.onClickTag(item)}>{item}</Tag>)}
                </div>
              }
              help={window.store.issue.errors.title}>
              <LengthLimitedInput max={128} />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Description:"
              name="desc"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.issue.errors.desc ? "error" : ""}
              help={window.store.issue.errors.desc}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Priority:"
              name="priority"
              rules={[{ validator: this.passCheck, }]}
              initialValue="high"
              validateStatus={window.store.issue.errors.priority ? "error" : ""}
              help={window.store.issue.errors.priority}>
              <Select
                style={{ width: '100%' }}
                placeholder="Please select">
                <Select.Option key="none">none</Select.Option>
                <Select.Option key="high">high</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.issue.loading}>Save{window.store.issue.loading}</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
