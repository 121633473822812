import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { LinkOutlined } from '@ant-design/icons'
import { Row, Col, Table } from 'antd'
import DateTime from '../../../components/shared/format/datetime'
import Status from '../../../components/shared/format/status'
import LengthLimiter from '../../../components/shared/format/length_limiter'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.tweet.list()
  }

  handleOnChange = (pagination) => {
    window.store.tweet.modify({
      currentPage: pagination.current,
    })
    window.store.tweet.list()
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: value => <Link to={`/tweets/${value}`}>{value}</Link>,
      },
      {
        title: 'Tweet ID',
        dataIndex: 'tweet_id',
        key: 'tweet_id',
      },
      {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        render: (value, record) => <span>
          <LengthLimiter length={64} text={value}></LengthLimiter>
          &nbsp;<a href={`https://twitter.com/statuses/${record.tweet_id}`} target='_blank' rel="noopener noreferrer"><LinkOutlined /></a>
        </span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status text={value}></Status>,
      },
      {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
    ]

    return (
      <Row>
        <Col span={24}>
          <Table
            owClassName={(_, index) =>
              index % 2 === 1 ? 'dark-row' : 'light-row'
            }
            bordered
            size="small"
            columns={columns}
            rowKey="id"
            dataSource={window.store.tweet.items}
            pagination={window.store.tweet.pagination}
            loading={window.store.tweet.loading}
            onChange={this.handleOnChange}
          />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
