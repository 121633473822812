import { types } from 'mobx-state-tree'
import _ from 'lodash'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const TodoArchiveBase = types
  .model({
    currentCategory: 'backup',
  })
  .actions(self => ({
    create(tid) {
      self.loading = true
      post(`/todos/${tid}/backups`).then(() => self.list(tid))
    },

    list(tid) {
      self.loading = true
      get(`/todos/${tid}/archives`, {
        page: self.currentPage,
        category: self.currentCategory,
      }).then(self.setData)
    },
  }))

const TodoArchive = types
  .compose(
    BasicObject,
    TodoArchiveBase,
  )

export default TodoArchive
