import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Divider, Pagination, Button, Modal, Input } from 'antd'
import util from '../../../utils'
import Workspace from './components/workspace'
import styles from './view.module.css'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
      visible: false,
    }

    this.main = window.store.translation
    this.main.read(this.props.match.params.id)
  }

  handleOnChange = (page) => {
    this.main.modify({
      currentPageSlave: page,
    })
    this.main.read(this.props.match.params.id)
    this.setState({
      selected: 0,
    })
  }

  onInputChange = (event) => {
    this.main.modify({
      currentSentence: event.target.value,
    })
  }

  onClickAddSentence = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.main.createText()
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  render() {
    if (this.main.item.item) {
      util.setTitle(this.main.item.item.title)
    }

    let workspaceItems = []
    if (this.main.item.items) {
      workspaceItems = this.main.item.items.map((item, index) =>
        <div className={this.state.selected === index ? styles.workspaceItemSelected : ''} key={item.id} onClick={() => {
          this.setState({ selected: index })
        }}>
          <div className={styles.workspaceSource}>
            {item.raw}
          </div>
          <div className={styles.workspaceResult}>
            {item.result ? item.result : '[null]'}
          </div>
        </div>
      )
    }

    return (
      <Row>
        <Col span={5}>
          <div className={styles.workspace}>
            <Divider orientation="left" plain>Workspace
              <span style={{ marginLeft: 8 }}>
                <Button type="dashed" size="small" onClick={this.onClickAddSentence}>+</Button>
              </span>
            </Divider>
            <div className={styles.workspaceItems}>
              {workspaceItems}
            </div>
            <div className={styles.pagination}>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                defaultPageSize={5}
                total={this.main.item.total}
                current={this.main.currentPageSlave}
                onChange={this.handleOnChange}
              />
            </div>
          </div>
          <Modal
            title="Insert Sentence:"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Input value={this.main.currentSentence} onChange={this.onInputChange} />
          </Modal>
        </Col>
        <Col span={19}>
          {this.main.item.items && this.main.item.items[this.state.selected] &&
            <Workspace
              store={this.props.history.store}
              itemID={this.main.item.items[this.state.selected].id}
              index={this.state.selected}
            />
          }
        </Col>
      </Row>
    )
  }
}

export default observer(View)
