import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

class FilterDropmenu extends React.Component {
  onClick = (e) => {
    this.props.click(e)
  }

  capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  render() {
    const values = this.props.value.split(' ')
    const items = values.map((item) => {
      return { value: item, label: item ? this.capitalize(item) : 'All' }
    })

    return (
      <div style={{ padding: '0 0 9px 8px', textAlign: 'right', display: 'inline-block' }}>
        <Select
          style={{ marginLeft: 8, width: 120 }}
          size='middle'
          defaultValue={this.props.default ? this.props.default : values[0]}
          onChange={this.onClick}
          options={items}
        />
      </div>
    )
  }
}

FilterDropmenu.propTypes = {
  value: PropTypes.string,
  click: PropTypes.func,
  default: PropTypes.string,
}

export default FilterDropmenu
