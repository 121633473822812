import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import utils from '../../utils'
import BasicObject from '../basic/base'

const TagBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get(`${utils.MT}/admin/tags`, {
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then(self.setData)
    },

    read(id) {
      get(`${utils.MT}/admin/tags/` + id).then(self.setItemData)
    },

    update(id, data) {
      put(`${utils.MT}/admin/tags/${id}`, data).then(({ error }) => {
        if (!error) window.location.href = `/#/tags`
      })
    },
  }))

export const Tag = types
  .compose(
    BasicObject,
    TagBase,
  )

export default Tag
