import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import utils from '../../utils'
import BasicObject from '../basic/base'

const ObjectEpisodeBase = types
  .model({
  })
  .actions(self => ({
    create(data) {
      self.loading = true
      post(`${utils.MT}/admin/object_episodes`, data).then(({ error }) => {
        if (!error) window.location.href = `/#/objs/${data.obj_id}`
      })
    },

    read(id) {
      get(`${utils.MT}/admin/object_episodes/` + id).then(self.setItemData)
    },

    update(id, data) {
      put(`${utils.MT}/admin/object_episodes/${id}`, data).then(({ error }) => {
        if (!error) window.location.href = `/#/objs/${data.obj_id}`
      })
    },

    delete(id, sid) {
      del(`${utils.MT}/admin/object_episodes/${id}`).then(() => window.store.adminObj.read(sid))
    },
  }))

export const ObjectEpisode = types
  .compose(
    BasicObject,
    ObjectEpisodeBase,
  )

export default ObjectEpisode
