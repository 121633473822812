import React from 'react'
import util from '../../../utils'
import Simple from '../../../components/shared/ant/table/simple'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='translation'
        store={this.props.history.store}
        columns={'title status created_at control'}
        options={{
          setTitle: util.setTitle,
          create: null,
          features: 'view',
          size: 'small',
        }}
      />
    )
  }
}

export default Index
