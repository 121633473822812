import React from 'react'
import PropTypes from 'prop-types'

class Title extends React.Component {
  render() {
    if (this.props.status === 'unread') {
      let additional = {}
      return (
        <span style={{ background: '#f6ffed', color: '#52c41a', ...additional }}>
          {this.props.text}
        </span>
      )
    }
    if (this.props.status === 'done') {
      return (
        <span style={{ opacity: 0.2 }}>
          {this.props.text}
        </span>
      )
    }
    if (this.props.status === 'pending') {
      return (
        <span style={{ opacity: 0.2, textDecoration: 'line-through' }}>
          {this.props.text}
        </span>
      )
    }
    if (this.props.status === 'fav') {
      return (
        <span style={{ color: '#ffa1a1' }}>
          {this.props.text}
        </span>
      )
    }
    return this.props.text
  }
}

Title.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired
  ])
}

export default Title
