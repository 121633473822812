import React from 'react'
import PropTypes from 'prop-types'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd';

class Bool extends React.Component {
  render() {
    let value = this.props.value
    if (this.props.method === 'icon') {
      if (value) {
        return <CheckCircleOutlined theme="outlined" style={{ color: value ? 'rgb(82, 196, 26)' : 'rgb(235, 47, 150)' }} />
      } else {
        return <CloseCircleOutlined theme="outlined" style={{ color: value ? 'rgb(82, 196, 26)' : 'rgb(235, 47, 150)' }} />
      }
    } else {
      if (value) {
        return (
          <Tag color="green">enabled</Tag>
        )
      }
      else {
        return (
          <Tag color="orange">disabled</Tag>
        )
      }
    }
  }
}

Bool.propTypes = {
  value: PropTypes.bool,
  method: PropTypes.string,
}

export default Bool
