import { types, flow } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const TweetShardBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/tweet_shards', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        order_by: self.currentOrderBy,
      }).then(self.setData)
    },

    update(id, data) {
      self.loading = true
      post(`/tweet_shards/${id}/favorite`, data).then(() => {
        self.list()
      })
    },

    favoriteAll: flow(function* favoriteAll() {
      for (let item of self.items) {
        if (item.status === 'none') {
          yield post(`/tweet_shards/${item.id}/favorite`, {
            flag: 'favorite'
          })
        }
      }
      self.list()
    }),

    delete(id) {
      self.loading = true
      del(`/tweet_shards/${id}`).then(() => {
        self.list()
      })
    },
  }))

const TweetShard = types
  .compose(
    BasicObject,
    TweetShardBase,
  )

export default TweetShard
