import React from 'react'
import {
  TwitterOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  RobotOutlined,
  ShopOutlined,
  UploadOutlined,
  IssuesCloseOutlined,
  BookOutlined,
  HeatMapOutlined,
  AppstoreAddOutlined,
  CustomerServiceOutlined,
  SketchOutlined,
  NumberOutlined,
  HddOutlined,
} from '@ant-design/icons'
import SiderBase from '../../components/shared/ant/layout/siderbase'

const tweetShards = [
  {
    key: '/tweet_users',
    name: 'Users'
  },
  {
    key: '/tweet_images',
    name: 'Images'
  },
  {
    key: '/tweet_image_files',
    name: 'Image File'
  },
  {
    key: '/tweet_image_files/thumbnails',
    name: 'Thumbnails'
  },
]

const track = [
  {
    key: '/characters',
    name: 'Characters'
  },
  {
    key: '/people',
    name: 'People'
  },
  {
    key: '/tags',
    name: 'Tags'
  },
  {
    key: '/lists',
    name: 'Lists'
  },
  {
    key: '/user_tokens',
    name: 'Tokens'
  },
  {
    key: '/source_bgms',
    name: 'Sources'
  },
]

const more = [
  {
    key: '/books',
    name: 'Books'
  },
  {
    key: '/pages',
    name: 'Wiki'
  },
  {
    key: '/docs',
    name: 'Doc'
  },
  {
    key: '/charts',
    name: 'Charts'
  },
  {
    key: '/translations',
    name: 'Translation'
  },
  {
    key: '/subjects',
    name: 'Subject'
  },
]

const menu = {
  userMenu: [
    {
      key: '/issues',
      name: 'Issue',
      icon: <RobotOutlined />
    },
    {
      key: '/todos',
      name: 'TODO',
      icon: <IssuesCloseOutlined />
    },
    {
      key: '/bookmarks',
      name: 'Bookmark',
      icon: <BookOutlined />
    },
    {
      key: '/maps',
      name: 'Map',
      icon: <HeatMapOutlined />
    },
    {
      key: '/warehouse_items',
      name: 'WarehouseItems',
      icon: <HddOutlined />
    },
    {
      key: '/tweet_shards',
      name: 'Twitter',
      icon: <TwitterOutlined />,
      sub: tweetShards
    },
    {
      key: '/telegram_logs',
      name: 'Telegram',
      icon: <SendOutlined />
    },
    {
      key: '/shopping_items',
      name: 'ShoppingItems',
      icon: <ShoppingCartOutlined />
    },
    {
      key: '/subscriptions',
      name: 'Subscription',
      icon: <CustomerServiceOutlined />
    },
    {
      key: '/uploads',
      name: 'Uploads',
      icon: <UploadOutlined />,
      sub: more
    },
    {
      key: '/objs',
      name: 'Track',
      icon: <SketchOutlined />
    },
    {
      key: '/collections',
      name: 'Collections',
      icon: <NumberOutlined />,
      sub: track
    },
    {
      key: '/admin/apps',
      name: 'Apps',
      icon: <RobotOutlined />
    },
  ],
  adminMenu: [],
  showRegister: false,
  siteName: "S",
}

const Nav = (props) => (
  <SiderBase menu={menu}>{props.children}</SiderBase>
)

export default Nav
