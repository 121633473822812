import React from 'react'
import PropTypes from 'prop-types'
import styles from './enum.sass'

class Enum extends React.Component {
  translate() {
    return this.props.text === null ? 'normal' : this.props.text
  }
  render() {
    return (
      <span className={this.props.type === 'hasBackground' ? styles.background : ''}>
        {this.translate()}
      </span>
    )
  }
}

Enum.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
}

export default Enum
