import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import utils from '../../utils'
import BasicObject from '../basic/base'

const SourceBgmBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get(`${utils.MT}/admin/source_bgms`, {
        page: self.currentPage,
        per: self.pagination.pageSize,
        start_time: self.currentStartTime,
        end_time: self.currentEndTime,
      }).then(self.setData)
    },
  }))

export const SourceBgm = types
  .compose(
    BasicObject,
    SourceBgmBase,
  )

export default SourceBgm
