import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import utils from '../../utils'
import BasicObject from '../basic/base'

const PersonBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get(`${utils.MT}/admin/people`, {
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then(self.setData)
    },
  }))

export const Person = types
  .compose(
    BasicObject,
    PersonBase,
  )

export default Person
