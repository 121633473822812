import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Form } from 'antd'
import { get } from '../../../utils/request'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      editMode: this.props.match.path === '/maps/edit/:id',
    }

    if (this.state.editMode) {
      this.loadData()
    }
  }

  loadData = async () => {
    let origin = (await get('/maps/' + this.props.match.params.id)).data
    this.formRef.current.setFieldsValue(origin)
  }

  handleSubmit = values => {
    if (window.store.map.loading) return

    if (this.state.editMode) {
      window.store.map.update(this.props.match.params.id, -1, values)
    } else {
      window.store.map.create(values)
    }
  }

  componentDidMount = () => {
    if (window.debug.name === 'debug') this.formRef.current.setFieldsValue({
      title: Math.random().toString(36).substring(20),
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }

    return (
      <Row>
        <Col span={24}>
          <Form ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout} label="Title:"
              name="title"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.map.errors.title ? "error" : ""}
              help={window.store.map.errors.title}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Description:"
              name="desc"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.map.errors.desc ? "error" : ""}
              help={window.store.map.errors.desc}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.map.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
