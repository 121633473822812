import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  PauseCircleFilled,
  PauseCircleOutlined,
  RightCircleFilled,
  RightCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { Row, Col, Table, Input, Button } from 'antd'
import util from '../../../utils'
import FilterBar from '../../../components/shared/filter_bar'
import Filter from '../../../components/shared/ant/bar/filter'
import Status from '../../../components/shared/format/status'
import DateTime from '../../../components/shared/format/datetime'
import Spin from '../../../components/shared/form/spin'
import TodoText from './components/todo_text'

const styles = {
  clickableIconWrapper: {
    cursor: 'pointer',
    padding: '10px 5px 10px 0',
  },
  icon: {
    marginRight: '4px',
  },
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keywords: '',
    }

    util.setTitle('Issue')
    window.store.issue.list()
  }

  handleOnChange = (pagination) => {
    window.store.issue.modify({
      currentPage: pagination.current,
    })
    window.store.issue.list()
  }

  onModifyItemStatus = (item, value, index) => {
    window.store.issue.update(item.id, { status: value }, index)
  }

  onModifyItemPriority = (item, value, index) => {
    window.store.issue.update(item.id, { priority: value }, index)
  }

  onDeleteItem = (item) => {
    window.store.issue.delete(item.id)
  }

  handleSubmit = () => {
    window.store.issue.modify({
      currentPage: 1,
      currentKeywords: this.state.keywords,
    })
    window.store.issue.list()
  }

  emitEmpty = () => {
    this.setState({ keywords: '' })
    window.store.issue.modify({
      currentPage: 1,
      currentKeywords: '',
    })
    window.store.issue.list()
  }

  onChangeKeywords = (e) => {
    this.setState({ keywords: e.target.value })
  }

  onClickSwitcher = (e) => {
    let priority = ''
    let status = ''
    switch (e.target.value) {
    case 'working':
      priority = ''
      status = 'working'
      break
    case 'high':
      priority = 'high'
      status = ''
      break
    case 'normal':
      priority = 'none'
      status = ''
      break
    case 'done':
      priority = ''
      status = 'done'
      break
    case 'cancelled':
      priority = ''
      status = 'cancelled'
      break
    default:
    }
    window.store.issue.modify({
      currentPage: 1,
      currentPriority: priority,
      currentStatus: status,
    })
    window.store.issue.list()
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (value, item) => <TodoText priority={item.priority} status={item.status} text={value}></TodoText>,
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          {item.status !== 'working' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'working', index)}>
              <RightCircleOutlined style={styles.icon} />
            </span>
          }
          {item.status === 'working' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'none', index)}>
              <RightCircleFilled style={styles.icon} />
            </span>
          }
          {item.status !== 'done' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'done', index)}>
              <CheckCircleOutlined style={styles.icon} />
            </span>
          }
          {item.status === 'done' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'none', index)}>
              <CheckCircleFilled style={styles.icon} />
            </span>
          }
          {item.priority !== 'high' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemPriority(item, 'high', index)}>
              <ExclamationCircleOutlined style={styles.icon} />
            </span>
          }
          {item.priority === 'high' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemPriority(item, 'none', index)}>
              <ExclamationCircleFilled style={styles.icon} />
            </span>
          }
          {item.status !== 'cancelled' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'cancelled', index)}>
              <PauseCircleOutlined style={styles.icon} />
            </span>
          }
          {item.status === 'cancelled' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'none', index)}>
              <PauseCircleFilled style={styles.icon} />
            </span>
          }
        </span>,
      },
    ]

    const buttons = [
      {
        text: 'All',
        value: '',
      },
      {
        text: 'Working',
        value: 'working',
      },
      {
        text: 'High priority',
        value: 'high',
      },
      {
        text: 'Normal',
        value: 'normal',
      },
      {
        text: 'Done',
        value: 'done',
      },
      {
        text: 'Cancelled',
        value: 'cancelled',
      },
    ]

    const filterLeft = <Link to={`/issues/create`}>
      <Button type="dashed">New</Button>
    </Link>

    const filterRight = <span>
      <Filter value={buttons} click={this.onClickSwitcher} default={window.store.issue.currentStatus} />
      <span className="inlineFilter">
        <Input
          suffix={<CloseCircleOutlined onClick={this.emitEmpty} />}
          onChange={this.onChangeKeywords}
          onPressEnter={this.handleSubmit}
          value={this.state.keywords} />
        <Button type="primary" onClick={this.handleSubmit}>Filter</Button>
      </span>
    </span>

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
          <Table
            size="small"
            columns={columns}
            dataSource={window.store.issue.items}
            pagination={window.store.issue.pagination}
            rowKey='id'
            onChange={this.handleOnChange} />
          <Spin loading={window.store.issue.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
