import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, PageHeader, Card, Badge } from 'antd'
import Complex from '../../../components/shared/ant/table/complex'
import styles from './view.module.sass'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.main = window.store.collection
    this.main.read(this.props.match.params.id)
  }

  handleAdd = (ids) => {
    this.main.batch_insert(ids, this.props.match.params.id)
  }

  handleSearch = (values) => {
    this.main.modify({
      currentKeywords: values.keywords
    })
    this.main.search()
  }

  handleChange = (page) => {
    this.main.modify({
      currentViewPage: page,
    })
    this.main.search()
  }

  collection_id = (cid) => {
    if (!cid) return 'default'
    cid = '' + cid
    if (cid === this.props.match.params.id) return 'success'
    return 'error'
  }

  category = (value) => value.includes('offprint') ? 'error' : 'success'

  render() {
    return (
      <Row>
        <Col span={24}>
          {this.main.item &&
            <div className={styles.item}>
              <PageHeader
                className="site-page-header"
                onBack={() => null}
                title={this.main.item.name}
                subTitle={this.main.item.alias ? this.main.item.alias.join(', ') : ''}
              />
              <Card title="Include" loading={this.main.loading}>
                <Complex
                  items={this.main.item.objs}
                  columns='carrier category name released_at collection_relation'
                  options={{
                    id: value => <Link to={`/objs/${value}`}>{value}</Link>,
                    handleChange: () => {},
                    pagination: null,
                  }}
                />
              </Card>
              <Card title="Search" loading={this.main.loading}>
                <Complex
                  items={this.main.searchedSubjects}
                  columns='collection_id carrier category name alias count_ep count_volume released_at'
                  options={{
                    id: value => <Link to={`/objs/${value}`}>{value}</Link>,
                    collection_id: value => <span><Badge status={this.collection_id(value)} /><Link to={`/collections/${value}`}>{value}</Link></span>,
                    category: value => <span><Badge status={this.category(value)} />{value}</span>,
                    alias: value => <span>{value.slice(0, 1)}</span>,
                    defaultSearchKeywords: this.main.currentKeywords,
                    handleSearch: this.handleSearch,
                    handleChange: this.handleChange,
                    pagination: this.main.pagination,
                    topRightButtons: [
                      ['add', this.handleAdd],
                    ]
                  }}
                />
              </Card>
            </div>
          }
        </Col>
      </Row>
    )
  }
}

export default observer(View)
