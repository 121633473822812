import React from 'react'
import Simple from '../../../components/shared/ant/table/simple'
import util from '../../../utils'
import { BookOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='map'
        store={this.props.history.store}
        columns={'title count_mark category status created_at control'}
        control={{
          status: {
            done: 'check-circle',
          }
        }}
        options={{
          setTitle: util.setTitle,
          title: (value, item) => {
            if (item.status === 'done') {
              return (
                <span style={{ opacity: 0.2 }}>
                  {value}
                </span>
              )
            }
            return value
          },
          create: null,
          edit: null,
          size: 'small',
          features: 'open_in_new_tab',
        }}
        additionalButtons={
          <Form.Item>
            <a href={`javascript:window.open('${window.location.origin}/#/maps/bookmark?url='+window.btoa(encodeURIComponent(window.location.href)).replace('=', '|'));`}>
              <Button icon={<BookOutlined />} />
            </a>
          </Form.Item>
        }
      />
    )
  }
}

export default Index
