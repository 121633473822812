import React from 'react'
import PropTypes from 'prop-types'
import { FileOutlined } from '@ant-design/icons'

class ExternalLinks extends React.Component {
  render() {
    if (this.props.show === false) return <span></span>
    if (!this.props.list || this.props.list.length <= 0) return (<span></span>)
    let items = []
    for (let [index, item] of this.props.list.entries()) {
      items.push(
        <a style={{ marginRight: 4 }} target="_blank" rel="noopener noreferrer" href={(this.props.prefix ? this.props.prefix : '') + ((item && item.key) ? item.key : item)} key={index}><FileOutlined /></a>
      )
    }
    return (
      <span>{items}</span>
    )
  }
}

ExternalLinks.propTypes = {
  list: PropTypes.array,
  prefix: PropTypes.string,
  show: PropTypes.bool,
}

export default ExternalLinks
