import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  CheckCircleOutlined,
  CheckCircleFilled,
  DeleteOutlined,
} from '@ant-design/icons'
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Select,
  Form,
  Popconfirm,
  DatePicker,
  Pagination
} from 'antd'
import Filter from '../bar/filter'
import MultiFilter from '../bar/multi_filter'
import FilterBar from '../../filter_bar'
import Status from '../../format/status'
import DateTime from '../../format/datetime'
import { pluralize } from 'inflected'
import moment from 'moment'

class Simple extends React.Component {
  constructor(props) {
    super(props)
    this.main = window.store[this.props.keywords.lowerCamelCase()]
    this.plural = this.props.slug ? this.props.slug : pluralize(this.props.keywords.replace(' ', '_'))
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
    ]
    this.features = {}
    if (this.props.options.features) {
      for (let item of this.props.options.features.split(' ')) {
        this.features[item] = true
      }
    }
    if (this.props.options.id) {
      this.columns[0]['render'] = this.props.options.id
    } else if (this.features.open_in_new_tab) {
      this.columns[0]['render'] = value => <Link target='_blank' to={`/${this.plural}/${value}`} rel="noopener noreferrer">{value}</Link>
    } else {
      this.columns[0]['render'] = value => <Link to={`/${this.plural}/${value}`}>{value}</Link>
    }
    const statusKeywords = 'category status flag'.split(' ')
    const dateTimeKeywords = 'created_at updated_at'.split(' ')
    const columns = this.props.columns.split(' ')
    const iconWrapper = {
      cursor: 'pointer',
      padding: '10px 5px 10px 0',
    }
    for (let item of columns) {
      let res = {
        title: item.upperCamelCase(),
        dataIndex: item,
        key: item,
      }
      if (item === 'control') {
        let control = this.props.control
        res['render'] = (value, item, index) => {
          let icons = []
          if ('edit' in this.props.options) {
            icons.push(<span key={Math.random()}>
              <Link to={`/${this.plural}/edit/${item.id}`}>
                <span style={iconWrapper}>
                  <Button type="dashed" size="small">Edit</Button>
                </span>
              </Link>
            </span>)
          }
          if (this.features.view) {
            icons.push(<span key={Math.random()}>
              <Link to={`/${this.plural}/${item.id}`}>
                <span style={iconWrapper}>
                  <Button type="dashed" size="small">View</Button>
                </span>
              </Link>
            </span>)
          }
          for (let field in control) {
            for (let content in control[field]) {
              let icon = {}
              if (typeof control[field][content] === 'string') {
                icon.outlined = <CheckCircleOutlined />
                icon.filled = <CheckCircleFilled />
              } else {
                icon = control[field][content]
              }
              icons.push(<span key={Math.random()}>
                {item[field] !== content &&
                  <span
                    style={iconWrapper}
                    onClick={() => this.onModifyItem(item, field, content, index)}>
                    {icon.outlined}
                  </span>
                }
                {item[field] === content &&
                  <span
                    style={iconWrapper}
                    onClick={() => this.onModifyItem(item, field, 'none', index)}>
                    {icon.filled}
                  </span>
                }
                <span>&nbsp;</span>
              </span>)
            }
          }
          if ('deletion' in this.props.options) {
            icons.push(<span key={Math.random()}>
              <Popconfirm title="Are you sure?" onConfirm={() => this.onDeleteItem(item)} okText="Yes" cancelText="No">
                <span style={iconWrapper}>
                  <DeleteOutlined style={{ marginRight: 4 }} />
                </span>
              </Popconfirm>
            </span>)
          }
          return <span>{icons}</span>
        }
      } else if (dateTimeKeywords.indexOf(item) >= 0) {
        res['render'] = value => <DateTime type={this.props.options[item] ? this.props.options[item] : ''} text={value}></DateTime>
      } else if (item in this.props.options) {
        if (item !== 'size') res['render'] = this.props.options[item]
      } else if (statusKeywords.indexOf(item) >= 0) {
        res['render'] = value => <Status text={value}></Status>
      }
      this.columns.push(res)
    }
    this.buttons = [
      {
        text: 'All',
        value: '',
      },
    ]
    if (this.props.control && this.props.control.status) {
      for (let item in this.props.control.status) {
        this.buttons.push({
          text: item.upperCamelCase(),
          value: item,
        })
      }
    }

    if (this.props.options.param) {
      return this.main.list(this.props.options.param)
    }
    this.main.list()
  }

  onModifyItem = (item, key, value, index) => {
    this.main.update(item.id, index, {
      [key]: value
    })
  }

  onDeleteItem = (item) => {
    this.main.delete(item.id)
  }

  handleSubmit = values => {
    this.main.modify({
      currentPage: 1,
      currentStatus: '',
      currentKey: values.key,
      currentKeywords: values.keywords
    })
    this.main.list()
  }

  onClickSwitcher = (e, key) => {
    this.main.modify({
      currentPage: 1,
      [key ? `current${key.upperCamelCase()}` : 'currentStatus']: e.target ? e.target.value : e,
    })
    this.main.list()
  }

  onDateRangeChange = (value) => {
    if (value === null) {
      this.main.modify({
        currentStartTime: '',
        currentEndTime: '',
      })
    } else {
      let start = value.clone().startOf('month')
      let end = value.clone().endOf('month')
      this.main.modify({
        currentStartTime: start.toISOString(),
        currentEndTime: end.toISOString(),
      })
    }
    this.main.list()
  }

  handleChange = (page) => {
    this.main.modify({
      currentPage: page,
    })
    if (this.props.options.param) {
      return this.main.list(this.props.options.param)
    }
    this.main.list()
  }

  render() {
    let addonBefore = <span>Search</span>
    if (this.props.options.filters) {
      let options = []
      for (let item of this.props.options.filters) {
        options.push(<Select.Option value={item} key={item}>{item.upperCamelCase()}</Select.Option>)
      }
      addonBefore = (
        <Form.Item name="key"
          initialValue={this.props.options.filters[0]}
          noStyle>
          <Select style={{ width: 120 }}>
            {options}
          </Select>
        </Form.Item>
      )
    }
    let filters2 = []
    if (this.props.options.filters2) {
      filters2 = Object.entries(this.props.options.filters2).map(([key, item]) =>
        <MultiFilter key={key} value={item} click={(val) => this.onClickSwitcher(val, key)} />
      )
    }

    const filterLeft = (
      <Form layout="inline" onFinish={this.handleSubmit}>
        <Form.Item name="keywords">
          <Input addonBefore={addonBefore} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Filter</Button>
        </Form.Item>
        {'create' in this.props.options &&
          <Form.Item>
            <Link to={`/${this.plural}/create`}>
              <Button type="dashed">New</Button>
            </Link>
          </Form.Item>
        }
        {this.props.additionalButtons}
      </Form>
    )

    let secondFilter = []
    if (this.props.options.categories) {
      for (let category in this.props.options.categories) {
        let buttons = [
          {
            text: 'All',
            value: '',
          },
        ]
        for (let item of this.props.options.categories[category].split(' ')) {
          buttons.push({
            text: item.upperCamelCase(),
            value: item,
          })
        }
        secondFilter.push(
          <Filter key={'filter-' + category} value={buttons} click={(e) => {
            this.main.modify({
              currentPage: 1,
              [category]: e.target.value,
            })
            this.main.list()
          }}></Filter>
        )
      }
    }
    if (this.features.datepicker) {
      secondFilter.push(
        <DatePicker key='datepicker' onChange={this.onDateRangeChange} picker="month" />
      )
    }

    const filterRight = (
      <span>
        {filters2}
        {secondFilter}
        <Filter key='filter-first' value={this.buttons} click={this.onClickSwitcher}></Filter>
      </span>
    )

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
          <Table
            rowClassName={(record, index) =>
              this.props.options.size === 'small' ? (index % 2 === 1 ? 'dark-row' : 'light-row') : ''
            }
            bordered={this.props.options.size === 'small' ? true : false}
            size={this.props.options.size === 'small' ? 'small' : 'default'}
            columns={this.columns}
            rowKey="id"
            dataSource={this.main.items}
            pagination={false}
            loading={this.main.loading}
            onChange={(page) => this.handleChange(page)}
          />
          <Pagination
            style={{
              margin: '16px 0',
              float: 'right',
            }}
            defaultCurrent={1}
            pageSize={this.main.pagination.pageSize}
            total={this.main.pagination.total > 0 ? this.main.pagination.total : null}
            current={this.main.currentPage}
            size={this.props.options.size === 'small' ? 'small' : 'default'}
            onChange={(page) => this.handleChange(page)} />
        </Col>
      </Row>
    )
  }
}

Simple.propTypes = {
  keywords: PropTypes.string,
  /**
    'bookmark'
   */
  slug: PropTypes.string,
  /**
    'admin/tags'
   */
  store: PropTypes.object,
  /**
    {this.props.history.store}
   */
  columns: PropTypes.string,
  /**
    {'title url link category status created_at control'}
   */
  control: PropTypes.object,
  /**
    {
      status: {
        dontBuy: {
          outlined: <ExclamationCircleOutlined />,
          filled: <ExclamationCircleFilled />,
        },
        daily: {
          outlined: <HeartOutlined />,
          filled: <HeartFilled />,
        },
        done: {
          outlined: <CheckCircleOutlined />,
          filled: <CheckCircleFilled />,
        },
      }
    }
  */
  options: PropTypes.object,
  /**
    {
      title: (value, item) => {
      },
      created_at: 'sinceThin',
      create: null,
      edit: null, // show edit button on each item
      deletion: null, // show deletion button on each item
      plural: 'watches', // admin/objs
      features: 'open_in_new_tab view datepicker',
      filters: ['title', 'url'],
      categories: {
        currentCategory: 'text attach group_text group_attach',
        currentOrderBy: 'price',
      }, // filters
      size: 'small',
      param: this.props.match.params.id,
    }
  */
  additionalButtons: PropTypes.object,
  /**
    {
      <Form.Item>
        <a href="">
          <Button>Bookmark</Button>
        </a>
      </Form.Item>
    }
   */
}

export default observer(Simple)
