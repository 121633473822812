import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const TweetImageFileBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/tweet_image_files', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
      }).then(self.setData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/tweet_image_files/${id}`, data).then(() => {
        self.list()
      })
    },

    delete(id) {
      self.loading = true
      del(`/tweet_image_files/${id}`).then(() => {
        self.list()
      })
    },
  }))

const TweetImageFile = types
  .compose(
    BasicObject,
    TweetImageFileBase,
  )

export default TweetImageFile
