import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Row, Col, Table, Progress, Input, Button } from 'antd'
import util from '../../../utils'
import FilterBar from '../../../components/shared/filter_bar'
import Filter from '../../../components/shared/ant/bar/filter'
import Status from '../../../components/shared/format/status'
import DateTime from '../../../components/shared/format/datetime'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keywords: '',
    }

    util.setTitle('Todo')
    window.store.todo.list()
  }

  handleOnChange = (pagination) => {
    window.store.todo.modify({
      currentPage: pagination.current,
    })
    window.store.todo.list()
  }

  onClickSwitcher = (e) => {
    window.store.todo.modify({
      currentPage: 1,
      currentStatus: e.target.value
    })
    window.store.todo.list()
  }

  handleSubmit = () => {
    window.store.todo.modify({
      currentPage: 1,
      currentKeywords: this.state.keywords,
      currentStatus: '',
    })
    window.store.todo.list()
  }

  emitEmpty = () => {
    this.setState({ keywords: '' })
    window.store.todo.modify({
      currentPage: 1,
      currentKeywords: '',
      currentStatus: 'open',
    })
    window.store.todo.list()
  }

  onChangeKeywords = (e) => {
    this.setState({ keywords: e.target.value })
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (value, item) => <Link to={`/todos/${item.id}`}>{value}</Link>
      },
      {
        title: '',
        dataIndex: 'progress',
        key: 'progressGraph',
        render: value => {
          let j = value.split(' of ')
          return (
            <Progress percent={parseInt((100*j[0]/(j[1] > 0 ? j[1] : 1)).toFixed(0))} width={40} />
          )
        }
      },
      {
        title: '',
        dataIndex: 'progress',
        key: 'progress',
      },
      {
        title: 'Highlights',
        dataIndex: 'highlights',
        key: 'highlights',
        render: value => <span>{(value && value > 0) ? value : ''}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
    ]

    const buttons = [
      {
        text: 'Open',
        value: 'open',
      },
      {
        text: 'Closed',
        value: 'closed',
      },
      {
        text: 'All',
        value: '',
      },
    ]

    const filterLeft = <Link to='/todos/create'>
      <Button type="dashed">New</Button>
    </Link>

    const filterRight = <span>
      <Filter value={buttons} click={this.onClickSwitcher}></Filter>
      <span className="inlineFilter">
        <Input
          suffix={<CloseCircleOutlined onClick={this.emitEmpty} />}
          onChange={this.onChangeKeywords}
          onPressEnter={this.handleSubmit}
          value={this.state.keywords} />
        <Button type="primary" onClick={this.handleSubmit}>Filter</Button>
      </span>
    </span>

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
          <Table
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={window.store.todo.items}
            pagination={window.store.todo.pagination}
            onChange={this.handleOnChange} />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
