import React from 'react'
import { observer } from 'mobx-react'
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Row, Col, Table, Button, Form } from 'antd'
import _ from 'lodash'
import Spin from '../../../components/shared/form/spin'
import styles from './view.module.css'

class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      subjects: [],
    }

    this.main = window.store.mapPlan
    this.main.read(this.props.match.params.id)
  }

  onClickTransmit = () => {
    this.main.addMark({
      duration: 1
    })
  }

  render() {
    let start = 0

    let times = []
    let hourLines = []
    let hourHalfLines = []
    for (let i = start; i <= 24; i++) {
      let text = i + ':00'
      let intv = (i-start)*4.4
      if (i === 0 || i === 24) text = <span></span>
      times.push(<span key={'time' + i} className={styles.time} style={{ top: `${intv}%` }}>
        {text}
      </span>)
      hourLines.push(<div key={'hour' + i} className={styles.hourLine} style={{ top: `${1+intv}%` }}></div>)
      if (i !== 24) hourHalfLines.push(<div key={'hourHalf' + i} className={styles.hourHalfLines} style={{ top: `${3.2+intv}%` }}></div>)
    }

    let subjects = []
    if (window.store.map.item.map_marks) {
      let marks = {}
      for (let item of window.store.map.item.map_marks) {
        marks[item.id] = item
      }
      for (let [index, item] of Object.entries(this.main.current)) {
        let top = 1 + 4.4 * (item.arrive_at - start)
        let height = 4.4 * item.duration
        subjects.push(
          <div
            key={'subject' + index}
            className={styles.subject}
            style={{ top: `${top}%`, height: `${height}%` }}>
            {marks[item.mark_id]?.name} ({item.duration}h)
            <div className={styles.close}>
              <Button
                type="dashed"
                icon={<CloseOutlined />}
                shape="circle"
                size="small"
                onClick={() => this.main.deleteMark(parseInt(index))}
              ></Button>
            </div>
          </div>
        )
      }
    }

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Open at',
        dataIndex: 'open_at',
        key: 'open_at',
      },
      {
        title: 'Close at',
        dataIndex: 'close_at',
        key: 'close_at',
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          <span className={styles.clickableIconWrapper} onClick={() => this.main.addMark(item)}>
            <PlusCircleOutlined className={styles.icon} />
          </span>
        </span>,
      },
    ]

    return (
      <Row>
        <Col span={24}>
          <div className={styles.board}>
            {times}
            {hourLines}
            {hourHalfLines}
            {subjects}
          </div>
          <div className={styles.marks}>
            <div className="innerTopBar" style={{ marginBottom: 12 }}>
              <Button type="default" onClick={this.onClickTransmit}>Transmit</Button>
            </div>
            <Table
              columns={columns}
              dataSource={window.store.map.item.map_marks}
              rowKey='id'
            />
          </div>
          <Spin loading={this.main.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(Timeline)
