import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Table } from 'antd'
import DateTime from '../../../components/shared/format/datetime'
import Status from '../../../components/shared/format/status'
import LengthLimiter from '../../../components/shared/format/length_limiter'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.tweetUser.list()
  }

  handleOnChange = (pagination) => {
    window.store.tweetUser.modify({
      currentPage: pagination.current,
    })
    window.store.tweetUser.list()
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: value => <Link to={`/tweet_users/${value}`}>{value}</Link>,
      },
      {
        title: 'Tweet User',
        dataIndex: 'tweet_user_id',
        key: 'tweet_user_id',
        render: value => <LengthLimiter length={9} text={'' + value}></LengthLimiter>
      },
      {
        title: 'Screen name',
        dataIndex: 'screen_name',
        key: 'screen_name'
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: value => <LengthLimiter length={12} text={'' + value}></LengthLimiter>
      },
      {
        title: 'Statuses',
        dataIndex: 'statuses_count',
        key: 'statuses_count'
      },
      {
        title: 'Favourites',
        dataIndex: 'favourites_count',
        key: 'favourites_count'
      },
      {
        title: 'Followers',
        dataIndex: 'followers_count',
        key: 'followers_count'
      },
      {
        title: 'Friends',
        dataIndex: 'friends_count',
        key: 'friends_count'
      },
      {
        title: 'Listed',
        dataIndex: 'listed_count',
        key: 'listed_count'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status text={value}></Status>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
    ]

    return (
      <Row>
        <Col span={24}>
          <Table
            rowClassName={(_, index) =>
              index % 2 === 1 ? 'dark-row' : 'light-row'
            }
            bordered
            size="small"
            columns={columns}
            rowKey="id"
            dataSource={window.store.tweetUser.items}
            pagination={window.store.tweetUser.pagination}
            loading={window.store.tweetUser.loading}
            onChange={this.handleOnChange}
          />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
