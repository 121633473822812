import { types } from 'mobx-state-tree'
import { post, get, put, del } from '../../utils/request'
import BasicObject from '../basic/base'

const MapMarkBase = types
  .model({
    markers_loaded: false,
    recent_modified_mark_id: 0,
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/map_marks', payload).then((data) => self.deferCreate(payload.map_id, data))
    },
    deferCreate(mid, { data, error }) {
      if (self.checkError(error)) return
      self.recent_modified_mark_id = data.id
      self.markers_loaded = false
      window.store.map.read(mid)
    },

    update(id, mid, payload) {
      self.loading = true
      put(`/map_marks/${id}`, payload).then((data) => self.deferCreate(mid, data))
    },

    delete(id, mid) {
      del(`/map_marks/${id}`).then((data) => self.deferDelete(mid, data))
    },
    deferDelete(mid, { error }) {
      if (error) return
      self.markers_loaded = false
      window.store.map.read(mid)
    },
  }))

const MapMark = types
  .compose(
    BasicObject,
    MapMarkBase,
  )

export default MapMark
