import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, PageHeader, Card, Radio } from 'antd'
import util from '../../../utils'
import Spin from '../../../components/shared/form/spin'
// https://github.com/remarkjs/react-markdown/blob/main/src/ast-to-react.js
// https://github.com/remarkjs/remark
// https://github.com/unifiedjs/unified

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      operation: null,
    }

    this.reload()
    this.unlisten = this.props.history.listen((location, action) => {
      this.reload()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  reload = () => {
    window.store.page.read(this.props.match.params.id)
  }

  handleSubjectOperation = (e) => {
    return {
    }[e.target.value]()
  }

  renderSlugs = () => {
    if (!window.store.page.item.content) return ''

    const regex = /\[\[([^[\]]+)\]\]/ig
    let items = []
    for (let item of window.store.page.item.content.matchAll(regex)) {
      items.push(
        <li key={item[1]}>
          <Link to={`/pages/${item[1]}`} target='_blank' rel="noreferrer">{item[1]}</Link>
        </li>
      )
    }
    return items
  }

  render() {
    util.setTitle(window.store.page.item.title)

    return (
      <Row>
        <Col span={24}>
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={window.store.page.item.title}
            subTitle={''}
          />
          <Row>
            <Col span={4}>
              <Card title="Slugs" style={{ marginRight: 12 }}>
                {this.renderSlugs()}
              </Card>
            </Col>
            <Col span={20}>
              <Card title="Wiki" extra={
                <Radio.Group value={this.state.operation} onChange={this.handleSubjectOperation}>
                  <Radio.Button value="large">
                    <Link to={`/pages/edit/${this.props.match.params.id}`}>Edit</Link>
                  </Radio.Button>
                </Radio.Group>
              }>
                <pre style={{ whiteSpace: 'pre-line' }}>
                  {window.store.page.item.content}
                </pre>
              </Card>
            </Col>
          </Row>
          <Spin loading={window.store.page.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(View)
