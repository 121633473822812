import { types, flow } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const TelegramLogBase = types
  .model({
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/telegram_logs', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        keywords: self.currentKeywords,
        start_time: self.currentStartTime,
        end_time: self.currentEndTime,
      }).then(self.setData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/telegram_logs/${id}/status`, data).then(() => {
        self.list()
      })
    },

    favoriteAll: flow(function* favoriteAll() {
      for (let item of self.items) {
        if (item.status === 'none') {
          yield put(`/telegram_logs/${item.id}/status`, {
            status: 'favorite'
          })
        }
      }
      self.list()
    }),
  }))

const TelegramLog = types
  .compose(
    BasicObject,
    TelegramLogBase,
  )

export default TelegramLog
