import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const BookBase = types
  .model({
    item: types.optional(types.frozen(), {}),
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/books', {
        page: self.currentPage,
        per: self.pagination.pageSize,
        status: self.currentStatus,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/books/${id}`, data).then((res) => {
        self.list()
      })
    },
  }))

const Book = types
  .compose(
    BasicObject,
    BookBase,
  )

export default Book
