import React from 'react'
import Simple from '../../../components/shared/ant/form/simple'

class Create extends React.Component {
  render() {
    return (
      <Simple
        id={this.props.match.params.id}
        keywords='page'
        slug='pages'
        parent={this.props}
        columns={'title content'}
        options={{
          content: {
            type: 'textarea',
            rows: 12,
            defaultValue: () => '',
          },
        }}
      />
    )
  }
}

export default Create
