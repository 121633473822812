import React from 'react'
import { observer } from 'mobx-react'
import Simple from '../../../components/shared/ant/form/simple'
import queryString from 'query-string'

class Create extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parsed: queryString.parse(this.props.location.search),
    }
  }

  getName = () => {
    if (!this.state.parsed.name) return ''
    return this.state.parsed.name.replace(/\(|\)/g, '').replace(/season[0-9 ]+/gi, '').trim()
  }

  getAlias = () => {
    if (!this.state.parsed.alias) return ''
    return this.state.parsed.alias.split(',').map((item) => item.replace(/第[一|二|三|四|五|六|七|八|九|十]+季/g, '').trim()).join('\n')
  }

  render() {
    return (
      <Simple
        keywords='collection'
        slug='admin/collections'
        id={this.props.match.params.id}
        parent={this.props}
        columns={'name alias'}
        options={{
          name: {
            defaultValue: this.getName,
          },
          alias: {
            type: 'textarea',
            as: 'array',
            defaultValue: this.getAlias,
          },
        }}
        params={{
        }}
      />
    )
  }
}

export default Create
