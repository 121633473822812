import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  CloseCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { Row, Col, Input, Table, Modal, message, Popconfirm, Button, Form, Select } from 'antd'
import L from 'leaflet'
import _ from 'lodash'
import coordtransform from 'coordtransform2'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import DateTime from '../../../components/shared/format/datetime'
import { baseMaps } from '../../../components/shared/thirdparty/map_config'
import Spin from '../../../components/shared/form/spin'
import styles from './view.module.css'
import util from '../../../utils'
import icons, { availableIcons } from '../../../utils/map_icon'
import { get } from '../../../utils/request'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      map: null,

      center: {},
      zoomLevel: 10,
      clicked: {},
      selectedMarkId: 0,

      visibleCreateMark: false,
      form_coordinates: '',
      form_name: '',
      form_link: '',
      form_comment: '',
      form_category: 'fav',
      form_id: null,
      form_data: '',
      form_duration: 1,

      visibleMarkList: false,
      markListKeywords: '',
      filteredMapMarks: null,

      visibleImportJSON: false,

      visibleImportMark: false,
      form_data_import: '',
      importMarks: [],
    }

    window.store.map.read(this.props.match.params.id)
  }

  onMapClick = (e) => {
    // alert("You clicked the map at " + e.latlng)
    // popup
    //   .setLatLng(e.latlng)
    //   .setContent("You clicked the map at " + e.latlng.toString())
    //   .openOn(this.state.map)
    this.setState({
      clicked: e.latlng
    })
  }

  onMapMoveEnd = (e) => {
    this.setState({
      center: this.state.map.getCenter(),
      zoomLevel: this.state.map.getZoom(),
    })
  }

  onClickEditMark = (markId) => {
    let item = _.filter(window.store.map.item.map_marks, { id: markId })[0]
    this.setState({
      visibleCreateMark: true,
      form_coordinates: `(${item.coordinates.x}, ${item.coordinates.y})`,
      form_name: item.name,
      form_duration: item.duration,
      form_link: item.link,
      form_photo: item.photo,
      form_comment: item.comment,
      form_category: item.category,
      form_id: markId,
      form_data : JSON.stringify(item),
    })
  }

  onClickEditSelectedMark = () => {
    if (this.state.selectedMarkId === 0) return
    this.onClickEditMark(this.state.selectedMarkId)
  }

  onSelectMark = (markId) => {
    this.setState({
      selectedMarkId: markId,
    })
  }

  onPopupOpen = (e) => {
    this.onSelectMark(parseInt(e.popup._content.split('|')[0].replace('<b>', '').replace(' ', '')))
  }

  onClickCleanUp = () => {
    localStorage.removeItem('import')
    this.setState({
      visibleImportMark: false,
    })
  }

  onMarkListSearch = (e) => {
    this.setState({
      markListKeywords: e.target.value,
      filteredMapMarks: e.target.value ? window.store.map.item.map_marks.filter((item) => item.name.includes(e.target.value)) : null,
    })
  }

  ff = (float) => Math.round(float*100)/100

  componentDidMount = () => {
    var map = L.map('mapid', {
      layers: [baseMaps.outdoors]
    })
    this.setState({
      map: map,
    })

    L.control.layers(baseMaps).addTo(map);

    let markers = L.markerClusterGroup({
      maxClusterRadius: 40,
    })
    let dependentMarkers = L.layerGroup()
    map.addLayer(dependentMarkers)
    this.setState({
      markers,
      dependentMarkers,
    })

    var overlayMaps = {
      markers,
      dependentMarkers,
    }
    L.control.layers(overlayMaps).addTo(map)

    map.on('click', this.onMapClick)
    map.on('moveend', this.onMapMoveEnd)
    map.on('popupopen', this.onPopupOpen)
  }

  componentDidUpdate = () => {
    if (!this.state.loaded && window.store.map.item.title) {
      if (window.store.map.item.data.length > 0) {
        this.state.map.setView(new L.LatLng(window.store.map.item.data[0].center[0], window.store.map.item.data[0].center[1]), window.store.map.item.data[0].zoom_level)
      } else {
        this.state.map.setView([35.364976, 138.729172], 10)
      }
      this.setState({
        loaded: true,
      })
    }
    if (window.store.map.item.map_marks && !window.store.mapMark.markers_loaded && this.state.markers) {
      if (this.state.markers) this.state.markers.clearLayers()
      if (this.state.dependentMarkers) this.state.dependentMarkers.clearLayers()
      for (let item of window.store.map.item.map_marks) {
        let options = { icon: icons[item.category] || icons.none }
        if (item.id === window.store.mapMark.recent_modified_mark_id) {
          if ('none' === item.category) options.icon = icons.gooH
        }
        let marker = L.marker([item.coordinates.x, item.coordinates.y], options)
        this.state.markers.addLayer(marker)
        this.state.dependentMarkers.addLayer(marker)
        let msg = `<b>${item.id} | ${item.name}` + (item.link ? ` <a href="${item.link}" target="_blank">🔗</a>` : '') + '</b>'
        if (item.duration) msg += `<br><br><b>Duration:</b> ${item.duration}h`
        if (item.photo) msg += `<br><br><a href="${util.bucket}${item.photo}" target="_blank"><img class="thumbnail" src="${util.bucket}${item.photo}" width="300px" /></a>`
        if (item.comment) msg += `<br><br><span class="description">${item.comment}</span>`
        marker.bindPopup(msg)
      }
      window.store.mapMark.modify({
        markers_loaded: true
      })
    }
  }

  onClickCreateMark = () => {
    if (!this.state.clicked.lat || !this.state.clicked.lng) return
    this.setState({
      visibleCreateMark: true,
      form_coordinates: `(${this.state.clicked.lat}, ${this.state.clicked.lng})`,
      form_name: '',
      form_duration: 1,
      form_link: '',
      form_photo: '',
      form_comment: '',
      form_category: 'fav',
      form_id: null,
      form_data : '',
    })
  }

  onClickFixCoordinates = () => {
    let i = this.state.form_coordinates.split(', ')
    let lat = parseFloat(i[0].replace('(', ''))
    let lon = parseFloat(i[1].replace(')', ''))
    let coo = coordtransform.gcj02towgs84(lat, lon)
    this.setState({
      form_coordinates: `(${coo[0]}, ${coo[1]})`,
    })
  }

  handleOk = () => {
    this.setState({
      visibleCreateMark: false,
    })
    if (this.state.form_id) {
      window.store.mapMark.update(
        this.state.form_id,
        this.props.match.params.id,
        {
          coordinates: this.state.form_coordinates,
          name: this.state.form_name,
          duration: this.state.form_duration,
          link: this.state.form_link,
          photo: this.state.form_photo,
          comment: this.state.form_comment,
          category: this.state.form_category,
        },
      )
    } else {
      window.store.mapMark.create({
        map_id: this.props.match.params.id,
        coordinates: this.state.form_coordinates,
        name: this.state.form_name,
        duration: this.state.form_duration,
        link: this.state.form_link,
        photo: this.state.form_photo,
        comment: this.state.form_comment,
        category: this.state.form_category,
      })
    }
  }

  handleCancel = () => this.setState({
    visibleCreateMark: false,
  })

  onClickMarkList = () => {
    this.setState({
      visibleMarkList: true,
    })
  }

  handleOkMarkList = () => {
    this.setState({
      visibleMarkList: false,
    })
  }

  handleCancelMarkList = () => this.setState({ visibleMarkList: false })

  onSaveMapData = () => {
    window.store.map.update(
      this.props.match.params.id,
      null,
      {
        data: [
          {
            center: [this.state.center.lat, this.state.center.lng],
            zoom_level: this.state.zoomLevel
          }
        ],
      }
    )
    message.success('Map updated!');
  }

  onDeleteItem = (item) => {
    window.store.mapMark.delete(item.id, this.props.match.params.id)
  }

  loadImportMarks = () => {
    let data = localStorage.getItem('import')
    if (!data) return []
    return JSON.parse(data).reverse()
  }

  onClickImportBookmark = (item) => {
    this.setState({
      visibleCreateMark: true,
      form_coordinates: `(${item.lat}, ${item.lon})`,
      form_name: item.name,
      form_link: '',
      form_photo: '',
      form_comment: '',
      form_category: 'fav',
      form_id: null,
      form_data : '',
    })
  }

  onClickAutoFillLatestUpload = async () => {
    const res = await get('/uploads')
    this.setState({ form_photo: `uploads/${res.data.items[0].source_uri}` })
  }

  onClickImportJSON = () => {
    this.setState({
      visibleImportJSON: true,
    })
  }

  onClickImportMark = () => {
    this.setState({
      importMarks: this.loadImportMarks(),
      visibleImportMark: true,
    })
  }

  handleOkImportJSON = () => {
    this.setState({
      visibleImportJSON: false,
    })
    let item = JSON.parse(this.state.form_data_import)
    this.setState({
      visibleCreateMark: true,
      form_coordinates: `(${item.coordinates.x}, ${item.coordinates.y})`,
      form_name: item.name,
      form_duration: item.duration,
      form_link: item.link,
      form_photo: item.photo,
      form_comment: item.comment,
      form_category: item.category,
      form_id: null,
      form_data : '',
    })
  }

  handleCancelImportJSON = () => this.setState({ visibleImportJSON: false })

  handleOkImportMark = () => this.setState({ visibleImportMark: false })

  handleCancelImportMark = () => this.setState({ visibleImportMark: false })

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Coordinates',
        dataIndex: 'coordinates',
        key: 'coordinates',
        render: value => <span>{`${value.x}, ${value.y}`}</span>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value}></DateTime>,
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          <span
            className={styles.clickableIconWrapper}
            onClick={() => this.onClickEditMark(item.id)} >
            <EditOutlined className={styles.icon} />
          </span>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => this.onDeleteItem(item)}
            okText="Yes"
            cancelText="No" >
            <span className={styles.clickableIconWrapper}>
              <CloseCircleOutlined className={styles.icon} />
            </span>
          </Popconfirm>
        </span>,
      },
    ]

    const columnsImportMark = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Lat',
        dataIndex: 'lat',
        key: 'lat',
      },
      {
        title: 'Lon',
        dataIndex: 'lon',
        key: 'lon',
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          <span
            className={styles.clickableIconWrapper}
            onClick={() => this.onClickImportBookmark(item)}>
            <PlusCircleOutlined className={styles.icon} />
          </span>
        </span>,
      },
    ]

    return (
      <Row>
        <Col span={24}>
          <div className={styles.map}>
            <div id="mapid" className={styles.mapid}></div>
            <div className={styles.debug}>
              <span>{window.store.map.item.title}</span>
              <span> | </span>
              <span onClick={this.onSaveMapData}>Center: {this.ff(this.state.center.lat)}, {this.ff(this.state.center.lng)}</span>
              <span> | </span>
              Zoom level: {this.state.zoomLevel}
              <span> | </span>
              <span onClick={this.onClickCreateMark}>Click: {this.ff(this.state.clicked.lat)}, {this.ff(this.state.clicked.lng)}</span>
              <span> | </span>
              <span onClick={this.onClickEditSelectedMark}>Selected mark: {this.state.selectedMarkId}</span>
              <span> | </span>
              <span onClick={this.onClickMarkList}>Mark list</span>
              <span> | </span>
              <span onClick={this.onClickImportJSON}>Import JSON</span>
              <span> | </span>
              <span onClick={this.onClickImportMark}>Import bookmark</span>
              <span> | </span>
              <Link to={`/map_plans/${window.store.map.item.id}`}>Plan</Link>
              <span> | </span>
              <span><a href={`https://zxc.im`}>Shared link</a></span>
            </div>
          </div>
          <Spin loading={window.store.map.loading} />

          <Modal
            title="Mark list"
            visible={this.state.visibleMarkList}
            width={1280}
            style={{ top: 30 }}
            zIndex={1000}
            onOk={this.handleOkMarkList}
            onCancel={this.handleCancelMarkList}>
            <Input
              value={this.state.markListKeywords}
              onChange={this.onMarkListSearch}
              placeholder='Search by Name'
              style={{ marginBottom: 12 }} />
            <Table
              columns={columns}
              dataSource={this.state.filteredMapMarks || window.store.map.item.map_marks}
              rowKey='id'
            />
          </Modal>

          <Modal
            title="Mark"
            visible={this.state.visibleCreateMark}
            width={680}
            zIndex={1001}
            onOk={this.handleOk}
            onCancel={this.handleCancel}>
            <Form>
              <Form.Item label="Coordinates">
                <Input
                  value={this.state.form_coordinates}
                  addonAfter={
                    <span onClick={() => this.onClickFixCoordinates()}>Fix</span>
                  }
                  onChange={(e) => {
                    this.setState({ form_coordinates: e.target.value })
                  }}
                />
              </Form.Item>
              <Form.Item label="Name">
                <Input
                  value={this.state.form_name}
                  onChange={(e) => {
                    this.setState({ form_name: e.target.value })
                  }}
                />
              </Form.Item>
              <Form.Item label="Category">
                {availableIcons.map((item) => {
                  return <span
                    key={item}
                    className={[
                      styles.icon_demo,
                      this.state.form_category === item ? styles.icon_selected : ''
                    ].join(' ')}
                    onClick={(e) => this.setState({ form_category: item })}
                  >
                    {icons[item].options.iconUrl && <img
                      src={icons[item].options.iconUrl}
                      style={{
                        width: icons[item].options.iconSize[0],
                        height: icons[item].options.iconSize[1],
                      }}
                    />}
                    {!icons[item].options.iconUrl && <span
                      className={[
                        styles.icon_inurl,
                        `leaflet-icon-${item}`,
                      ].join(' ')}
                      style={{
                        width: icons[item].options.iconSize[0],
                        height: icons[item].options.iconSize[1],
                      }}
                    ></span>}
                  </span>
                })}
              </Form.Item>
              <Form.Item label="Duration">
                <Input
                  value={this.state.form_duration}
                  addonAfter="hrs"
                  onChange={(e) => {
                    this.setState({ form_duration: e.target.value })
                  }}
                />
              </Form.Item>
              <Form.Item label="Link">
                <Input
                  value={this.state.form_link}
                  onChange={(e) => {
                    this.setState({ form_link: e.target.value })
                  }}
                />
              </Form.Item>
              <Form.Item label="Photo URL">
                <Input
                  suffix={
                    <span>
                      <a href={util.getSlayerFrontendURI() + 'uploads/create?category=maps&data=' + window.btoa(encodeURIComponent(window.location.origin + '/#/maps/' + window.store.map.item.id + `#coordinates=${this.state.form_coordinates}&name=${this.state.form_name}`))} target='_blank' rel="noopener noreferrer">
                        <UploadOutlined />
                      </a>
                      <PlusCircleOutlined style={{ marginLeft: 10 }} onClick={this.onClickAutoFillLatestUpload} />
                    </span>
                  }
                  value={this.state.form_photo}
                  onChange={(e) => {
                    this.setState({ form_photo: e.target.value })
                  }}
                />
              </Form.Item>
              <Form.Item label="Comment">
                <Input.TextArea
                  rows={2}
                  value={this.state.form_comment}
                  onChange={(e) => {
                    this.setState({ form_comment: e.target.value })
                  }}
                />
              </Form.Item>
              <Form.Item label="Export mark data">
                <Input value={this.state.form_data} disabled={true} />
              </Form.Item>
            </Form>
          </Modal>

          <Modal title="Import JSON"
            visible={this.state.visibleImportJSON}
            width={1280}
            onOk={this.handleOkImportJSON}
            onCancel={this.handleCancelImportJSON}>
            <Form>
              <Form.Item label="JSON">
                <Input.TextArea
                  rows={5}
                  value={this.state.form_data_import}
                  onChange={(e) => {
                    this.setState({ form_data_import: e.target.value })
                  }}
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal title="Import mark"
            visible={this.state.visibleImportMark}
            width={1280}
            onOk={this.handleOkImportMark}
            onCancel={this.handleCancelImportMark}>
            <Table
              columns={columnsImportMark}
              dataSource={this.state.importMarks}
              rowKey='name'
            />
            <Button type="primary" style={{ marginTop: 12 }} onClick={() => this.onClickCleanUp()}>Clean up</Button>
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default observer(View)
