import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  CloseCircleOutlined,
} from '@ant-design/icons'
import { Row, Col, Table, Input, Button } from 'antd'
import util from '../../../utils'
import FilterBar from '../../../components/shared/filter_bar'
import Status from '../../../components/shared/format/status'
import DateTime from '../../../components/shared/format/datetime'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keywords: '',
    }

    util.setTitle('Chart')
    window.store.chart.list()
  }

  handleOnChange = (pagination) => {
    window.store.chart.modify({
      currentPage: pagination.current,
    })
    window.store.chart.list()
  }

  handleSubmit = () => {
    window.store.chart.modify({
      currentPage: 1,
      currentKeywords: this.state.keywords,
    })
    window.store.chart.list()
  }

  emitEmpty = () => {
    this.setState({ keywords: '' })
    window.store.chart.modify({
      currentPage: 1,
      currentKeywords: '',
    })
    window.store.chart.list()
  }

  onChangeKeywords = (e) => {
    this.setState({ keywords: e.target.value })
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (value, item) => <Link to={`/charts/${item.id}`}>{value}</Link>
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => <DateTime text={value} type="sinceThin"></DateTime>,
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item) => <span>
          <span style={{ marginRight: 8 }}>
            <Link to={`/charts/edit/${item.id}`}>
              <Button type="dashed" size="small">Edit</Button>
            </Link>
          </span>
          <Link to={`/charts/${item.id}`}>
            <Button type="dashed" size="small">View</Button>
          </Link>
        </span>,
      },
    ]

    const filterRight = <span>
      <span className="inlineFilter">
        <Input
          suffix={<CloseCircleOutlined onClick={this.emitEmpty} />}
          onChange={this.onChangeKeywords}
          onPressEnter={this.handleSubmit}
          value={this.state.keywords} />
        <Button type="primary" onClick={this.handleSubmit}>Filter</Button>
      </span>
    </span>

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterRight={filterRight} />
          <Table
            size="small"
            columns={columns}
            dataSource={window.store.chart.items}
            pagination={window.store.chart.pagination}
            onChange={this.handleOnChange} />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
