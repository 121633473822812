import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Row, Col, Input, Form, Descriptions, Badge, PageHeader, Card, Radio, Modal } from 'antd'
import DateTime from '../../../components/shared/format/datetime'
import Episodes from './components/episodes'
import styles from './view.module.sass'
import util from '../../../utils'

function Link3party(props) {
  if (!props.id) return '-'

  return (
    <span>
      <a href={props.prefix + props.id} target='_blank' rel="noopener noreferrer">
        {props.id} {props.score &&
          <Badge count={props.score} style={{ backgroundColor: '#52c41a' }} overflowCount={99999} />
        }
      </a>
    </span>
  )
}

const success = (bool) => bool ? 'success' : 'error'
const counter = { textDecoration: 'underline' }
const score = { textDecoration: 'overline' }

class View extends React.Component {
  epBatchformRef = React.createRef()
  objDeletionformRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      operation: null,
      delete_subject: false,
      bulk_create: false,
    }

    this.main = window.store.obj
    this.main.read(this.props.match.params.id)
  }

  handleClickDelete = () => {
    this.setState({
      delete_subject: false,
    })
    this.main.delete(
      this.props.match.params.id,
      {
        duplication_id: this.objDeletionformRef.current.getFieldsValue().duplication_id
      },
    )
  }

  handleClickBatchCreateEpisodes = () => {
    this.setState({
      bulk_create: false,
    })
    this.main.batchCreateEpisode(this.props.match.params.id, {
      amount: this.epBatchformRef.current.getFieldsValue().amount
    })
  }

  handleSubjectOperation = (e) => {
    return {
      delete_subject: () => this.setState({ delete_subject: true }),
      bulk_create: () => this.setState({ bulk_create: true }),
    }[e.target.value]()
  }

  render() {
    return (
      <Row>
        <Col span={24}>
          {this.main.item.obj &&
            <div className={styles.item}>
              <PageHeader
                className="site-page-header"
                onBack={() => null}
                title={this.main.item.obj.name}
                subTitle={this.main.item.obj.alias ? this.main.item.obj.alias.join(', ') : ''}
              />
              <Card title="Subject" extra={
                <Radio.Group value={this.state.operation} onChange={this.handleSubjectOperation}>
                  <Radio.Button value="large"><Link to={`/objs/edit/${this.main.item.obj.id}`}>Edit</Link></Radio.Button>
                  <Radio.Button value="default"><Link to={`/objs/create?oid=${this.main.item.obj.id}`}>Season Generator</Link></Radio.Button>
                  <Radio.Button value="delete_subject">Delete</Radio.Button>
                </Radio.Group>
              }>
                <Descriptions
                  bordered
                  layout="vertical"
                  size="small"
                  column={8}>
                  <Descriptions.Item label="ID">
                    <a href={`${util.surface()}#/objs/${this.main.item.obj.id}`} target="_blank">
                      {this.main.item.obj.id}
                    </a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Collection">
                    {this.main.item.obj.collection_id &&
                      <Link to={`/collections/${this.main.item.obj.collection_id}`}>
                        {this.main.item.obj.collection_id}
                      </Link>
                    }
                    {!this.main.item.obj.collection_id &&
                      <Link to={`/collections/create?name=${this.main.item.obj.name}&alias=${this.main.item.obj.alias.join(',')}`}>
                        Create
                      </Link>
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Carrier">
                    {this.main.item.obj.carrier}
                  </Descriptions.Item>
                  <Descriptions.Item label="Category">
                    {this.main.item.obj.category}
                  </Descriptions.Item>
                  <Descriptions.Item label="Rate">
                    {this.main.item.obj.rate}
                  </Descriptions.Item>
                  <Descriptions.Item label="Rate bio">
                    {JSON.stringify(this.main.item.obj.rate_bio)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Released at">
                    <DateTime type="date" text={this.main.item.obj.released_at}></DateTime>
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <Badge status={success(this.main.item.obj.status !== 'deleted')} />
                    {this.main.item.obj.status}
                  </Descriptions.Item>
                  <Descriptions.Item label="Count Episode" labelStyle={counter}>
                    {this.main.item.obj.count_ep}
                  </Descriptions.Item>
                  <Descriptions.Item label="Count SP" labelStyle={counter}>
                    {this.main.item.obj.count_sp}
                  </Descriptions.Item>
                  <Descriptions.Item label="Count Volume" labelStyle={counter}>
                    {this.main.item.obj.count_volume}
                  </Descriptions.Item>
                  <Descriptions.Item label="Count fav" labelStyle={counter}>
                    {this.main.item.obj.count_fav}
                  </Descriptions.Item>
                  <Descriptions.Item label="Count comment" labelStyle={counter}>
                    {this.main.item.obj.count_comment}
                  </Descriptions.Item>
                  <Descriptions.Item label="MyAnimeList" labelStyle={score}>
                    <Link3party prefix='https://myanimelist.net/anime/' id={this.main.item.obj.mal_id} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Collection relation">
                    {this.main.item.obj.collection_relation || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duplication of">
                    {this.main.item.obj.duplication_id || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="BGM" labelStyle={score}>
                    <Link3party prefix='https://bgm.tv/subject/' id={this.main.item.obj.bgm_id} score={this.main.item.obj.bgm_score} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Douban" labelStyle={score}>
                    <Link3party prefix='https://movie.douban.com/subject/' id={this.main.item.obj.douban_id} score={this.main.item.obj.douban_score} />
                  </Descriptions.Item>
                  <Descriptions.Item label="IMDB" labelStyle={score}>
                    <Link3party prefix='https://www.imdb.com/title/' id={this.main.item.obj.imdb_id} />
                  </Descriptions.Item>
                  <Descriptions.Item label="ISBN" labelStyle={score}>
                    {this.main.item.obj.isbn}
                  </Descriptions.Item>
                  <Descriptions.Item label="Anidb" labelStyle={score}>
                    <Link3party prefix='https://anidb.net/anime/' id={this.main.item.obj.anidb_id} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Steam" labelStyle={score}>
                    <Link3party prefix='https://store.steampowered.com/app/' id={this.main.item.obj.steam_id} score={this.main.item.obj.steam_score} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Created at">
                    <DateTime type="date" text={this.main.item.obj.created_at}></DateTime>
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated at">
                    <DateTime type="date" text={this.main.item.obj.updated_at}></DateTime>
                  </Descriptions.Item>
                  <Descriptions.Item label="" span={3}>
                  </Descriptions.Item>
                  <Descriptions.Item label="Cover" span={8}>
                    {this.main.item.obj.cover || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Bio Long" span={8}>
                    {this.main.item.obj.bio_long || '-'}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Card title="Episodes" extra={
                <Radio.Group value={this.state.operation} onChange={this.handleSubjectOperation}>
                  <Radio.Button value=""><Link to={`/object_episodes/create?obj_id=${this.main.item.obj.id}`}>Add</Link></Radio.Button>
                  <Radio.Button value="bulk_create">Bulk Create</Radio.Button>
                  <Radio.Button value=""><Link to={`/object_episodes/bulk?oid=${this.main.item.obj.id}`}>Bulk Edit</Link></Radio.Button>
                </Radio.Group>
              }>
                <Episodes data={this.main.item.obj} />
              </Card>
              <Modal title="Delete Subject" visible={this.state.delete_subject} onOk={this.handleClickDelete} onCancel={() => this.setState({
                delete_subject: false,
              })} okText="Delete" cancelText="Cancel">
                <Form layout="inline" ref={this.objDeletionformRef}>
                  <Form.Item
                    name="duplication_id"
                    validateStatus={this.main.errors.duplication_id ? "error" : ""}
                    label="Duplication of:"
                    help={this.main.errors.duplication_id}>
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
              <Modal title="Batch Create" visible={this.state.bulk_create} onOk={this.handleClickBatchCreateEpisodes} onCancel={() => this.setState({
                bulk_create: false,
              })} okText="Create" cancelText="Cancel">
                <Form layout="inline" ref={this.epBatchformRef}>
                  <Form.Item
                    name="amount"
                    validateStatus={this.main.errors.amount ? "error" : ""}
                    label="Episode Amount:"
                    help={this.main.errors.amount}>
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          }
        </Col>
      </Row>
    )
  }
}

export default observer(View)
