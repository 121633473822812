import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Table, Button, Popconfirm } from 'antd'
import Date from '../../../../components/shared/format/date'

class Episodes extends React.Component {
  handleRemove = (id) => {
    window.store.objectEpisode.delete(id, this.props.data.id)
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: value => <Link target='_blank' to={`/object_episodes/edit/${value}`} rel="noopener noreferrer">{value}</Link>,
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        filterMultiple: false,
        filters: [
          {
            text: 'Special',
            value: 'special',
          },
          {
            text: 'None',
            value: 'none',
          },
        ],
        onFilter: (value, record) => record.category === value,
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => a.key - b.key,
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
        render: (text, record) => (
          <span>{text}</span>
        )
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Alias',
        dataIndex: 'alias',
        key: 'alias',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
      },
      {
        title: 'Released at',
        dataIndex: 'released_at',
        key: 'released_at',
        render: value => <Date text={value}></Date>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="dashed" shape="circle" icon={<PlusOutlined />} size="small" />
            &nbsp;
            {record.status !== 'deleted' &&
              <Popconfirm title="Are you sure?" onConfirm={() => this.handleRemove(record.id)} okText="Yes" cancelText="No">
                <Button type="dashed" shape="circle" icon={<MinusOutlined />} size="small" />
              </Popconfirm>
            }
          </span>
        )
      },
    ]

    return (
      <Table columns={columns} dataSource={this.props.data.raw_object_episodes} />
    )
  }
}

Episodes.propTypes = {
  data: PropTypes.object,
}

export default Episodes
