import React from 'react'
import {
  CloseCircleOutlined,
  CloseCircleFilled,
  HeartOutlined,
  HeartFilled,
} from '@ant-design/icons'
import Simple from '../../../components/shared/ant/table/simple'
import ExternalLinks from '../../../components/shared/format/external_links'
import DateTime from '../../../components/shared/format/datetime'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='shopping item'
        store={this.props.history.store}
        columns='uuid title price quantity url snap category status happened_at created_at control'
        control={{
          status: {
            dontBuy: {
              outlined: <CloseCircleOutlined />,
              filled: <CloseCircleFilled />,
            },
            daily: {
              outlined: <HeartOutlined />,
              filled: <HeartFilled />,
            },
          }
        }}
        options={{
          size: 'small',
          title: (value, item) => {
            if (item.status === 'daily') {
              return (
                <span className="highlightPriorityHigh">
                  {value}
                </span>
              )
            }
            if (item.status === 'dontBuy') {
              return (
                <span style={{ opacity: 0.2, textDecoration: 'line-through' }}>
                  {value}
                </span>
              )
            }
            return value
          },
          price: value => <span>{value / 100}</span>,
          url: (value, item) => <ExternalLinks list={[item.meta.url]} prefix='https:'></ExternalLinks>,
          snap: (value, item) => <ExternalLinks list={[item.meta.url_snap]} prefix='https:'></ExternalLinks>,
          happened_at: value => <DateTime type='date' text={value}></DateTime>,
          created_at: 'sinceThin',
          categories: {
            currentCategory: 'jddj jd taobao steam steam_market ios',
            currentOrderBy: 'price happened_at',
          },
        }}
      />
    )
  }
}

export default Index
