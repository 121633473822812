import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const BookmarkBase = types
  .model({
  })
  .actions(self => ({
    create(payload) {
      self.loading = true
      post('/bookmarks', payload).then(({ data, error }) => {
        if (self.checkError(error)) return
        if (data.duplicated) {
          window.location.href = `/#/bookmarks/${data.id}`
          return
        }
        window.location.href = '/#/bookmarks'
      })
    },

    list() {
      self.loading = true
      get('/bookmarks', {
        page: self.currentPage,
        status: self.currentStatus,
        key: self.currentKey,
        keywords: self.currentKeywords,
      }).then(self.setData)
    },

    read(id) {
      get('/bookmarks/' + id).then(self.setItemData)
    },

    update(id, index, data) {
      self.loading = true
      put(`/bookmarks/${id}`, data).then(({ error }) => {
        if (error) return
        if (index !== null && index !== undefined) {
          self.list()
        } else {
          window.location.href = '/#/bookmarks'
        }
      })
    },
  }))

const Bookmark = types
  .compose(
    BasicObject,
    BookmarkBase,
  )

export default Bookmark
