import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import Length from '../ant/suffix/length'

class LengthLimitedInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      titleLength: 0,
      prevValue: '',
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && state.prevValue !== props.value) {
      return {
        prevValue: props.value,
        titleLength: props.value.length,
      }
    }
    return null
  }

  onChangeTitle = (e) => {
    this.setState({ titleLength: e.target.value.length })
    if (this.props.onChange) this.props.onChange(e)
  }

  render() {
    if (this.props.value) {
      return <Input onChange={this.onChangeTitle} suffix={<Length current={this.state.titleLength} max={this.props.max} />} value={this.props.value} />
    }

    return <Input onChange={this.onChangeTitle} suffix={<Length current={this.state.titleLength} max={this.props.max} />} />
  }
}

LengthLimitedInput.propTypes = {
  max: PropTypes.number.isRequired,
  onChange: PropTypes.any,
  value: PropTypes.any,
}

export default LengthLimitedInput
