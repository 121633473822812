import { types } from 'mobx-state-tree'
import { post, get, put } from '../../utils/request'
import BasicObject from '../basic/base'

const WarehouseChangelogBase = types
  .model({
    currentTag: '',
    currentRisk: '',
  })
  .actions(self => ({
    list() {
      self.loading = true
      get('/warehouse_changelogs', {
        page: self.currentPage,
        per: self.pagination.pageSize,
      }).then(self.setData)
    },
  }))

const WarehouseChangelog = types
  .compose(
    BasicObject,
    WarehouseChangelogBase,
  )

export default WarehouseChangelog
