import React from 'react'
import PropTypes from 'prop-types'

class TodoText extends React.Component {
  render() {
    if (this.props.status === 'working') {
      return (
        <span className={`working ${this.props.priority}`}>
          {this.props.text}
        </span>
      )
    }
    if (this.props.status === 'done') {
      return (
        <span style={{ opacity: 0.2 }}>
          {this.props.text}
        </span>
      )
    }
    if (this.props.status === 'cancelled') {
      return (
        <span style={{ opacity: 0.2, textDecoration: 'line-through' }}>
          {this.props.text}
        </span>
      )
    }
    if (this.props.priority === 'high') {
      return (
        <span style={{ background: '#e6f7ff', color: '#1890ff' }}>
          {this.props.text}
        </span>
      )
    }
    return this.props.text
  }
}

TodoText.propTypes = {
  priority: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default TodoText
