import React from 'react'
import { Row, Col, Card } from 'antd'

class Index extends React.Component {
  render() {
    return (
      <Row>
        <Col span={24}>
          <Card title="System">
            <span>Non-admin is inaccessible.</span>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Index
