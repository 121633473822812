import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  CloseCircleOutlined,
  RightCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from '@ant-design/icons'
import {
  Row,
  Col,
  Table,
  Input,
  Button,
} from 'antd'
import util from '../../../utils'
import FilterBar from '../../../components/shared/filter_bar'
import Filter from '../../../components/shared/ant/bar/filter'
import Status from '../../../components/shared/format/status'
import Spin from '../../../components/shared/form/spin'
import Indicator from './components/indicator'
import NextBillOrTerminate from './components/next_bill_or_terminate'
import SubscriptionText from './components/subscription_text'

const styles = {
  clickableIconWrapper: {
    cursor: 'pointer',
    padding: '10px 5px 10px 0',
  },
  icon: {
    marginRight: '4px',
  },
}
const Buttons = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Valid',
    value: 'valid',
  },
  {
    text: 'Periodic',
    value: 'periodic',
  },
  {
    text: 'Ignorable',
    value: 'ignorable',
  },
  {
    text: 'Terminated',
    value: 'terminated',
  },
  {
    text: 'Expired',
    value: 'expired',
  },
]

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keywords: '',
    }

    util.setTitle('Subscription')
    window.store.subscription.list()
  }

  handleOnChange = (pagination) => {
    window.store.subscription.modify({
      currentPage: pagination.current,
    })
    window.store.subscription.list()
  }

  onModifyItemStatus = (item, value, index) => {
    window.store.subscription.update(item.id, { status: value }, index)
  }

  onModifyItemPriority = (item, value, index) => {
    window.store.subscription.update(item.id, { priority: value }, index)
  }

  onDeleteItem = (item) => window.store.subscription.update(item.id)

  onClickSwitcher = (e) => {
    window.store.subscription.modify({
      currentPage: 1,
      currentGroup: e.target.value,
    })
    window.store.subscription.list()
  }

  handleSubmit = () => {
    window.store.subscription.modify({
      currentPage: 1,
      currentKeywords: this.state.keywords,
    })
    window.store.subscription.list()
  }

  emitEmpty = () => {
    this.setState({ keywords: '' })
    window.store.subscription.modify({
      currentPage: 1,
      currentKeywords: '',
    })
    window.store.subscription.list()
  }

  onChangeKeywords = (e) => {
    this.setState({ keywords: e.target.value })
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value, item) => <SubscriptionText text={item.name} expireDate={item.start_or_end_at} category={item.category} status={item.status} />,
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        key: 'desc',
        render: (value, item) => <SubscriptionText text={item.desc} expireDate={item.start_or_end_at} category={item.category} status={item.status} />,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, item) => <SubscriptionText text={`${item.amount} ${item.currency.toUpperCase()}`} expireDate={item.start_or_end_at} category={item.category} status={item.status} />,
      },
      {
        title: 'Next bill',
        dataIndex: 'next_bill_at',
        key: 'next_bill_at',
        render: (value, item) => <SubscriptionText text={<NextBillOrTerminate type="cycle" item={item} />} expireDate={item.start_or_end_at} category={item.category} status={item.status} />
      },
      {
        title: 'Terminate',
        dataIndex: 'terminate_at',
        key: 'terminate_at',
        render: (value, item) => <NextBillOrTerminate type="onetime" item={item} />
      },
      {
        title: 'Indicator',
        dataIndex: 'indicator',
        key: 'indicator',
        render: (value, item) => <Indicator type="onetime" item={item} />
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => <Status type="status" text={value}></Status>,
      },
      {
        title: 'Control',
        key: 'control',
        render: (value, item, index) => <span>
          <span style={{ marginRight: 8 }}>
            <Link to={`/subscriptions/edit/${item.id}`}>
              <Button type="dashed" size="small">Edit</Button>
            </Link>
          </span>
          {item.status !== 'terminated' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'terminated', index)}>
              <CloseCircleOutlined style={styles.icon} />
            </span>
          }
          {item.status === 'terminated' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'none', index)}>
              <CloseCircleFilled style={styles.icon} />
            </span>
          }
          {item.status !== 'ignorable' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'ignorable', index)}>
              <RightCircleOutlined style={styles.icon} />
            </span>
          }
          {item.status === 'ignorable' &&
            <span style={styles.clickableIconWrapper}
              onClick={() => this.onModifyItemStatus(item, 'none', index)}>
              <RightCircleFilled style={styles.icon} />
            </span>
          }
        </span>,
      },
    ]

    const filterLeft = (
      <Link to={`/subscriptions/create`}>
        <Button type="dashed">New</Button>
      </Link>
    )

    const filterRight = <span>
      <Filter value={Buttons} click={this.onClickSwitcher} default={window.store.subscription.currentGroup}></Filter>
      <span className="inlineFilter">
        <Input
          suffix={<CloseCircleOutlined onClick={this.emitEmpty} />}
          onChange={this.onChangeKeywords}
          onPressEnter={this.handleSubmit}
          value={this.state.keywords} />
        <Button type="primary" onClick={this.handleSubmit}>Filter</Button>
      </span>
    </span>

    return (
      <Row>
        <Col span={24}>
          <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
          <Table
            size="small"
            columns={columns}
            dataSource={window.store.subscription.items}
            pagination={window.store.subscription.pagination}
            rowKey='id'
            onChange={this.handleOnChange} />
          <Spin loading={window.store.subscription.loading} />
        </Col>
      </Row>
    )
  }
}

export default observer(Index)
