import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Input, Button, Form, Upload, message, Tag } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { put } from '../../../utils/request'
import queryString from 'query-string'

class Create extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
    }

    if (this.props.match.params.id) {
      window.store.upload.reupload(this.props.match.params.id)
    }
  }

  componentDidMount = () => {
    let parsed = queryString.parse(this.props.location.search)
    if (parsed.category) {
      this.formRef.current.setFieldsValue({
        category: parsed.category,
      })
    }
    if (parsed.data) {
      let data = decodeURIComponent(window.atob(parsed.data.replace('|', '=')))
      this.formRef.current.setFieldsValue({
        ref: data,
      })
    }
  }

  handleSubmit = values => {
    window.store.upload.create(values)
  }

  onClickTag = item => {
    this.formRef.current.setFieldsValue({
      category: item
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
    }
    const props = {
      name: 'file',
      multiple: false,
      customRequest({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
      }) {
        let contentType = 'image/jpeg'
        if (/\.png$/.test(file.name)) contentType = 'image/png'
        else if (/\.svg$/.test(file.name)) contentType = 'image/svg+xml'

        fetch(window.store.upload.presignedURL, {
          method: 'put',
          headers: {
            'content-type': contentType,
            ...headers,
          },
          body: file,
        })
        .then((data) => {
          onSuccess(data, file)
        })
        .catch(onError)

        // onUploadProgress: ({ total, loaded }) => {
        //   onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        // },

        return {
          abort() {
            console.log('upload progress is aborted.');
          },
        }
      },
      async onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
          let res = (await put('/uploads/' + window.store.upload.precreatedItemID, {
            status: 'uploaded',
            source_uri: await info.file.response.text(),
          })).data
          if (res.status === 'success') {
            const id = window.store.upload.precreatedItemID
            message.success(`ID: ${id} saved successfully.`);
            window.store.upload.onCreate({
              data: {
                id: 0,
                presigned: '',
              }
            })
            window.location.href = `/#/uploads/${id}/view`
          }
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    }

    if (window.store.upload.presignedURL) {
      return (
        <Row>
          <Col span={24}>
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Upload.Dragger>
          </Col>
        </Row>
      )
    }

    return (
      <Row>
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="horizontal"
            onFinish={this.handleSubmit}
            style={{ marginTop: 24 }}>
            <Form.Item {...formItemLayout}
              label="Ref:"
              name="ref"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.upload.errors.ref ? "error" : ""}
              help={window.store.upload.errors.ref}>
              <Input />
            </Form.Item>

            <Form.Item {...formItemLayout}
              label="Category:"
              name="category"
              rules={[{ whitespace: true }]}
              validateStatus={window.store.upload.errors.category ? "error" : ""}
              extra={
                <div style={{ marginTop: window.store.upload.errors.category ? -2 : 8 }}>
                  {['icon', 'maps', 'wiki', 'blog', 'photo'].map(item => <Tag key={item} onClick={() => this.onClickTag(item)}>{item}</Tag>)}
                </div>
              }
              help={window.store.upload.errors.category}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 3 }}>
              <Button type="primary" htmlType="submit" loading={window.store.upload.loading}>Save</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default observer(Create)
