import React from 'react'
import { observer } from 'mobx-react'
import { LockOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons'
import { Input, Button, Form, Select } from 'antd'
import LoginBox from '../../components/shared/ant/form/login_box'
import { post } from '../../utils/request'
import utils from '../../utils'

class Login extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      captcha: this.getCaptchaURI(),
    }
  }

  getCaptchaURI = () => {
    let api = utils.API
    if (this.formRef.current) {
      const values = this.formRef.current.getFieldsValue()
      if (values.site) {
        api = utils[values.site]
      }
    }
    return `${api}/captcha?uuid=${window.store.user.uuid}&ts=${Date.now()}`
  }

  handleSubmit = values => {
    if (window.store.user.loading) return

    post(`${values.site ? utils[values.site] : ''}/users/login`, {
      ...values,
      uuid: window.store.user.uuid,
    }).then(({data}) => {
      localStorage.setItem(`token${values.site ? values.site : ''}`, data.token)
      localStorage.setItem('user_id', data.id)
      localStorage.setItem('nickname', data.nickname)
      localStorage.setItem('level', data.level)
      window.location.href = '/'
    })
  }

  reloadCaptcha = () => {
    this.setState({
      captcha: this.getCaptchaURI(),
    })
  }

  componentDidMount = () => {
    if (window.debug.name === 'debug') this.formRef.current.setFieldsValue({
      email: 'test@test.com',
      password: 'test@test.com'
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }

    return (
      <LoginBox>
        <Form ref={this.formRef} layout="horizontal" onFinish={this.handleSubmit}>
          <Form.Item {...formItemLayout}
            name="site"
            rules={[{ validator: this.passCheck, }]}
            initialValue=""
            label="Site:">
            <Select style={{ width: '100%' }} placeholder="Please select" onChange={this.reloadCaptcha}>
              <Select.Option key="">Default</Select.Option>
              <Select.Option key="MT">Moetrack</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item {...formItemLayout}
            label="Email:"
            name="email"
            rules={[{ required: true }]}
            validateStatus={window.store.user.errors.email ? "error" : ""}
            help={window.store.user.errors.email}>
            <Input addonBefore={<UserOutlined />} type="email" name="email" />
          </Form.Item>

          <Form.Item {...formItemLayout}
            label="Password:"
            name="password"
            rules={[{ required: true }]}
            validateStatus={window.store.user.errors.password ? "error" : ""}
            help={window.store.user.errors.password}>
            <Input addonBefore={<LockOutlined />} type="password" name="password" />
          </Form.Item>

          <Form.Item {...formItemLayout}
            label="Captcha:"
            name="_rucaptcha"
            rules={[{ required: true }]}
            validateStatus={window.store.user.errors._rucaptcha ? "error" : ""}
            help={window.store.user.errors._rucaptcha}>
            <Input addonBefore={<SyncOutlined onClick={this.reloadCaptcha} />} type="text" name="_rucaptcha" />
          </Form.Item>

          <Form.Item {...formItemLayout}
            label="_">
            <img alt='captcha' src={this.state.captcha} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
            <Button type="primary" htmlType="submit" loading={window.store.user.loading}>Submit</Button>
          </Form.Item>
        </Form>
      </LoginBox>
    )
  }
}

export default observer(Login)
