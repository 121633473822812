import React from 'react'
import { useHistory } from "react-router-dom";
import { Row, Col } from 'antd'

function Logout() {
  let history = useHistory()

  React.useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('nickname')
    localStorage.removeItem('level')
    localStorage.removeItem('tokenMT')
    history.push('/login')
  })

  return (
    <Row>
      <Col span={24}>
        <span>Logging out ...</span>
      </Col>
    </Row>
  )
}

export default Logout
