import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import { Table, Input, Button, Form, Tag, Tooltip } from 'antd'
import Filter from '../../../components/shared/ant/bar/filter'
import Ranger from '../../../components/shared/ant/input/ranger'
import FilterBar from '../../../components/shared/filter_bar'
import DateTime from '../../../components/shared/format/datetime'
import Status from '../../../components/shared/format/status'
import LengthLimiter from '../../../components/shared/format/length_limiter'

const styles = {
  icon: {
    marginRight: 4,
  },
  filename: {
    borderBottom: '1px dashed darkgrey',
  }
}
const tagColor = 'geekblue'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    window.store.telegramLog.list()
  }

  handleOnChange = (pagination) => {
    window.store.telegramLog.modify({
      currentPage: pagination.current,
    })
    window.store.telegramLog.list()
  }

  handleSubmit = values => {
    window.store.telegramLog.modify({
      currentPage: 1,
      currentStatus: '',
      currentKeywords: values.keywords,
      currentStartTime: values.date ? values.date[0].toISOString() : '',
      currentEndTime: values.date ? values.date[1].toISOString() : '',
    })
    window.store.telegramLog.list()
  }

  onClickSwitcher = (e) => {
    window.store.telegramLog.modify({
      currentPage: 1,
      currentStatus: e.target.value,
    })
    window.store.telegramLog.list()
  }

  onClickFavorite = (item, value, index) => {
    window.store.telegramLog.update(item.id, index, {
      status: value
    })
  }

  favoriteAll = () => {
    window.store.telegramLog.favoriteAll()
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '72px',
        render: value => <Link to={`/telegram_logs/${value}`}>{value}</Link>,
      },
      {
        title: 'Message ID',
        dataIndex: 'message_id',
        width: '128px',
      },
      {
        title: 'Username',
        dataIndex: ['telegram_user', 'username'],
        ellipsis: true,
        width: '196px',
      },
      {
        title: 'Text',
        dataIndex: 'text',
        ellipsis: true,
        render: (value, record) => <span className="longest">
          {record.telegram_chat.title &&
            <Tag color="blue">[<LengthLimiter length={5} text={record.telegram_chat.title}></LengthLimiter>]</Tag>
          }
          {record.meta.forward_sender_name &&
            <Tag color="green">{record.meta.forward_sender_name}</Tag>
          }
          {record.meta.forward_from_username &&
            <Tag color="purple">{record.meta.forward_from_username}</Tag>
          }
          {record.meta.caption &&
            <Tooltip title={record.meta.caption}>
              <Tag color={tagColor}>CAPTION</Tag>
            </Tooltip>
          }
          {record.meta.video && record.meta.video.file_name &&
            <Tooltip title={record.meta.video.file_name || record.meta.video.file_id}>
              <Tag color={tagColor}>VIDEO</Tag>
            </Tooltip>
          }
          {record.meta.photo && record.meta.photo.file_id &&
            <Tooltip title={record.meta.photo.file_id}>
              <Tag color={tagColor}>PHOTO</Tag>
            </Tooltip>
          }
          {record.meta.voice && record.meta.voice.file_id &&
            <Tooltip title={record.meta.voice.file_id}>
              <Tag color={tagColor}>VOICE</Tag>
            </Tooltip>
          }
          {record.meta.audio && record.meta.audio.file_id &&
            <Tooltip title={record.meta.audio.file_id}>
              <Tag color={tagColor}>AUDIO</Tag>
            </Tooltip>
          }
          {record.meta.document && record.meta.document.file_id &&
            <Tooltip title={record.meta.document.file_id}>
              <Tag color={tagColor}>DOC</Tag>
            </Tooltip>
          }
          {record.meta.private && record.meta.private.filename &&
            <Tooltip title={record.meta.private.filename}>
              <a href={'https://static.3facfe.com/telegram/' + record.meta.private.filename} target='_blank' rel="noopener noreferrer">
                <Tag color={tagColor}>FILE</Tag>
              </a>
            </Tooltip>
          }
          {record.meta.sticker && record.meta.sticker.file_id &&
            <Tooltip title={record.meta.sticker.file_id}>
              <Tag color={tagColor}>STICKER</Tag>
            </Tooltip>
          }
          <span style={record.status === 'favorite' ? { background: '#e6f7ff', color: '#1890ff' } : {}}>
            {value || record.meta.caption || record.meta.video?.file_name}
          </span>
        </span>,
      },
      {
        title: 'Category',
        dataIndex: ['telegram_chat', 'category'],
        render: value => <Status text={value} oranges={['text']} greens={['group_text']}></Status>,
        width: '128px',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: value => <Status text={value}></Status>,
        width: '96px',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        render: value => <DateTime text={value}></DateTime>,
        width: '196px',
      },
      {
        title: 'Control',
        key: 'control',
        width: '96px',
        render: (value, item, index) => <span>
          <span style={{ cursor: 'pointer', padding: '10px 5px 10px 0' }}
            onClick={() => this.onClickFavorite(item, item.status === 'favorite' ? 'none' : 'favorite', index)}>
            {item.status === 'favorite' &&
              <StarFilled style={styles.icon} />
            }
            {item.status !== 'favorite' &&
              <StarOutlined style={styles.icon} />
            }
          </span>
        </span>,
      },
    ]

    const buttons = [
      {
        text: 'All',
        value: '',
      },
      {
        text: 'Favorite',
        value: 'favorite',
      },
      {
        text: 'Forwarded by me',
        value: 'forwarded_by_me',
      },
    ]

    const filterLeft = (
      <Form layout="inline" onFinish={this.handleSubmit}>
        <Form.Item name="keywords">
          <Input addonBefore={<span>Full text</span>} />
        </Form.Item>
        <Form.Item name="date">
          <Ranger />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Filter</Button>
        </Form.Item>
        <Form.Item>
          <Button type="dashed" style={styles.button} onClick={this.favoriteAll}>Favorite all</Button>
        </Form.Item>
      </Form>
    )

    const filterRight = (
      <Filter value={buttons} click={this.onClickSwitcher}></Filter>
    )

    return (
      <div>
        <FilterBar filterLeft={filterLeft} filterRight={filterRight} />
        <Table
          rowClassName={(_, index) =>
            index % 2 === 1 ? 'dark-row' : 'light-row'
          }
          bordered
          size="small"
          columns={columns}
          rowKey='id'
          dataSource={window.store.telegramLog.items}
          pagination={window.store.telegramLog.pagination}
          loading={window.store.telegramLog.loading}
          onChange={this.handleOnChange}
        />
      </div>
    )
  }
}

export default observer(Index)
