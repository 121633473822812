import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'

class Status extends React.Component {
  translate() {
    return this.props.text === null || this.props.text === 'none' ? 'normal' : this.props.text
  }

  render() {
    let text = this.translate(this.props.text)

    if (this.props.pairs) {
      return (
        <Tag color={this.props.pairs[text]}>{text}</Tag>
      )
    }

    let oranges = ['protected_tweet', 'deleted_tweet', 'limit_exceed', 'loop', 'invalid'].concat(this.props.oranges)
    if (oranges.indexOf(text) >= 0) return (
      <Tag color="orange">{text}</Tag>
    )

    let greens = ['favorite'].concat(this.props.greens)
    if (greens.indexOf(text) >= 0) return (
      <Tag color="green">{text}</Tag>
    )

    switch (text) {
      case 'normal':
        return (
          <Tag color="blue">normal</Tag>
        )
      case 'success':
        return (
          <Tag color="green">success</Tag>
        )
      default:
        return (
          <Tag color="purple">{this.props.text}</Tag>
        )
    }
  }
}

Status.propTypes = {
  text: PropTypes.string,
  oranges: PropTypes.array,
  greens: PropTypes.array,
  pairs: PropTypes.object,
}

export default Status
