import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import Simple from '../../../components/shared/ant/table/simple'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='page'
        store={this.props.history.store}
        columns={'title status created_at'}
        options={{
          title: value => <Link to={`/pages/${value}`}>{value}</Link>,
          created_at: 'sinceThin',
          create: null,
          edit: null,
          size: 'small',
        }}
        additionalButtons={
          <Link to="/pages/Home">
            <Button>Home</Button>
          </Link>
        }
      />
    )
  }
}

export default Index
