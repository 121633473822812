import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import App from './routes/router'
import './index.css'
import 'antd/dist/antd.dark.min.css'
import 'leaflet/dist/leaflet.css'

window.debug = window.location.hostname === 'localhost' ? console.debug : () => {}

// eslint-disable-next-line no-extend-native
String.prototype.upperCamelCase = function(){
  return this.toLowerCase().replace(/_/g, ' ').replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

// eslint-disable-next-line no-extend-native
String.prototype.lowerCamelCase = function(){
  return this.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '')
}

if (window === undefined) {
  console.debug('ReactDOM.hydrate')
  ReactDOM.hydrate(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById('root')
  )
  // Using hydrate for web:
  // Warning: Expected server HTML to contain a matching <div> in <div>.
} else {
  console.debug('ReactDOM.render')
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById('root')
  )
}
