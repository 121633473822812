import React from 'react'
import { Link } from 'react-router-dom'
import Simple from '../../../components/shared/ant/table/simple'

class Index extends React.Component {
  render() {
    return (
      <Simple
        keywords='tag'
        slug='tags'
        store={this.props.history.store}
        columns='name display_name count_record status created_at control'
        control={{
        }}
        options={{
          size: 'small',
          create: null,
          edit: null,
        }}
      />
    )
  }
}

export default Index
