export default (props) => {
  return (
    <footer id="footer">
      <div className="inner">
        <span>© 2016-2020 {props.siteName}</span>
        <span className="dot"> · </span>
        <span>Made by <a href="https://3facfe.com" target="_blank" rel="noopener noreferrer">
        Evlos</a> with <span style={{
          color: '#f09199',
        }}>♥</span></span>
      </div>
    </footer>
  )
}
